<script lang="ts" setup>
import { ref, inject, Ref, getCurrentInstance, watch } from 'vue';
import { AccordionProps } from './types';

const props = defineProps({
  "header": { default: null },
  "initialOpen": { type: Boolean,  }
});
const emit = defineEmits(["show", "hide"]);

const instance = getCurrentInstance();

const show = ref(props.initialOpen === true);
const accordion = inject<AccordionProps>('accordion');
const opened = inject<Ref<string[]>>('opened');

function onClick()
{
    opened.value = [instance.uid.toString()];
    show.value = !show.value;
}

watch(show, (value) =>
{
    value ? emit('show') : emit('hide');
});

watch(opened, (value) =>
{
    if (!value.includes(instance.uid.toString()))
    {
        show.value = false;
    }
});
</script>

<template>
    <div :class="[accordion.itemClass, (show ? accordion.itemClassActive : {}), (accordion.border ? 'accordion-item' : '')]">
        <component :is="accordion.headerTag" class="accordion-header" @click="onClick">
            <button type="button" class="accordion-button" :class="[accordion.headerClass, {'collapsed': !show}]">
                <slot name="icon" v-if="accordion.icon">
                    <i class="far fa-fw" :class="[accordion.iconClass, {'fa-chevron-down': show, 'fa-chevron-right': !show}, (show ? accordion.headerClassActive : {})]"></i>
                </slot>
                <span :class="[accordion.labelClass, show ? accordion.headerClassActive : {}]">
                    <slot name="header">
                        {{ props.header }}
                    </slot>
                </span>
            </button>
        </component>
        <div class="accordion-collapse collapse" :class="{'show': show}">
            <div class="accordion-body" :class="accordion.contentClass">
                <slot name="default"></slot>
            </div>
        </div>
    </div>
</template>

<style scoped>
.accordion-button::after {
    display: none;
}
</style>
