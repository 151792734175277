<script lang="ts" setup>
import { ref, provide } from 'vue';
import { AccordionProps } from './types';

const props = defineProps({
  "headerTag": { default: 'h2' },
  "headerClass": { default: 'px-1 py-2 text-body-secondary fw-medium' },
  "headerClassActive": { default: 'text-primary' },
  "itemClass": { default: '' },
  "itemClassActive": { default: '' },
  "iconClass": { default: 'me-4' },
  "labelClass": { default: '' },
  "contentClass": { default: 'px-1 py-2' },
  "border": { type: Boolean, default: true },
  "icon": { type: Boolean, default: true },
  "initialOpen": { type: Boolean,  }
});

const opened = ref<string[]>([]);

provide('accordion', props);
provide('opened', opened);
</script>

<template>
    <div :class="{'accordion': props.border}">
        <slot name="default"></slot>
    </div>
</template>
