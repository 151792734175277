import { RouteRecordRaw } from 'vue-router';
import CrmRoutes from '@/modules/logito/crm/routes';
import CalendarRoutes from '@/modules/logito/calendar/routes';
import StaffRoutes from '@/modules/logito/staff/routes';
import OfficeRoutes from '@/modules/logito/office/routes';
import SignatureRoutes from '@/modules/logito/signatures/routes';
import DevexpressRoutes from '@/modules/logito/dev-express/routes';

const routes: Array<RouteRecordRaw> = [
    ...CrmRoutes,
    ...CalendarRoutes,
    ...StaffRoutes,
    ...OfficeRoutes,
    ...SignatureRoutes,
    ...DevexpressRoutes
];

export default routes;
