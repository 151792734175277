import { axios } from '@/plugins/axios';
import { KeyValuePair, Resource, Pagination } from "@/helpers/Interfaces";

export default class ScriptsService
{
    public static async getDictionaryData(field: string): Promise<Pagination<any>>
    {
        return (await axios.get(`simple-dictionary/scripts/form/${field}`)).data;
    }

    public static async getDetails(publicId: string): Promise<Resource<DetailsModel>>
    {
        return (await axios.get(`simple-fetch/scripts/${publicId}`)).data;
    }

    public static async create(form: FormModel, baseScriptId: string): Promise<Resource<DetailsModel>>
    {
        return (await axios.post('simple-command/scripts', form, {
            params: {
                baseScriptId: baseScriptId ?? ''
            }
        })).data;
    }

    public static async update(publicId: string, form: FormModel): Promise<Resource<DetailsModel>>
    {
        return (await axios.put(`simple-command/scripts/${publicId}`, form)).data;
    }

    public static async getDocumentation(): Promise<ScriptDoc[]>
    {
        return (
            await axios.get('scripts/documentation', {
                params: {},
            })
        ).data;
    }

    public static async getFieldsSchema(moduleId: string): Promise<any[]>
    {
        return (
            await axios.get('scripts/fields-schema', {
                params: {
                    moduleId: moduleId,
                },
            })
        ).data;
    }

    public static async debugOn(scriptId: string): Promise<void>
    {
        (await axios.post(`scripts/csharp/debug/${scriptId}`)).data;
    }

    public static async debugOff(scriptId: string): Promise<any>
    {
        return (await axios.get(`scripts/csharp/debug/${scriptId}`)).data;
    }
}

export interface DetailsModel extends FormModel {
    id: number;
    publicId: string;
}

export interface FormModel {
    name: Record<string, string>;
    description: Record<string, string>;
    module: string;
    scriptRole: KeyValuePair;
    scriptType: KeyValuePair;
    isArchive: boolean;
    scriptEngine: string;
    scriptConfig: string;
    sharedScript: string;
    baseScript: string;
    additionalScript: string;
    linkedScripts: KeyValuePair[];
    template: KeyValuePair;
    overrideBaseScript: boolean;
    overrideAdditionalScript: boolean;
    overrideSharedScript: boolean;
    overrideScriptConfig: boolean;
    isSystemScript: boolean;
}

export interface ScriptDoc {
    description: string;
    methods: Method[];
    name: string;
    props: Prop[];
}

interface Prop {
    name: string;
    type: string;
    description: string;
}

interface Method {
    clipboard: string;
    description: string;
    implementation: string;
    name: string;
    retrunType: string;
}

export enum ScriptRoleEnum {
    ExecutableScript = "ExecutableScript",
    SharedScript = "SharedScript",
    Template = "Template"
}

export enum ScriptTypeEnum {
    DynamicTableField = "DynamicTableField",
    DynamicTableDefaultRowValue = "DynamicTableDefaultRowValue",
    Escalation = "Escalation",
    ValidationScript = "ValidationScript",
    ScriptTask = "ScriptTask",
    DataSource = "DataSource",
    PrintTemplate = "PrintTemplate",
    DynamicModal = "DynamicModal"
}

export enum ScriptEngineEnum {
    ClearScript = "ClearScript",
    CSharpEngine = "CSharpEngine"
}
