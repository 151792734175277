<script lang="ts" setup>
import { computed, ref, onMounted, getCurrentInstance } from 'vue';
import { DetailsBuilderContract } from '@/components/builder/details';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';

const instance = getCurrentInstance();

defineOptions({
    name: 'field-condition',
});

const props = defineProps({
  "details": null,
  "label": { default: null },
  "modelValue": null,
  "when": { default: () => WhenChoice.When },
  "showLabel": { type: Boolean, default: false },
  "type": { default: 'bool' }
});

const emit = defineEmits(["update:modelValue"]);

const modal = ref(false);
const buffer = ref<string>(null);
const errorMessage = ref<string>(null);

const uid = computed(() => `form-field-${instance.uid}`);
const visible = computed(() => props.when == WhenChoice.When);

const triggerInput = (value: string): void =>
{
    emit('update:modelValue', value);
};

const saveExpression = (): void =>
{
    triggerInput(buffer.value);
    closeEditor();
};

const openEditor = (): void =>
{
    buffer.value = props.modelValue;
    modal.value = true;
};

const closeEditor = (): void =>
{
    buffer.value = props.modelValue;
    modal.value = false;
};

onMounted((): void =>
{
    buffer.value = props.modelValue;
});
</script>

<template>
    <div class="form-group" v-if="visible">
        <label :for="uid" v-if="showLabel">{{ $t('[[[Skrypt]]]') }}</label>
        <div class="input-group">
            <input
                type="text"
                class="form-control pointer"
                :readonly="true"
                :id="uid"
                :value="modelValue"
                :placeholder="showLabel ? '' : label"
                @click="openEditor()"
            />
            <span class="input-group-text pointer" @click="openEditor()">
                <i class="fas fa-cog"></i>
            </span>
        </div>
        <small class="form-text text-danger" v-if="errorMessage">
            {{ $t(errorMessage) }}
        </small>
        <ideo-modal id="field-condition-modal" v-model="modal" :title="label" size="xl" centered scrollable>
            <template #default>
                <div class="row">
                    <div class="col-lg-7 mb-3 mb-lg-0">
                        <textarea v-model="buffer" class="form-control h-100" :rows="5" spellcheck="false"></textarea>
                    </div>
                    <div class="col-lg-5">
                        <div v-if="type == 'bool'" class="legend">
                            <h5 class="fw-bold2 mb-3">{{ $t('[[[Warunkowe wyświetlanie pól]]]') }}</h5>
                            <p class="mb-0">{{ $t('[[[Skrypt powinien zwrócić wartość typu bool, określającą czy dane pole powinno być widoczne czy nie.]]]') }}</p>
                            <p class="mb-0">{{ $t('[[[W skrypcie korzystać można ze wszystkich serwisów zgodnie z dokumentacją w źródłach danych.]]]') }}</p>
                            <p class="">{{ $t('[[[Dodatkowo dostępny jest obiekt document zawierający dane dokumentu oraz obiekt data z polami ProcessId, ModuleId i ModuleVersionId.]]]') }}</p>
                            <div class="mb-2">
                                <h6>{{ $t('[[[Stała wartość]]]') }}</h6>
                                <pre>true</pre>
                            </div>
                            <div class="mb-2">
                                <h6>{{ $t('[[[Jeśli kwota przekracza 2000]]]') }}</h6>
                                <pre>
document.DbDataDynamic["123_Numeric1"] > 2000;
                                </pre>
                            </div>
                            <div class="mb-2">
                                <h6>{{ $t('[[[Jeśli w polu wyboru wybrano opcję 1]]]') }}</h6>
                                <pre>
document.DbDataDynamic["123_LogitoChoice1"][0].internalId == '1';
                                </pre>
                            </div>
                            <div>
                                <h6>{{ $t('[[[Jeśli użytkownik należy do grupy użytkowników modułu o id 456]]]') }}</h6>
                                <pre>
var groups = ProcessDictionaryService.GetModuleUserGroups(data.ModuleVersionId);
var group = groups.single(p => p.Id == 456);
group.Members.Contains(CurrentUser.Id);
                                </pre>
                            </div>
                        </div>
                        <div v-if="type == 'columns'" class="legend">
                            <h5 class="fw-bold2 mb-3">{{ $t('[[[Warunkowe wyświetlanie kolumn tabeli]]]') }}</h5>
                            <p class="mb-0">{{ $t('[[[Skrypt powinien zwrócić obiekt składający się z klucza kolumny oraz wyrażenia zwracającego wartość logiczną, określający czy dana kolumna powinna być widoczna czy nie.]]]') }}</p>
                            <p class="mb-0">{{ $t('[[[Jeśli kolumna z tabeli nie jest uwzględniona w obiekcie to domyślnie jest ona widoczna.]]]') }}</p>
                            <p class="mb-0">{{ $t('[[[W skrypcie korzystać można ze wszystkich serwisów zgodnie z dokumentacją w źródłach danych.]]]') }}</p>
                            <p class="">{{ $t('[[[Dodatkowo dostępny jest obiekt document zawierający dane dokumentu oraz obiekt data z polami ProcessId, ModuleId i ModuleVersionId.]]]') }}</p>
                            <div class="mb-2">
                                <h6>{{ $t('[[[Struktura zwracanego obiektu:]]]') }}</h6>
                                <pre>
const result = {
    klucz_kol_1: false,
    klucz_kol_3: data.ProcessId == 2,
    klucz_kol_4: document.DbDataDynamic["123_Numeric1"] > 2000,
};

result;
                                </pre>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #modal-footer>
                <button class="btn btn-primary" @click.stop.prevent="saveExpression()">
                    {{ $t('[[[Zapisz]]]') }}
                </button>
                <ideo-button variant="light" @click.stop.prevent="closeEditor()">
                    {{ $t('[[[Anuluj]]]') }}
                </ideo-button>
            </template>
        </ideo-modal>
    </div>
</template>

<style lang="scss" scoped>
.legend {
    h6 {
        font-style: italic;
        padding-left: 10px;
        padding-bottom: 2px;
        margin-bottom: 0;
        border-left: 2px solid var(--bs-primary);
    }

    pre {
        white-space: pre-line;
        margin-bottom: 0;
        padding-left: 12px;
    }

    pre + h6 {
        margin-top: 1rem !important;
    }
}
</style>

<style lang="scss">
.textcomplete-dropdown {
    max-height: 160px;
    padding: 0 !important;

    &::-webkit-scrollbar-track {
        background-color: var(--bs-dropdown-bg) !important;
    }
}
</style>
