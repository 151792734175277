import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { Column } from '@/modules/core/common/services/GridService';

export const Definition: BlueprintDefinition = {
    type: 'logito-document-list',
    name: 'Lista dokumentów',
    icon: 'far fa-list',
    group: 'logito',
    position: 15,
};

export interface LogitoDocumentListContract extends Blueprint, VisibleBlueprint, HasLabel, HasWidth, HasHelp {
    moduleId: number;
    filtersJson: string;
    filterMappings: Record<string, string>;
    fields: Record<string, Column>;
}

export class LogitoDocumentListType implements LogitoDocumentListContract, ValidatableBlueprint
{
    public id: string;
    public type: string;
    public name: string;
    public disabled?: boolean;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public width: number;
    public minWidth: number;
    public exceptWidth?: number[];
    public help: Record<string, string>;
    public moduleId: number;
    public filtersJson: string;
    public filterMappings: Record<string, string>;
    public fields: Record<string, Column>;
    public errors: ValidationErrors;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 1;
        this.moduleId = null;
        this.filtersJson = null;
        this.filterMappings = {};
        this.fields = {};
        this.disabled = false;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.label = { 'pl-PL': 'Lista dokumentów' };
        this.showLabel = true;
        this.help = {};
        this.errors = {};
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        return {
            [this.name]: this.errors,
        };
    }
}
