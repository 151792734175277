<script lang="ts" setup>
import { ref, reactive, computed, onUnmounted } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { useAlerts } from '@/plugins/alerts';
import { Form } from '@/helpers/Form';
import { FormEntry, FormContract } from '@/components/forms/blueprints/form';
import { FluentFormBuilder } from '@/components/forms';
import Loader from '@/components/common/Loader.vue';
import { DynamicModalType } from '@/components/forms/blueprints/logito-dynamic-modal/index';
import DynamicFormHelper from '@/modules/low-code/helpers/DynamicFormHelper';
import ModulesService from '@/modules/low-code/services/ModulesService';

const props = defineProps({
  "endpointId": null,
  "baseBuilder": null,
  "licence": null,
  "blueprint": null,
  "modalEntry": null
});

const { $t } = useLocalization();
const { $alert } = useAlerts();

const form = reactive(Form.create({
    document: null as FormContract,
}));

const entry = ref<FormEntry>(new FormEntry());
const builder = ref(
    FluentFormBuilder.setup(false, true)
        .blueprint(
            () => form.document,
            () => 0
        )
        .entry(
            () => entry.value,
            () => 0
        )
        .make()
);

const isLoaded = ref(false);
const reloadKey = ref(0);
const formId = computed(() => props.blueprint.dynamicForm.key);

const loadForm = async () =>
{
    try
    {
        const response = await ModulesService.fetchForm(`${props.licence}-dynamic-modal-${formId.value}/bpmn-start/${props.endpointId}`);

        form.document = response;
        builder.value.update();
    }
    catch (ex: any)
    {
        if (ex.code === 400) $alert.warning(ex.message);
    }
};

const loadData = async () =>
{
    if (props.blueprint.script?.key)
        await executeScript();
};

const executeScript = async () =>
{
    form.wait();

    try
    {
        const response = await DynamicFormHelper.getFormData(props.licence, props.endpointId, props.modalEntry, props.baseBuilder, props.blueprint.script.key);

        entry.value = response;

        builder.value.update();
    }
    catch (ex: any)
    {
        if (ex.code === 422)
        {
            const errors: Record<string, string[]> = {};

            Object.entries(ex.data.errors).forEach(([key, value]) =>
            {
                const keyWithDot =
                    key.includes('.value') || key.includes('.custom')
                        ? key.toLowerCase()
                        : `${key.toLowerCase()}.value`;

                errors[keyWithDot] = value as string[];
            });

            builder.value.setEntryErrors(errors);
            $alert.warning($t('[[[Nie wszystkie pola zostały wypełnione prawidłowo.]]]'));
        }
    }
    finally
    {
        form.continue();
    }
};

const addEntriesData = () =>
{
    entry.value = builder.value.getEntry() as FormEntry;

    entry.value.module = props.licence + '-dynamic-modal-' + formId.value;
    entry.value.formId = props.endpointId;
    entry.value.actionName = 'bpmn-start';
};

const initForm = async () =>
{
    form.wait();

    await loadForm();
    await loadData();
    addEntriesData();

    form.complete();
};

const init = async () =>
{
    await initForm();

    isLoaded.value = true;
};

init();

onUnmounted(() =>
{

});

const getBuilder = async () =>
{
    return builder.value;
};

defineExpose({
    getBuilder
});
</script>

<template>
    <DataCard>
        <template #default>
            <div v-if="!isLoaded" class="position-absolute top-50 start-50 translate-middle">
                <Loader />
            </div>
            <div v-else class="row">
                <div class="col-md-12">
                    <form id="dynamic-form">
                        <FormBlueprint :key="reloadKey" :builder="builder" />
                    </form>
                </div>
            </div>
        </template>
    </DataCard>
</template>
