<script lang="ts" setup>
import { ref, computed, Ref, nextTick } from 'vue';
import { useEvents } from '@/plugins/events';
import { useRoute } from 'vue-router';
import { TableEntry, TableType, instaceOfTableEntry } from '.';
import { KeyValuePair, Resource } from '@/helpers/Interfaces';
import { v4 as uuidv4 } from 'uuid';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import StorageService, { Resource as FileResource } from "@/modules/core/files/services/StorageService";
import BpmnService from '@/modules/low-code/process/services/BpmnService';
import TableTemplatesService from '@/modules/low-code/table-templates/services/TableTemplatesService';
import TableView from './TableView.vue';
import ImportTableModal from './modals/ImportTableModal.vue';
import TemplatesModal from './modals/TemplatesModal.vue';
import FieldCanAddRow from './properties/FieldCanAddRow.vue';
import properties from '@/components/forms/properties';
import { FieldScript } from '@/components/forms/properties';
import { ScriptTypeEnum } from '@/modules/low-code/services/ScriptsService';

defineOptions({
    name: 'table-blueprint',
    components: {
        FieldScript,
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});

const { $events } = useEvents();

const reloadKey = ref(0);
const entryData = ref(new TableEntry()) as Ref<TableEntry>;
const idsRows = ref<string[]>([]);
const templates = ref<Resource<KeyValuePair>[]>([]);
const templateFile = ref<FileResource>();
const route = useRoute();

const importTableModalRef = ref<InstanceType<typeof ImportTableModal | null>>(null);
const templatesModalRef = ref<InstanceType<typeof TemplatesModal | null>>(null);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value;
    },
    set(value: Record<string, any>[])
    {
        entryData.value.data = value;
    },
});

const blueprint = computed(() => props.blueprint);
const design = computed(() => props.form.designMode());
const required = computed(() => props.form.expressions.required(props.blueprint));
const isTemplateMode = computed(() => !!props.entry.fieldIndex);
const isImport = computed(() => blueprint.value.allowImport && !isTemplateMode.value);
const isTemplates = computed(() => templates.value.length > 0 && !isTemplateMode.value);
const scriptType = computed(() => ScriptTypeEnum.DynamicTableDefaultRowValue);

const canAddRow = computed(() =>
{
    if (design.value) return blueprint.value.canAddRow !== NeverChoice.Never;

    switch (blueprint.value.canAddRow)
    {
        case AlwaysChoice.Always:
            return true;
        case NeverChoice.Never:
            return false;
        case WhenChoice.When:
            return props.form.expressions.executeExpression(blueprint.value.canAddRowWhen) ?? true;
        default:
            return true;
    }
});

const moduleId = computed(() =>
{
    return route.params.moduleId.toString();
});

const openTemplatesModal = () =>
{
    templatesModalRef.value?.showModal(templates.value);
};

const openImportModal = () =>
{
    importTableModalRef.value?.showModal();
};

const onAllowImportChange = (value: boolean) =>
{
    if (!value) blueprint.value.importColumns = {};
};

const getDefaultRows = async (indices: number[]) =>
{
    return BpmnService.getDefaultRowsValue(
        props.entry.module,
        props.entry.formId,
        props.blueprint.name,
        indices,
        entryData.value
    );
};

const setDefaultRowValues = async (indices: number[]): Promise<boolean> =>
{
    if (!blueprint.value.defaultRowValueScript?.key || design.value || isTemplateMode.value) return false;

    const defaultRows = await getDefaultRows(indices);

    Object.entries(defaultRows).forEach(([rowIndex, rowValue]) =>
    {
        if (!rowValue) return false;

        const row = entryData.value.data[Number.parseInt(rowIndex)];

        Object.entries(rowValue).forEach(([key, value]) =>
        {
            if (!value) return false;

            const elementBlueprint = props.form.schema.find(key) as EntryFactory<any>;

            if (!elementBlueprint) return false;

            row[key] = elementBlueprint.createEntry(value);
        });
    });

    props.form.update();

    return true;
};

const setDefaultNumberOfRows = (_value: number) =>
{
    value.value.length = _value;
    idsRows.value.length = _value;
    blueprint.value.numberOfRows = _value;

    for (let i = 0; i < value.value.length; i++)
    {
        if (value.value[i] == null)
        {
            value.value[i] = {};
            idsRows.value[i] = uuidv4();
        }
    }
};

const addRow = async () =>
{
    value.value.push({});
    idsRows.value.push(uuidv4());

    if (await setDefaultRowValues([value.value.length - 1]))
        reloadKey.value++;
};

const fetchTemplates = async () =>
{
    if (!props.entry.module || !props.entry.formId || !props.blueprint.name) return;

    try
    {
        const response = await TableTemplatesService.getTemplates(
            props.entry.module,
            props.entry.formId,
            props.blueprint.name
        );

        templates.value = response.items;
    }
    catch (ex)
    {
        templates.value = [];
    }
};

const uploadTemplate = async () =>
{
    if (templateFile.value && templateFile.value.upload)
    {
        try
        {
            const response = await StorageService.upload(templateFile.value.upload, 'users', true, false, false, 'public');

            blueprint.value.templateFileId = response.publicId;
        }
        catch (ex)
        {
            blueprint.value.templateFileId = null;
        }
    }
    else
    {
        blueprint.value.templateFileId = null;
    }
};

const initTemplateFile = async () =>
{
    if (blueprint.value.templateFileId)
    {
        const response = await StorageService.fetchResourceSimple(blueprint.value.templateFileId);

        templateFile.value = response;
    }
};

const init = async () =>
{
    entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instaceOfTableEntry);

    // TODO: Do not mutate props
    props.blueprint.importColumns ??= {}; // eslint-disable-line

    if (value.value.length === 0)
    {
        const numberOfRows = blueprint.value.numberOfRows;

        await nextTick();

        for (let i = 0; i < numberOfRows; i++)
        {
            value.value.push({});
        }

        await setDefaultRowValues(Array.from(Array(numberOfRows).keys()));
    }

    idsRows.value = Array(value.value.length ?? blueprint.value.numberOfRows);

    for (let i = 0; i < idsRows.value.length; i++)
    {
        idsRows.value[i] = uuidv4();
    }

    fetchTemplates();
    await initTemplateFile();
};

const afterImportCompleted = async (tableEntry: TableEntry) =>
{
    value.value = tableEntry.data;
    idsRows.value = Array(value.value.length ?? blueprint.value.numberOfRows);

    for (let i = 0; i < idsRows.value.length; i++)
    {
        idsRows.value[i] = uuidv4();
    }

    props.form.update();
    reloadKey.value = reloadKey.value + 1;

    await setDefaultRowValues(Array.from(Array(entryData.value.data.length).keys()));
    reloadKey.value = reloadKey.value + 1;

    $events.$emit('reload-related-documents');
};

init();
</script>

<template>
    <form-component-wrapper
        class="table-component"
        :form="form"
        :parent="parent"
        :blueprint="blueprint"
        :disabled="false"
    >
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <table-view
                    v-model:value="value"
                    v-model:ids-rows="idsRows"
                    :form="form"
                    :blueprint="blueprint"
                    :entry="entry"
                    :design="design"
                    :can-add-row="canAddRow"
                    :reload-key="reloadKey"
                />
                <form-help :form="form" :blueprint="blueprint" />
                <form-error-message :entry="entryData" name="values" />
                <form-error-message :entry="entryData" name="custom" />
                <div class="d-flex flex-wrap gap-2 my-2">
                    <action-button
                        v-if="canAddRow"
                        variant="success"
                        :disabled="design"
                        class="flex-fill me-0"
                        icon="fas fa-plus"
                        :text="$t('[[[Dodaj wiersz]]]')"
                        @click="addRow"
                    />
                    <action-button
                        v-if="isImport"
                        class="flex-fill me-0"
                        variant="light"
                        :disabled="design"
                        icon="fas fa-file-import"
                        :text="$t('[[[Importuj]]]')"
                        @click="openImportModal"
                    ></action-button>
                    <ideo-button
                        v-if="isTemplates"
                        variant="secondary"
                        :title="$t('[[[Szablony]]]')"
                        icon="fas fa-bars"
                        @click="openTemplatesModal"
                    />
                </div>
            </div>
            <import-table-modal
                ref="importTableModalRef"
                :blueprint-name="blueprint.name"
                :form-schema="form"
                :entry="entry"
                @import-completed="afterImportCompleted"
            />
            <templates-modal ref="templatesModalRef" :blueprint-name="blueprint.name" :entry="entry" :form="form" />
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" :form="form" :blueprint="blueprint" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-columns
                v-model="blueprint.components"
                v-model:columns="blueprint.columns"
                :form="form"
                :blueprint="blueprint"
                :label="$t('[[[Kolumny]]]')"
                :schema="form.schema"
            />
            <field-numeric
                :model-value="blueprint.numberOfRows"
                :label="$t('[[[Początkowa ilość wierszy]]]')"
                :naturals="true"
                :min="1"
                :max="20"
                @update:model-value="setDefaultNumberOfRows"
            />
            <field-checkbox
                v-model="blueprint.allowImport"
                :label="$t('[[[Pozwól na uzupełnianie danych z pliku excel]]]')"
                @update:model-value="onAllowImportChange"
            />
            <field-checkbox
                v-if="blueprint.allowImport"
                v-model="blueprint.hasColumnHeaders"
                :label="$t('[[[Pomiń pierwszy wiersz excela]]]')"
            />
            <field-checkbox
                v-model="blueprint.showColumnOrdinalNumber"
                :label="$t('[[[Pokaż kolumnę z liczbą porządkową]]]')"
            />
            <field-map-select-input
                v-if="blueprint.allowImport"
                v-model="blueprint.importColumns"
                :label="$t('[[[Pola mapowane (excel)]]]')"
                :form="form"
                :blueprint="blueprint"
            />
            <ideo-form-group :label="'Wgraj indywidualny szablon do pobrania'">
                <fileupload :max-files="1" v-model="templateFile" @update:modelValue="uploadTemplate" />
            </ideo-form-group>
            <field-computed
                v-model="blueprint.computedFields"
                :blueprint="blueprint"
                :form="form"
                :label="$t('[[[Pola wyliczane]]]')"
            />
            <FieldScript
                v-model="blueprint.defaultRowValueScript"
                :show-label="false"
                :label="$t('[[[Skrypt dla wartości domyślnych]]]')"
                :endpoint="`admin/forms/scripts`"
                :script-type="scriptType"
                :module-id="moduleId"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-can-add-row :form="form" :blueprint="blueprint" :label="$t('[[[Pozwól na dodawanie wierszy]]]')" />
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
