import { axios } from '@/plugins/axios';

export default class SzafirWebModuleService
{
    public static async getSettings(): Promise<SettingsModel>
    {
        return (await axios.get(`signatures/szafir-web-module/settings`)).data;
    }

    public static async getTaskLists(publicId: string, endpointId: string, module: string,): Promise<any>
    {
        return (await axios.get(`signatures/szafir-web-module/${module}/${publicId}/${endpointId}/task-lists`)).data;
    }

    public static async getSettingsXml(): Promise<string>
    {
        return (await axios.get('signatures/szafir-web-module/static-files/settings')).data;
    }

    public static async signDocument(
        publicId: string,
        endpointId: string,
        module: string,
        signedDoc: string
    ): Promise<any>
    {
        const formData = new FormData();
        const fileBlob = new Blob([signedDoc], { type: 'application/xml' });

        formData.append('signedDoc', fileBlob, 'signed.xml');

        return (await axios.post(`signatures/szafir-web-module/${module}/${publicId}/${endpointId}/sign`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })).data;
    }
}

export interface SettingsModel {
    debugMode: boolean;
}

export enum SignState {
    InProgress = 1,
    Success,
    Error
}
