import { axios } from "@/plugins/axios";
import { merge } from "lodash";
import { KeyValuePair } from '@/helpers/Interfaces';

export default class DynamicModalService
{
    public static async GetDictionary(endpoint: string, dynamicParams: Record<string, any>): Promise<KeyValuePair[]>
    {
        return (
            await axios.get(`admin/forms/dynamic-modal/${endpoint}`, {
                params: merge({}, dynamicParams),
            })
        ).data;
    }

    public static async ExecuteScript(licence: string, id: string, scriptId: string, model: Record<string, unknown>): Promise<any>
    {
        return (await axios.post(`dynamic-modal/${licence}/${id}/bpmn-start/script/${scriptId}`, model)).data;
    }
}
