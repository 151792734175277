<script lang="ts" setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useClipboard, useDebounce } from '@vueuse/core';
import ScriptsService, { ScriptDoc } from '@/modules/low-code/services/ScriptsService';

const props = defineProps({
  "scriptEngine": null
});

const { copy, copied } = useClipboard();

const search = ref('');
const debouncedSearch = useDebounce(search, 500);
const scriptDocs = ref<ScriptDoc[]>([]);
const isMounted = ref(false);

const showDocs = computed(() =>
{
    return (props.scriptEngine as any).key == 'ClearScript';
});

const filteredScriptDocs = computed(() =>
{
    if (debouncedSearch.value === '') return scriptDocs.value;

    const value = debouncedSearch.value.toLowerCase().trim();

    return scriptDocs.value.filter((doc) =>
    {
        const includesSearch = (str: string) => str.toLowerCase().includes(value);

        return (
            includesSearch(doc.name) ||
            includesSearch(doc.description) ||
            doc.methods?.some((method) => includesSearch(method.implementation)) ||
            doc.methods?.some((method) => includesSearch(method.description)) ||
            doc.props?.some((prop) => includesSearch(prop.name)) ||
            doc.props?.some((prop) => includesSearch(prop.description))
        );
    });
});

const loadData = async () =>
{
    if (isMounted.value == false)
        return;

    if (showDocs.value == false)
        return;

    if (scriptDocs.value.length > 0)
        return;

    const response = await ScriptsService.getDocumentation();

    scriptDocs.value = response;
};

watch(showDocs, async () =>
{
    await loadData();
}, {
    immediate: true,
});

onMounted(() =>
{
    isMounted.value = true;
    loadData();
});
</script>

<template>
    <div class="d-flex flex-column overflow-hidden">
        <template v-if="showDocs == false">
            <div>
                Dla wybranego silnika dokumentacja nie jest dostępna
            </div>
        </template>
        <template v-else>
            <div class="search mb-2">
                <ideo-form-input v-model="search" type="text" :placeholder="$t('[[[Wyszukaj]]]')" name="pageSize" />
                <i class="fas fa-magnifying-glass icon"></i>
            </div>
            <ideo-accordion class="scroll">
                <ideo-accordion-item v-for="(doc, index) in filteredScriptDocs" :key="index">
                    <template #header>{{ doc.name }}</template>
                    <template #default>
                        <p class="mb-0">
                            <b>{{ $t('[[[Opis]]]') }}</b><br />
                            <span class="doc-description">{{ doc.description }}</span>
                        </p>
                        <template v-if="doc.methods.length > 0">
                            <div class="dropdown-divider mt-2"></div>
                            <h5 class="my-2">{{ $t('[[[Metody]]]') }}</h5>
                            <div v-for="(method, methodIndex) in doc.methods" :key="methodIndex" class="mt-1 d-flex flex-column align-items-start">
                                <b>{{ $t('[[[Implementacja]]]') }}</b>
                                <IdeoTooltip
                                    :tooltip="!copied ? `${$t('[[[Kopiuj pole]]]')}` : `${$t('[[[Pole skopiowane]]]')}`"
                                    position="right"
                                >
                                    <span
                                        class="text-primary cursor-pointer me-1"
                                        @click="copy(`${doc.name}.${method.clipboard}`)"
                                    >
                                        {{ method.implementation }}
                                    </span>
                                </IdeoTooltip>
                                <div class="mt-1 mb-2 d-flex flex-column">
                                    <b>{{ $t('[[[Opis]]]') }}</b>
                                    <span class="doc-description">{{ method.description }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-if="doc.props.length > 0">
                            <div class="dropdown-divider mt-2"></div>
                            <h5 class="my-2">{{ $t('[[[Propki]]]') }}</h5>
                            <div v-for="(prop, propIndex) in doc.props" :key="propIndex" class="mt-1 d-flex flex-column align-items-start">
                                <b>{{ $t('[[[Propka]]]') }}</b>
                                <IdeoTooltip
                                    :tooltip="!copied ? `${$t('[[[Kopiuj pole]]]')}` : `${$t('[[[Pole skopiowane]]]')}`"
                                    position="right"
                                >
                                    <span
                                        class="text-primary cursor-pointer me-1"
                                        @click="copy(`${doc.name}.${prop.name}`)"
                                    >
                                        {{ prop.name }}
                                    </span>
                                </IdeoTooltip>
                                <div class="mt-1 mb-2 d-flex flex-column">
                                    <b>{{ $t('[[[Opis]]]') }}</b>
                                    <span class="doc-description">{{ prop.description }}</span>
                                </div>
                            </div>
                        </template>
                    </template>
                </ideo-accordion-item>
            </ideo-accordion>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.search {
    position: relative;

    input {
        padding-right: 36px;
    }

    .icon {
        position: absolute;
        top: 50%;
        right: 0.75rem;
        transform: translateY(-50%);
    }
}

.doc-description {
    white-space: pre-line;
}
</style>
