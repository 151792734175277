<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Debounce, Emit, Prop, Ref, Watch } from '@/helpers/Decorators';
import { Form, FormType } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import { normalizeClasses } from '@/helpers/Utils';
import AutocompleteService from '@/modules/core/common/services/AutocompleteService';
import { isEqual } from 'lodash';
import { createPopper, Instance as Popper } from '@popperjs/core';
import { onClickOutside } from '@vueuse/core';
import DictionaryTermsService from '@/modules/core/dictionary-terms/services/DictionaryTermsService';
import { FormEntry } from '../form';

@Options({
    name: 'IdeoSelect',
    emits: ['update:modelValue', 'clearModelValue', 'filtersChanged'],
})
export default class DictionaryTermsSelect extends Vue
{
    @Prop({ default: null, required: true }) public modelValue:
    | Array<Record<string | number, string>>
    | Record<string | number, string>
    | null;
    @Prop({ default: false }) public disabled: boolean;
    @Prop({ default: null }) public options: Array<Record<string | number, string>> | null;
    @Prop({ default: null }) public filters: Record<string, string | number> | null;
    @Prop({ default: null }) public endpoint: string | null;
    @Prop({ default: {} }) public narrowOptions: Record<string, any>;
    @Prop({ default: false }) public initialLoad: boolean;
    @Prop({ default: null }) public reloadKey: string | number | null;
    @Prop({ default: null }) public reloadValue: string | null;
    @Prop({ default: null }) public reloadOptionKey: string;
    @Prop({ default: null }) public placeholder: string | null;
    @Prop({ default: 'down' }) public direction: 'down' | 'up';
    @Prop({ default: true }) public clearButton: boolean;
    @Prop({ default: 'key' }) public valueField: string;
    @Prop({ default: 'value' }) public textField: string;
    @Prop({ default: false }) public multiselect: boolean;
    @Prop({ default: false }) public preselectWhenOneOption: boolean;
    @Prop({ default: false }) public preselectFirstOption: boolean;
    @Prop({ default: false }) public deselect: boolean;
    @Prop({ default: true }) public closeOnSelect: boolean;
    @Prop({ default: new Pager(1, 32, '', 'ASC') }) public pager: Pager;
    @Prop({ default: () => ({}) }) public customClasses: Record<string, boolean> | string[] | string;
    @Prop({ default: '.view-wrapper' }) public boundary: string;
    @Prop({ default: false }) public scrollToSelected: boolean;
    @Prop({ default: {}}) public entry: FormEntry;

    @Ref('input-search') public inputSearch: () => HTMLInputElement;
    @Ref('dropdown') public dropdown: () => HTMLUListElement;
    @Ref('select-wrapper') public selectWrapper: () => HTMLSelectElement;

    public focusedValue: Record<string | number, string> | null = null;
    public endpointOptions: Array<Record<string, string>> | null = null;
    public myOptions: Array<Record<string, string>> | null = null;
    public isListOpen: boolean = false;
    public alreadyFetched: boolean = false;
    public filter = Form.create({ search: '' });
    public popper: Popper = null;

    public get allFilters(): FormType<Record<string, any>>
    {
        return Form.create({ ...this.filter.formatData(), ...this.filters });
    }

    public get isMultiselectVisible(): boolean
    {
        return this.multiselect && !this.isListOpen && Array.isArray(this.modelValue) && this.modelValue.length > 0;
    }

    public get isInputVisible(): boolean
    {
        return (
            this.isListOpen ||
            this.modelValue == null ||
            (Array.isArray(this.modelValue) && this.modelValue.length === 0)
        );
    }

    public get containerClasses(): Record<string, boolean>
    {
        return {
            'ideo-select__wrapper--with-clear-button': this.clearButton,
            'ideo-select__wrapper--up-opened': this.isListOpen && this.direction === 'up',
            'ideo-select__wrapper--down-opened': this.isListOpen && this.direction === 'down',
            ...normalizeClasses(this.customClasses),
        };
    }

    public async created(): Promise<void>
    {
        if (this.initialLoad || this.preselectWhenOneOption || this.preselectFirstOption) await this.getOptions();

        if (Array.isArray(this.myOptions))
        {
            this.preselectValue();
        }
    }

    public mounted(): void
    {
        onClickOutside(this.selectWrapper(), () =>
        {
            this.toogleList(false);
        });
    }

    public isSelected(option: Record<string, string>): boolean
    {
        if (this.multiselect)
        {
            return (
                Array.isArray(this.modelValue) &&
                this.modelValue.findIndex((item) => item[this.valueField] === option[this.valueField]) > -1
            );
        }

        return option[this.valueField] === this.modelValue?.[this.valueField];
    }

    public isFocused(option: Record<string, string>): boolean
    {
        if (this.focusedValue?.[this.valueField] == option[this.valueField]) return true;

        return false;
    }

    public async getOptions(): Promise<void>
    {
        if (this.endpoint)
        {
            const { items } = await DictionaryTermsService.fetchOptions(
                this.endpoint,
                this.pager,
                this.filter.search,
                this.entry
            );

            this.myOptions = items?.map?.((item) => item.result);
        }
        else if (this.options)
        {
            this.myOptions = this.options.filter((option) =>
                (option[this.textField] ?? '-').toLowerCase().includes(this.filter.search.toLowerCase())
            );
        }

        if (Array.isArray(this.myOptions) && this.myOptions.length)
        {
            if (this.modelValue)
            {
                this.focusedValue = Array.isArray(this.modelValue)
                    ? this.modelValue[0] ? this.modelValue[0]: this.myOptions[0]
                    : this.modelValue;
            }
            else this.focusedValue = this.myOptions[0];
        }

        this.alreadyFetched = true;
    }

    public async toogleList(value: boolean, focus: boolean = false): Promise<void>
    {
        if (this.isListOpen === value) return;

        if (value && !this.alreadyFetched) await this.getOptions();

        this.isListOpen = value ?? false;

        if (this.isListOpen && focus)
        {
            this.$nextTick(() =>
            {
                this.inputSearch?.()?.focus?.();
            });
        }

        if (this.isListOpen)
        {
            this.popper = createPopper(this.selectWrapper(), this.dropdown(), {
                strategy: 'fixed',
                placement: this.direction === 'down' ? 'bottom-start' : 'top-start',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -1],
                        },
                    },
                    {
                        name: 'flip',
                        options: {
                            boundary: document.querySelector('.view-wrapper'),
                            fallbackPlacements: ['top', 'bottom'],
                        },
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                            boundary: document.querySelector(this.boundary),
                        },
                    },
                    {
                        name: 'sameWidth',
                        enabled: true,
                        phase: 'beforeWrite',
                        requires: ['computeStyles'],
                        fn: ({ state }) =>
                        {
                            state.styles.popper.width = `${state.rects.reference.width}px`;
                        },
                        effect: ({ state }) =>
                        {
                            state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
                        },
                    },
                ],
            });

            this.scrollToSelected && this.scrollTo();
        }
        else
        {
            this.popper?.destroy();
            this.popper = null;
        }

        if (!this.isListOpen) this.filter.clear();
    }

    public moveFocus(event: KeyboardEvent, option: Record<string, string>): void
    {
        if (
            event.key === 'Enter' ||
            event.key === 'ArrowUp' ||
            event.key === 'ArrowDown' ||
            event.key === 'Tab' ||
            event.key === 'Escape'
        )
        {
            const index = this.myOptions?.findIndex((item) => item[this.valueField] === option[this.valueField]);

            if (event.key === 'Enter')
            {
                event.preventDefault();
                this.selectValue(this.focusedValue);
            }
            else if (event.key === 'ArrowUp')
            {
                if (index > 0) this.focusedValue = { ...this.myOptions[index - 1] };

                this.scrollTo();
            }
            else if (event.key === 'ArrowDown')
            {
                if (index < this.myOptions.length - 1) this.focusedValue = { ...this.myOptions[index + 1] };

                this.scrollTo();
            }
            else
            {
                this.toogleList(false);
            }
        }
    }

    public preselectValue(): void
    {
        if (
            (this.preselectFirstOption && this.myOptions.length > 0) ||
            (this.preselectWhenOneOption && this.myOptions.length === 1)
        )
        {
            this.selectValue(this.myOptions[0], false);
        }
    }

    public selectValue(option: Record<string, string>, deselect: boolean = this.deselect): void
    {
        if (this.multiselect)
        {
            const value = Array.isArray(this.modelValue) ? [...this.modelValue] : [];
            const index = value.findIndex((item) => item[this.valueField] === option[this.valueField]);

            if (index === -1)
            {
                value.push(option);
                this.updateValue(value);
            }
            else if (index > -1 && deselect)
            {
                this.deleteValue(option);
            }
        }
        else
        {
            if (
                option[this.valueField] !== this.modelValue?.[this.valueField] ||
                option[this.textField] !== this.modelValue?.[this.textField]
            )
            {
                this.updateValue(option);
            }
            else if (deselect) this.updateValue(null);
        }

        this.closeOnSelect && this.toogleList(false);
    }

    public async scrollTo(): Promise<void>
    {
        await this.$nextTick();

        const currentElement = this.dropdown().querySelector('.ideo-select__option--focused');

        if (currentElement)
        {
            const offsetTop = (currentElement as HTMLLIElement).offsetTop;

            this.dropdown().scrollTop = offsetTop;
        }
    }

    @Emit('update:modelValue')
    public updateValue(
        value: Array<Record<string, string>> | Record<string, string> | null
    ): Array<Record<string, string>> | Record<string, string> | null
    {
        return value;
    }

    public deleteValue(option: Record<string, string>): void
    {
        if (this.modelValue != null && Array.isArray(this.modelValue))
        {
            const value = this.modelValue.filter((item) => item.key !== option.key);

            this.updateValue(value);
        }
    }

    @Emit('clearModelValue')
    public clearModelValue(): void
    {
        this.updateValue(this.multiselect ? [] : null);
        this.closeOnSelect && this.toogleList(false);
    }

    @Watch('allFilters')
    @Debounce(600)
    public allfiltersChanged(newValue: FormType<Record<string, any>>, oldValue: FormType<Record<string, any>>): void
    {
        if (!isEqual(newValue, oldValue))
        {
            this.$emit('filtersChanged', newValue);
            this.getOptions();
        }
    }

    @Watch('endpoint')
    @Watch('narrowOptions', { deep: true })
    public async narrowOptionsChanged(): Promise<void>
    {
        this.alreadyFetched = false;

        if (this.initialLoad || this.preselectWhenOneOption || this.preselectFirstOption) await this.getOptions();

        if (Array.isArray(this.myOptions))
        {
            this.preselectValue();
        }
    }

    @Watch('reloadKey')
    public async reloadKeyChanged(newValue: string | number | null): Promise<void>
    {
        if (newValue != null)
        {
            await this.getOptions();

            const optionKey = this.reloadOptionKey ? this.reloadOptionKey : this.valueField;

            const option = this.myOptions?.find(
                (option) => option?.[optionKey] === this.reloadValue ?? this.modelValue?.[optionKey]
            );

            if (typeof option === 'object' && optionKey in option) this.selectValue(option, false);
        }
    }
}
</script>

<template>
    <div class="ideo-select" :class="{ 'ideo-select--disabled': disabled }">
        <div class="ideo-select__wrapper" :class="containerClasses" ref="select-wrapper">
            <div class="ideo-select__content">
                <!-- Multiselect options -->
                <div
                    v-if="isMultiselectVisible && Array.isArray(modelValue)"
                    class="ideo-select__badge-container"
                    @click="toogleList(true, true)"
                >
                    <button
                        v-for="item in modelValue"
                        :key="item.key"
                        type="button"
                        class="ideo-select__badge"
                        :aria-label="$t('[[[Kliknij aby usunąć]]]')"
                        :title="$t(item.value)"
                        tabindex="-1"
                        @click.stop="deleteValue(item)"
                    >
                        {{ $filters.dashIfEmpty(item[textField]) }}
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <div class="flex-fill d-flex align-items-center">
                    <!-- Current value -->
                    <div
                        v-show="!isInputVisible"
                        class="ideo-select__value"
                        tabindex="0"
                        :title="modelValue?.[textField]"
                        @focus="toogleList(true, true)"
                    >
                        <slot name="selected-value" :selected="{ modelValue }">
                            <span>{{ modelValue?.[textField] }}</span>
                        </slot>
                    </div>

                    <!-- Input search -->
                    <input
                        v-show="isInputVisible"
                        v-model="filter.search"
                        type="text"
                        :placeholder="placeholder ?? $t('[[[Wyszukaj...]]]')"
                        class="ideo-select__input"
                        ref="input-search"
                        @keydown="moveFocus($event, focusedValue)"
                        @focus="toogleList(true)"
                    />
                </div>

                <!-- Button arrow -->
                <button
                    type="button"
                    tabindex="-1"
                    class="ideo-select__button ideo-select__button--arrow"
                    @click="toogleList(!isListOpen, !isListOpen)"
                >
                    <i
                        class="fas fa-caret-down ideo-select__icon"
                        :class="{ 'ideo-select__icon--rotate': isListOpen }"
                    ></i>
                </button>
            </div>

            <!-- Options list -->
            <ul
                v-show="isListOpen"
                class="ideo-select__list scroll"
                ref="dropdown"
                :class="[
                    direction === 'down' ? 'ideo-select__list--down' : 'ideo-select__list--up',
                    { 'ideo-select__list--with-clear-button': clearButton },
                ]"
            >
                <template v-if="Array.isArray(myOptions) && myOptions.length">
                    <li
                        v-for="(item, index) in myOptions"
                        :key="index"
                        class="ideo-select__option"
                        :class="{
                            'ideo-select__option--selected': isSelected(item),
                            'ideo-select__option--focused': isFocused(item),
                            'ideo-select__option--inactive': 'isActive' in item && !item.isActive,
                            deselect,
                        }"
                        @click="selectValue(item)"
                        @mouseenter="focusedValue = { ...item }"
                    >
                        <slot name="option" :option="item">
                            {{ $filters.dashIfEmpty(item[textField]) }}
                        </slot>
                    </li>
                </template>
                <li v-else class="ideo-select__option ideo-select__option--empty">
                    {{ $t('[[[Lista jest pusta]]]') }}
                </li>
            </ul>
        </div>

        <!-- Button clear -->
        <slot name="action">
            <button
                v-if="clearButton"
                type="button"
                class="ideo-select__button ideo-select__button--clear btn btn-secondary"
                @click="clearModelValue"
            >
                <i class="fas fa-fw fa-xmark"></i>
            </button>
        </slot>
    </div>
</template>

<style lang="scss" scoped>
.ideo-select {
    display: flex;
    color: var(--ideo-select-color);

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &__wrapper {
        position: relative;
        flex-grow: 1;
        display: flex;
        background: var(--ideo-select-bg);
        border: 1px solid var(--ideo-select-border-color);
        border-radius: var(--ideo-select-border-radius);
        min-height: 36px;

        &--with-clear-button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
        }

        &--up-opened {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        &--down-opened {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__content {
        display: flex;
        flex-grow: 1;
    }

    &__badge-container {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        align-items: center;
        padding: 4px 0 4px 6px;
        flex-grow: 1;
    }

    &__badge {
        background: var(--bs-primary);
        color: #ffffff;
        padding: 3px 20px 3px 15px;
        border-radius: 3px;
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        border: none;

        i {
            position: absolute;
            top: 2px;
            right: 4px;
            border: none;
            background: transparent;
            color: #ffffff;
            font-size: 0.625rem;
        }
    }

    &__input {
        outline: none;
        border: none;
        padding: 8px 0px 8px 10px;
        line-height: 18px;
        background: transparent;
        flex-grow: 1;
        color: var(--ideo-select-color);
        width: 100%;

        &::placeholder {
            opacity: 0.5;
            color: var(--ideo-select-color);
        }
    }

    &__value {
        flex-grow: 1;
        padding: 8px 0px 8px 10px;
        line-height: 18px;

        span {
            word-break: break-all;
        }
    }

    &__button {
        border: none;

        &--arrow {
            background: none;
            color: var(--ideo-select-arrow-color);
            padding: 0 10px 0 6px;
        }

        &--clear {
            width: 36px;
            border-top-right-radius: var(--ideo-select-border-radius);
            border-bottom-right-radius: var(--ideo-select-border-radius);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &__icon {
        transition: transform 0.3s ease;

        &--rotate {
            transform: rotate(180deg);
        }
    }

    &__list {
        z-index: 10001;
        min-width: 130px;
        background: var(--ideo-select-options-bg);
        border: 1px solid var(--ideo-select-border-color);
        width: calc(100% + 2px);
        margin: 0;
        max-height: 250px;
        list-style-type: none;
        padding: 0;

        &--with-clear-button {
            width: calc(100% + 1px);
        }

        &--up {
            bottom: 100%;
        }

        &--down {
            top: 100%;
        }
    }

    &__option {
        padding: 10px;
        line-height: 18px;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        &--empty {
            &:hover {
                background: none;
                color: unset;
                cursor: default;
            }
        }

        &--focused {
            background: var(--ideo-select-focused-option-bg);
            color: var(--ideo-select-focused-option-color);

            &.deselect {
                &.ideo-select__option--selected {
                    color: var(--ideo-select-deselect-option-color);
                    background: var(--ideo-select-deselect-option-bg);
                }
            }
        }

        &--selected {
            background: var(--ideo-select-selected-option-bg);
            color: var(--ideo-select-selected-option-color);
            font-weight: 600;
        }

        &--inactive {
            color: var(--bs-danger);
        }
    }

    [data-popper-reference-hidden] {
        visibility: hidden;
        pointer-events: none;
    }
}
</style>
