+<template>
    <div class="form-group" :class="{'mb-0': flag(nospace), 'invalid': invalid}">
        <label :for="labelFor" :class="labelClasses" v-if="label" @click="onClick">
            <component :is="tag">{{ label }}</component>
            <i class="fa-solid fa-fw fa-star-of-life fa-2xs text-danger" v-if="flag(required) && !invalid"></i>
            <i class="fa-solid fa-fw fa-triangle-exclamation" :title="invalidFeedback" v-if="invalid"></i>
            <i class="fa-solid fa-fw fa-circle-question text-info" ref="helpicon" v-if="$slots.help"></i>
        </label>
        <slot name="default"></slot>
        <template v-if="$slots.description">
            <small class="form-text text-muted"><slot name="description"></slot></small><br>
        </template>
        <small class="form-text text-danger" v-if="invalid">{{ $t(invalidFeedback) }}</small>
        <template v-if="loaded && $slots.help">
            <ideo-popover class="popover" :target="helpicon()" placement="right" triggers="hover">
                <slot name="help"></slot>
            </ideo-popover>
        </template>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Ref } from '@/helpers/Decorators';
import { normalizeClasses } from '@/helpers/Utils';

@Options({
    name: 'ideo-form-group'
})
export default class IdeoFormGroup extends Vue
{
    public loaded: boolean = false;

    @Ref()
    public helpicon: () => HTMLElement;

    @Prop()
    public label: string;

    @Prop({ default: 'span' })
    public tag: string;

    @Prop()
    public labelFor: string;

    @Prop()
    public invalidFeedback: string;

    @Prop({ default: null })
    public state: boolean | null;

    @Prop({ default: false })
    public required: boolean;

    @Prop({ default: false })
    public nospace: boolean;

    @Prop({ default: () => ({}) })
    public labelClass: Record<string, boolean> | string[] | string;

    public get labelClasses(): Record<string, boolean>
    {
        return {
            'w-100': true,
            'text-danger': this.invalid,
            ...normalizeClasses(this.labelClass)
        };
    }

    public get invalid(): boolean
    {
        return this.state === false || (this.state === null && !!this.invalidFeedback);
    }

    public flag(value: any): boolean
    {
        return value !== false;
    }

    public mounted(): void
    {
        this.loaded = true;
    }

    public onClick(e: any): void
    {
        if (!this.labelFor)
            e.stopPropagation();
    }
}
</script>
