<script lang="ts" setup>
import { getCurrentInstance, computed } from 'vue';

defineOptions({
    name: 'field-text'
});

defineProps({
  "label": { default: null },
  "modelValue": { default: undefined },
  "invalidFeedback": { type: Function, default: null },
  "pattern": { default: null },
  "size": { default: null },
  "type": { default: 'text' },
  "placeholder": { default: null },
  "required": { type: Boolean, default: false }
});

const emit = defineEmits(["update:modelValue"]);

const instance = getCurrentInstance();
const uid = computed(() => `form-field-${instance.uid}`);

const update = (e: any): void =>
{
    emit('update:modelValue', e?.target?.value);
};
</script>

<template>
    <div class="form-group">
        <label :for="uid" v-if="label">{{ $t(label) }} <var v-if="required">*</var></label>
        <input :type="type" :id="uid" class="form-control" :class="{'form-control-sm': size == 'sm', 'form-control-lg': size == 'lg'}" :placeholder="placeholder" :pattern="pattern" :value="$t(modelValue)" @input="update">
        <small class="form-text text-danger" v-if="invalidFeedback && invalidFeedback(modelValue)">
            {{ $t(invalidFeedback(modelValue)) }}
        </small>
    </div>
</template>
