import Lightbox from 'bs5-lightbox';
import { Directive, Plugin, DirectiveBinding } from 'vue';
import { useMouseClick } from '@/helpers/Utils';
// import iFrameResize from 'iframe-resizer/js/iframeResizer';
import { createPopper as createPopperInstance } from '@popperjs/core';

const DirectivesPlugin: Plugin =
{
    install(app, options)
    {
        app.directive('slugify', {
            beforeMount: (el: any, binding: any, vnode: any) =>
            {
                const search = [" ", "ę", "ó", "ą", "ś", "ł", "ż", "ź", "ć", "ń"];
                const replace = ["-", "e", "o", "a", "s", "l", "z", "z", "c", "n"];

                el.slugify = function(value: string)
                {
                    let text = value.toLowerCase();

                    for (let i = 0; i < search.length; i++)
                    {
                        const re = new RegExp(search[i], "g");

                        text = text.replace(re, replace[i]);
                    }

                    return text.replace(/[^a-z0-9-]/g, "");
                };

                el.addEventListener('blur', function(e: any)
                {
                    e.target.value = el.slugify(e.target.value);
                    e.target.dispatchEvent(new Event('input', { bubbles: true }));
                });
            }
        } as Directive);

        app.directive('focus', {
            mounted: function(el: HTMLElement)
            {
                el.dataset.focus = 'true';
                el.focus();
            }
        } as Directive);

        app.directive('lightbox', {
            mounted: function(el: any, binding: any)
            {

                if (binding.value?.inactive)
                    return;

                const lightbox = new Lightbox(el, {});
                const dbClick = binding.value?.dbClick ?? false;

                lightbox.modal._backdrop._config.className = 'modal-backdrop lightbox-backdrop';

                const mouseClick = useMouseClick();

                el.clickListener = (e: any) =>
                {
                    e.stopPropagation();

                    if (el.getAttribute('data-src'))
                    {
                        mouseClick(
                            () => !dbClick && lightbox.show(),
                            () => dbClick && lightbox.show()
                        );
                    }
                };

                el.addEventListener('click', el.clickListener);
            },
            beforeUnmount: function(el: any)
            {
                el.removeEventListener('click', el.clickListener);
            }
        });

        app.directive('tooltip', {
            updated(el: Tooltip, binding: DirectiveBinding)
            {
                const tooltip = (el as any).tooltip;

                if (tooltip)
                {
                    tooltip.innerHTML = binding.value;
                }
            },
            mounted(el: Tooltip, binding: DirectiveBinding)
            {
                if (!binding.value) return;

                const tooltip = document.createElement('div');

                el.tooltip = tooltip;

                const showTooltip = () => createTooltip(el, binding, tooltip);
                const hideTooltip = () => deleteTooltip(tooltip);

                el.addEventListener('mouseenter', showTooltip);
                el.addEventListener('mouseleave', hideTooltip);

                el._cleanup = () =>
                {
                    el.removeEventListener('mouseenter', showTooltip);
                    el.removeEventListener('mouseleave', hideTooltip);
                };
            },
            beforeUnmount(el: Tooltip)
            {
                if (el._cleanup)
                {
                    el._cleanup();
                }

                const tooltip = (el as any).tooltip;

                if (tooltip)
                {
                    tooltip.remove();
                }
            }
        });
    }
};

function createTooltip(el: HTMLElement, binding: DirectiveBinding, tooltip: HTMLElement)
{
    tooltip.classList.add('tooltip-logito');
    tooltip.setAttribute('position', 'right');
    tooltip.innerHTML = binding.value;

    document.body.appendChild(tooltip);

    const placement = binding.modifiers?.right ? 'right' : binding.modifiers?.left ? 'left' : 'top';

    createPopperInstance(el, tooltip, {
        placement: placement,
        strategy: 'fixed',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8]
                }
            }
        ]
    });
}

function deleteTooltip(tooltip: HTMLElement)
{
    tooltip.remove();
}

type Tooltip = HTMLElement & { tooltip: HTMLElement, _cleanup: () => void };

export default DirectivesPlugin;
