import { axios } from '@/plugins/axios';
import { $t } from '@/plugins/localization';
import Pager from '@/helpers/Pager';
import { merge } from 'lodash';
import { Pagination, Resource, Statement, Option, KeyValuePair, KeyValue, TermKeyValuePair } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';
import { FormEntry } from '@/components/forms/blueprints/form';

export default class DataSourcesService
{
    public static async getDictionaries(): Promise<Option[]>
    {
        return (await axios.get<Option[]>('admin/studio/data-sources/all')).data;
    }

    public static async getOptions(sourceId: number, query: string, pager: Pager): Promise<Pagination<Option>>
    {
        return (await axios.get<Pagination<Option>>(`admin/studio/data-sources/id/${sourceId}/options`, {
            params: { query: query, ...pager.data() }
        })).data;
    }

    public static async getOptionsById(sourceId: number, ids: number[], pager: Pager): Promise<Pagination<Option>>
    {
        return (await axios.post<Pagination<Option>>(`admin/studio/data-sources/id/${sourceId}/options`, { ids }, {
            params: pager.data()
        })).data;
    }

    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        // TODO
        // return (await axios.get<Pagination<Resource<ListItemModel>>>('simple-query/data-sources', {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/studio/data-sources', {
            params: {...filter, ...pager.data()}
        })).data;
    }

    public static async fetchDynamicControlsDataSource(type: string, pager: Pager): Promise<Pagination<KeyValue<string, string>>>
    {
        return (await axios.get(`admin/forms/data-sources`, {
            params: merge({}, { controlType: type }, pager.data())
        })).data;
    }

    public static async fetchDefaultUsersList(variant: string, pager: Pager, search: string, key?: string): Promise<Pagination<Resource<KeyValuePair>>>
    {
        return (await axios.get(`admin/forms/default-user-select/${variant}`, {
            params: merge({ search, key }, pager.data())
        })).data;
    }

    public static async fetchDefaultTermsList(dictionaryId: string, pager: Pager, search: string, key?: string): Promise<Pagination<Resource<KeyValuePair>>>
    {
        return (await axios.get(`admin/forms/default-dictionary-select/${dictionaryId}`, {
            params: merge({ search, key }, pager.data())
        })).data;
    }

    public static async fetchDefaultTermsForDocument(endpoint: string, pager: Pager, key: string): Promise<Pagination<Resource<TermKeyValuePair>>>
    {
        return (await axios.post(endpoint, {}, {
            params: merge({ key }, pager.data())
        })).data;
    }

    public static async fetchDefaultValuesForDocument(endpoint: string, pager: Pager, key: string, narrow?: Record<string, any>): Promise<Pagination<Resource<TermKeyValuePair>>>
    {
        return (await axios.get(endpoint, {
            params: merge({ key }, pager.data(), narrow)
        })).data;
    }

    public static async fetchUserFilterGroups(formId: number, groupType: string, groups: string[]): Promise<Pagination<Resource<KeyValuePair>>>
    {
        const pager = new Pager(1, 1000);

        return (await axios.get(`admin/forms/${formId}/user-filter-groups/${groupType}`, {
            params: merge({ search: '' }, pager.data(), groups)
        })).data;
    }

    public static async fetchDefaultCompanySelect(companyId?: string): Promise<KeyValuePair[]>
    {
        return (await axios.get(`admin/forms/default-company-select`, {
            params: { companyId }
        })).data;
    }

    public static async fetch(id: string): Promise<Resource<FormModel>>
    {
        // TODO
        // return (await axios.get<Resource<FormModel>>(`simple-fetch/data-sources/${id}`)).data;
        return (await axios.get<Resource<FormModel>>(`admin/studio/data-sources/${id}`)).data;
    }

    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        // TODO
        // return (await axios.post<Resource<FormModel>>(`simple-command/data-sources`, model)).data;
        return (await axios.post<Resource<FormModel>>(`admin/studio/data-sources`, model)).data;
    }

    public static async update(id: string, model: FormModel): Promise<Statement>
    {
        // TODO
        // return (await axios.put<Statement>(`simple-command/data-sources/${id}`, model)).data;
        return (await axios.put<Statement>(`admin/studio/data-sources/${id}`, model)).data;
    }

    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/studio/data-sources/${id}`)).data as Statement;
    }

    public static async getValues(sourceId: number, filter: ValueFilterModel, pager: Pager): Promise<Pagination<ValueItemModel>>
    {
        return (await axios.get<Pagination<ValueItemModel>>(`admin/studio/data-sources/${sourceId}/values`, {
            params: {...filter, ...pager.data()}
        })).data;
    }

    public static async addValue(sourceId: number, model: ValueModel): Promise<ValueModel>
    {
        return (await axios.post<ValueModel>(`admin/studio/data-sources/${sourceId}/values`, model)).data;
    }

    public static async updateValue(sourceId: number, model: ValueModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/studio/data-sources/${sourceId}/values/${model.id}`, model)).data;
    }

    public static async removeValue(sourceId: number, id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/studio/data-sources/${sourceId}/values/${id}`)).data as Statement;
    }
}

export interface ListItemModel
{
    id: number;
    dateCreatedUtc: DateTime;
    name: string;
    sourceType: KeyValuePair;
    dictionaryType: KeyValuePair;
}

export interface FilterModel
{
    name: string;
    sourceType: KeyValuePair;
    dictionaryType: KeyValuePair;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
}

export interface FormModel
{
    id: number;
    publicId: string;
    name: string;
    description: string;
    sourceType: KeyValuePair | string;
    dictionaryType: KeyValuePair;
    query: string;
    queryById: string,
    textField: string;
    valueField: string;
    // formOrigin?: number;
    formFilters: FormEntry;
    moduleId?: number;
    sectionId?: string;
    componentId?: string;
}

export interface ValueFilterModel
{
    text: string;
    value: string;
}

export interface ValueItemModel
{
    id: number;
    text: string;
    value: string;
    position: number;
    isDefault: boolean;
}

export interface ValueModel
{
    id: number;
    text: string;
    value: string;
    position: number;
    isDefault: boolean;
}

enum SourceTypeEnum {
    Dictionary = 'Dictionary',
    Database = 'Database',
    Form = 'Form'
}

const SourceTypeOptions = (): Option[] => ([
    { value: SourceTypeEnum.Dictionary, text: $t('[[[Słownik]]]') },
    { value: SourceTypeEnum.Database, text: $t('[[[Baza danych]]]') },
    { value: SourceTypeEnum.Form, text: $t('[[[Formularz]]]') }
]);

const SourceTypeName = (value: SourceTypeEnum): string => SourceTypeOptions().find(p => p.value === value)?.text || SourceTypeEnum[value];

export {
    SourceTypeEnum,
    SourceTypeOptions,
    SourceTypeName
};
