<script lang="ts" setup>
import { ref, computed, nextTick, onUnmounted, watch } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { useEvents } from '@/plugins/events';
import { LocalizationManager } from '@/components/builder/form/managers/LocalizationManager';
import DynamicForm from '@/modules/low-code/views/DynamicModalForm.vue';
import { FormEntry } from '../../form';
import { DynamicModalType, DynamicActionType, DynamicModalEntry } from '../index';
import { FormBuilderContract } from '@/components/builder/form';
import DynamicFormHelper from '@/modules/low-code/helpers/DynamicFormHelper';

const emit = defineEmits(["action-event", "closing-event", "reset-modal"]);

const props = defineProps({
  "baseBuilder": null,
  "blueprint": null,
  "entry": null,
  "modalEntry": null
});

const { $t } = useLocalization();
const { $events } = useEvents();
const localizationManager = new LocalizationManager();

const modal = ref(null);

const title = computed(() => localizationManager.translate(props.blueprint.formTitle));
const licence = computed(() => props.entry.module);
const endpointId = computed(() => props.entry.formId);
const actions = computed(() => props.blueprint.dynamicActions);
const dynamicFormRef = ref<any>(null);
const isLoaded = ref<boolean>(false);

const showModal = async () =>
{
    if ((props.blueprint.dynamicForm?.key ?? '') == '')
    {
        await executeScript(props.blueprint.script, props.baseBuilder);

        return;
    }

    $events.$on('dynamic-modal-hide', hideModal);

    nextTick(() =>
    {
        modal.value.show();
        isLoaded.value = true;
    });
};

const hideModal = async () =>
{
    modal.value.hide();
};

const actionEvent = async (action: DynamicActionType) =>
{
    const currentBuilder = await dynamicFormRef.value.getBuilder();

    if (action.closingModal)
    {
        isLoaded.value = false;

        emit('action-event', action, currentBuilder);
    }
    else
    {
        await executeScript(action.script, currentBuilder);
    }

    modal.value.hide();
};

const executeScript = async (script: any, builder: any) =>
{
    if (script?.key)
    {
        const formData = await DynamicFormHelper.getFormData(licence.value, endpointId.value, props.modalEntry, builder, script.key);

        emit('closing-event', formData);
    }
};

const getTranslatedText = (text: Record<string, string>) =>
{
    return localizationManager.translate(text);
};

watch(() => (modal.value?.visibilityState()), async () =>
{
    if (isLoaded.value == true && modal.value?.visibilityState() == false)
    {
        emit('reset-modal');
    }
}, {
    immediate: true
});

onUnmounted(() =>
{
    $events.$off('dynamic-modal-hide', hideModal);
});

defineExpose({
    showModal,
    hideModal
});
</script>

<template>
    <ideo-modal ref="modal" :title="title" size="xl" centered scrollable>
        <DynamicForm
            :licence="licence" :endpoint-id="endpointId" :base-builder="baseBuilder" :base-entry="entry" :modal-entry="modalEntry"
            :blueprint="blueprint" ref="dynamicFormRef"
        ></DynamicForm>
        <template #modal-footer="{ cancel }">
            <template v-for="(action, index) in actions" :key="index">
                <ideo-button :variant="(action.buttonStyle?.key ?? 'success').toLowerCase()" @click="actionEvent(action)">{{ getTranslatedText(action.name) }}</ideo-button>
            </template>
            <ideo-button variant="secondary" @click="cancel">{{ $t('[[[Anuluj]]]') }}</ideo-button>
        </template>
    </ideo-modal>
</template>
