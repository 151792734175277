<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { KeyValue, KeyValuePair, Option } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { FormEntry } from '../form';
import { typeName } from "@/helpers/Utils";
import { UserType, UserEntry, instanceOfUserEntry, UserFeatures, UserAccessType, UserDataFields, FilterByGroupType } from '.';
import properties from '../../properties';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import DataSourcesService from '@/modules/studio/data-sources/services/DataSourcesService';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';

@Options({
    name: 'user-blueprint',
    components: {
        ...properties,
    },
})
export default class UserBlueprint extends Vue
{
    @Prop({ default: null }) public form!: FormBuilderContract;
    @Prop({ default: null }) public parent!: AggregateBlueprint;
    @Prop({ default: null }) public blueprint!: UserType;
    @Prop({ default: null }) public entry!: FormEntry;
    @Prop() public index: number;

    public entryData: UserEntry = new UserEntry();
    public pager: Pager = new Pager(1, 999, '', 'DESC');
    public sourceOptions: Option[] = [];
    public defaultUsers: KeyValuePair[] | KeyValuePair = [];
    public selectedType: KeyValuePair = null;
    public filterGroups: KeyValuePair[] = [];
    public reloadKey: number = 0;

    public get value(): KeyValuePair[] | KeyValuePair
    {
        if (!this.blueprint.multiselect)
        {
            return this.entryData.data?.[0] ?? null;
        }

        return this.entryData.data ?? null;
    }

    public set value(value: KeyValuePair[] | KeyValuePair)
    {
        this.entryData.data = Array.isArray(value) ? value : value ? [value] : [];
    }

    public get narrowOptions(): Record<string, any>
    {
        const result: Record<string, any> = {};

        if (this.blueprint.filterByGroupType == FilterByGroupType[FilterByGroupType.Dynamic])
        {
            const userGroupData = this.entry[this.blueprint.filterByGroupField].data;

            if (userGroupData?.length > 0)
            {
                result[this.blueprint.filterByGroupField] = JSON.stringify(userGroupData.map((p: any) => p.key));
                result[`${this.blueprint.filterByGroupField}_type`] = userGroupData.first().type;
            }
        }

        result[`${this.blueprint.name}_type`] = this.getTypeKey(this.selectedType.key);

        return result;
    }

    public get design(): boolean
    {
        return this.form.designMode();
    }

    public get visible(): boolean
    {
        return this.form.expressions.visible(this.blueprint);
    }

    public get readonly(): boolean
    {
        return this.form.expressions.readonly(this.blueprint, false, this.index);
    }

    public get required(): boolean
    {
        return this.form.expressions.required(this.blueprint, this.index);
    }

    public get fetchOptions(): boolean
    {
        return !this.design;
    }

    public get properEndpoint(): string
    {
        return `simple-dictionary/${this.entry.module}/form/${this.entry.formId}/${this.entry.actionName}/${this.blueprint.name}`;
    }

    public get valueType(): number
    {
        return this.entryData.valuesType;
    }

    public set valueType(type: number)
    {
        this.entryData.valuesType = type;
    }

    public get placeholder(): string
    {
        return this.form.localization.translate(this.blueprint.placeholder);
    }

    public get isGroupFilterSelect(): boolean
    {
        const groupTypes = [
            FilterByGroupType[FilterByGroupType.Unit],
            FilterByGroupType[FilterByGroupType.Team],
            FilterByGroupType[FilterByGroupType.Group]
        ];

        return groupTypes.includes(this.blueprint.filterByGroupType);
    }

    public get isDynamicFilterSelect(): boolean
    {
        return this.blueprint.filterByGroupType == FilterByGroupType[FilterByGroupType.Dynamic];
    }

    public get typeOptions(): KeyValue<string, string>[]
    {
        return [
            {
                key: UserFeatures[UserFeatures.Director],
                value: this.$t('[[[Dyrektorzy]]]'),
            },
            {
                key: UserFeatures[UserFeatures.Manager],
                value: this.$t('[[[Kierownicy]]]'),
            },
            {
                key: UserFeatures[UserFeatures.Superior],
                value: this.$t('[[[Przełożeni]]]'),
            },
            {
                key: UserFeatures[UserFeatures.DirectSubordinate],
                value: this.$t('[[[Podwładni]]]')
            },
            {
                key: UserFeatures[UserFeatures.Subordinate],
                value: this.$t('[[[Wszyscy]]]')
            },
            {
                key: UserFeatures[UserFeatures.NoLogon],
                value: this.$t('[[[Konta bez logowania]]]')
            }
        ];
    }

    public get dataFieldOptions(): KeyValue<string, string>[]
    {
        return [
            {
                key: UserDataFields[UserDataFields.GivenName],
                value: this.$t('[[[Imię]]]')
            },
            {
                key: UserDataFields[UserDataFields.Surname],
                value: this.$t('[[[Nazwisko]]]')
            },
            {
                key: UserDataFields[UserDataFields.Email],
                value: this.$t('[[[E-mail]]]')
            },
            {
                key: UserDataFields[UserDataFields.Unit],
                value: this.$t('[[[Dział]]]')
            },
            {
                key: UserDataFields[UserDataFields.JobPosition],
                value: this.$t('[[[Stanowisko pracy]]]')
            },
            {
                key: UserDataFields[UserDataFields.Boss],
                value: this.$t('[[[Przełożony]]]')
            },
            {
                key: UserDataFields[UserDataFields.IsDirector],
                value: this.$t('[[[Dyrektor]]]')
            },
            {
                key: UserDataFields[UserDataFields.IsManager],
                value: this.$t('[[[Kierownik]]]')
            },
        ];
    }

    public get accessTypeOptions(): Option[]
    {
        return [
            {
                value: UserAccessType[UserAccessType.All],
                text: this.$t('Wszyscy użytkownicy')
            },
            {
                value: UserAccessType[UserAccessType.Active],
                text: this.$t('Aktywni użytkownicy')
            },
            {
                value: UserAccessType[UserAccessType.ModuleAccess],
                text: this.$t('Użytkownicy z dostępem do modułu')
            }
        ];
    }

    public get filterByGroupTypeOptions(): Option[]
    {
        return [
            {
                value: FilterByGroupType[FilterByGroupType.None],
                text: this.$t('[[[Brak]]]')
            },
            {
                value: FilterByGroupType[FilterByGroupType.Unit],
                text: this.$t('[[[Dział]]]')
            },
            {
                value: FilterByGroupType[FilterByGroupType.Team],
                text: this.$t('[[[Zespół]]]')
            },
            {
                value: FilterByGroupType[FilterByGroupType.Group],
                text: this.$t('[[[Grupa użytkowników]]]')
            },
            {
                value: FilterByGroupType[FilterByGroupType.Dynamic],
                text: this.$t('[[[Dynamicznie]]]')
            }
        ];
    }

    public get userGroupComponents()
    {
        return this.form.schema
            .components(['user-group'], this.blueprint)
            .map((p) => ({ value: p.name, text: this.$i18n.currentTranslation((p as HasLabel).label) }));
    }

    public async created(): Promise<void>
    {
        this.entryData = this.form.document.initEntry(this.blueprint, this.entryData, instanceOfUserEntry, this.index);
        this.setDefaultType();
        await this.fetchSourceOptions();
        await this.fetchDefaultUsers();
        await this.fetchGroupFilters();
    }

    public async fetchGroupFilters(): Promise<void>
    {
        if (this.design)
        {
            try
            {
                const result = await DataSourcesService.fetchUserFilterGroups(this.form.blueprintId, this.blueprint.filterByGroupType, this.blueprint.filterGroups);
                const groups = result.items.map(p => p.result);

                this.filterGroups = groups;
            }
            catch (error)
            {
                this.$alert.danger((error as any).message);
            }
        }
    }

    public async fetchSourceOptions(): Promise<void>
    {
        if (this.design)
        {
            try
            {
                const { items } = await DataSourcesService.fetchDynamicControlsDataSource(
                    typeName(this.blueprint.type),
                    this.pager
                );

                this.sourceOptions = items.map((item) =>
                {
                    return { text: item.value, value: item.key };
                });
            }
            catch (error)
            {
                this.$alert.danger((error as any).message);
            }
        }
    }

    private async fetchDefaultUsers(): Promise<void>
    {
        if (this.blueprint.defaultCurrentUser)
        {
            if (!this.design)
            {
                const result = await DataSourcesService.fetchDefaultValuesForDocument(
                    this.properEndpoint,
                    new Pager(1, 32, '', 'ASC'),
                    '[CurrentUser]',
                    { [`${this.blueprint.name}_type`]: this.getTypeKey(this.selectedType.key) }
                );

                this.value = result.items.map((p) => p.result);
            }
        }
        else if (this.blueprint.defaultValue?.length > 0)
        {
            if (this.design)
            {
                const result = await DataSourcesService.fetchDefaultUsersList(
                    UserAccessType[this.blueprint.accessType],
                    new Pager(1, this.blueprint.defaultValue.length, 'Id', 'asc'),
                    null,
                    `[${this.blueprint.defaultValue.toString()}]`
                );

                this.defaultUsers = result.items.map((p) => p.result);

                if (!this.blueprint.multiselect && this.defaultUsers.length > 0)
                    this.defaultUsers = this.defaultUsers[0];
            }
            else
            {
                const result = await DataSourcesService.fetchDefaultValuesForDocument(
                    this.properEndpoint,
                    new Pager(1, 32, '', 'ASC'),
                    `[${this.blueprint.defaultValue.toString()}]`,
                    { [`${this.blueprint.name}_type`]: this.getTypeKey(this.selectedType.key) }
                );

                this.value = result.items.map((p) => p.result);
            }
        }
    }

    public updateDefaultValue(): void
    {
        this.blueprint.defaultValue = Array.isArray(this.defaultUsers)
            ? this.defaultUsers.map((p) => p.key)
            : [(this.defaultUsers as KeyValuePair).key];
    }

    public updateMultiselect(value: boolean): void
    {
        if (value)
        {
            if (this.defaultUsers != null && !Array.isArray(this.defaultUsers))
                this.defaultUsers = [this.defaultUsers as KeyValuePair];
            else this.defaultUsers = [];
        }
        else
        {
            if (Array.isArray(this.defaultUsers) && (this.defaultUsers as KeyValuePair[]).length > 0)
                this.defaultUsers = this.defaultUsers[0];
            else this.defaultUsers = null;
        }

        this.updateDefaultValue();
    }

    public get allowedTypes(): KeyValue<string, string>[]
    {
        return this.typeOptions.where(p => this.blueprint.userTypes[p.key]).toArray();
    }

    public setDefaultType(): void
    {
        this.selectedType = this.allowedTypes.find((item) => item.key === 'Subordinate') ?? this.allowedTypes.first();
        this.valueType = this.getTypeKey(this.selectedType.key);
    }

    public async updateSelectedType(): Promise<void>
    {
        this.value = null;

        if (this.selectedType == null)
        {
            this.setDefaultType();
        }
        else
        {
            this.valueType = this.getTypeKey(this.selectedType.key);
        }
    }

    public updateFilterByGroupType(): void
    {
        this.filterGroups = [];
        this.blueprint.filterGroups = [];
    }

    public updateFilterGroups(): void
    {
        this.blueprint.filterGroups = this.filterGroups.map(p => p.key);
    }

    public restartDefault(): void
    {
        this.selectedType = null;
        this.updateSelectedType();
    }

    public getTypeKey(type: string): number
    {
        return UserFeatures[type as keyof typeof UserFeatures];
    }
}
</script>

<template>
    <form-component-wrapper class="user-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <div class="row">
                    <ideo-select
                        v-model="value"
                        :id="blueprint.id"
                        :disabled="readonly"
                        :multiselect="blueprint.multiselect"
                        :placeholder="placeholder"
                        :endpoint="fetchOptions ? properEndpoint : null"
                        :data-test="blueprint.id"
                        :class="`${allowedTypes.length > 1 ? 'col-sm-6 pe-sm-0' : ''}`"
                        :custom-classes="`${allowedTypes.length > 1 ? 'border-radius-right-sm-none' : ''}`"
                        :narrow-options="narrowOptions"
                        :reload-key="reloadKey"
                    >
                        <template #option="{ option }">
                            <user-avatar
                                :src="$filters.baseurl(`storage/files/${option.pictureUrl}`)"
                                :username="option.value"
                                rounded="circle"
                            />
                            <span class="ms-2 me-1">{{ option.value }}</span>
                            <span v-if="option.unit">({{ option.unit }})</span>
                        </template>
                    </ideo-select>
                    <div class="d-flex col-sm-6 ps-sm-0 mt-1 mt-sm-0" v-if="allowedTypes.length > 1">
                        <ideo-select
                            v-model="selectedType"
                            :disabled="readonly"
                            :placeholder="$t('[[[Dział]]]')"
                            :deselect="false"
                            :options="allowedTypes"
                            :clear-button="false"
                            class="flex-fill"
                            custom-classes="border-radius-right-none border-radius-left-sm-none"
                            @update:modelValue="updateSelectedType"
                        />
                        <div class="input-group-append">
                            <button
                                type="button"
                                class="btn btn-light border-radius-left-none"
                                @click.stop="restartDefault"
                            >
                                <i class="fas fa-fw fa-xmark"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" />
            </ideo-form-localize>
            <field-select
                v-model="blueprint.accessType"
                :options="accessTypeOptions"
                :label="$t('[[[Źródło danych]]]')"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'accessType')"
            />
            <field-checkbox
                v-model="blueprint.multiselect"
                :label="$t('[[[Wielokrotny wybór]]]')"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'multiselect')"
                @update:modelValue="updateMultiselect"
            />
            <field-select
                v-model="blueprint.filterByGroupType"
                :options="filterByGroupTypeOptions"
                :label="$t('[[[Filtr po grupie]]]')"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'filterByGroupType')"
                @update:modelValue="updateFilterByGroupType"
            />
            <ideo-form-group v-if="isGroupFilterSelect" :label="$t('[[[Wybierz grupę]]]')">
                <ideo-select
                    v-model="filterGroups"
                    :endpoint="`admin/forms/${form.blueprintId}/user-filter-groups/${blueprint.filterByGroupType}`"
                    :multiselect="true"
                    @update:modelValue="updateFilterGroups"
                />
            </ideo-form-group>
            <field-select
                v-if="isDynamicFilterSelect"
                v-model="blueprint.filterByGroupField"
                :label="$t('[[[Powiąż z polem typu grupa użytkowników]]]')"
                :options="userGroupComponents"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'filterByGroupField')"
            />
            <ideo-form-group :label="$t('[[[Typy użytkowników]]]')">
                <template v-for="option in typeOptions" :key="option.key">
                    <field-checkbox
                        v-model="blueprint.userTypes[option.key]"
                        :label="option.value"
                    />
                </template>
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Wartość domyślna]]]')">
                <ideo-form-checkbox v-model="blueprint.defaultCurrentUser" class="mb-2">
                    {{ $t('[[[Zalogowany użytkownik]]]') }}
                </ideo-form-checkbox>
                <ideo-select
                    v-if="!blueprint.defaultCurrentUser"
                    v-model="defaultUsers"
                    :endpoint="`admin/forms/default-user-select/${blueprint.accessType}`"
                    :multiselect="blueprint.multiselect"
                    @update:modelValue="updateDefaultValue()"
                >
                    <template #option="{ option }">
                        <UserAvatar
                            :src="$filters.baseurl(`storage/files/${option.pictureUrl}`)"
                            :username="option.value"
                            rounded="circle"
                        />
                        <span class="ms-2 me-1">{{ option.value }}</span>
                        <span v-if="option.unit">({{ option.unit }})</span>
                    </template>
                </ideo-select>
            </ideo-form-group>
            <ideo-form-group v-if="!blueprint.multiselect" :label="$t('[[[Dane do zapisania]]]')">
                <template v-for="option in dataFieldOptions" :key="option.key">
                    <field-checkbox
                        v-model="blueprint.dataFields[option.key]"
                        :label="option.value"
                    />
                </template>
            </ideo-form-group>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
        </template>
    </form-component-wrapper>
</template>
