import { AttachmentsContract, instanceOfAttachmentsEntry } from '@/components/forms/blueprints/attachments';
import { instanceOfUserGroupEntry } from '@/components/forms/blueprints/logito-user-group';
import { instanceOfUserEntry } from '@/components/forms/blueprints/logito-user';
import StorageService from '@/modules/core/files/services/StorageService';
import DynamicModalService from '@/modules/low-code/services/DynamicModalService';
import { DynamicModalEntry } from '../../../components/forms/blueprints/logito-dynamic-modal/index';
import { FormBuilderContract } from '@/components/builder/form';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { Entry } from '@/components/builder/form/entries/Entry';

export default class DynamicFormHelper
{
    public static async getFormData(licence: string, endpointId: string, modalEntry: DynamicModalEntry, baseBuilder: FormBuilderContract, scriptId: string)
    {
        const additonalData: Record<string, any> = {};

        additonalData['EventData'] = modalEntry?.data ?? null;

        let data = (await baseBuilder.collectEntry(async (blueprint: Blueprint, entry: Entry) =>
        {
            instanceOfAttachmentsEntry(entry) &&
                        (await entry.upload(
                            blueprint as AttachmentsContract,
                            async (file) => await StorageService.upload(file, 'documents')
                        ));
            instanceOfUserGroupEntry(entry) && (additonalData[`${blueprint.name}_type`] = entry.settings?.key);
            instanceOfUserEntry(entry) && (additonalData[`${blueprint.name}_type`] = entry.valuesType);
        })) as Record<string, any>;

        data = { ...data, ...additonalData };

        return await DynamicModalService.ExecuteScript(licence, endpointId, scriptId, data);
    }
}
