import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { RequiredBlueprint } from '@/components/builder/form/blueprints/RequiredBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry, instanceOfEntry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasFilter } from '@/components/builder/form/traits/HasFilter';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';
import { DateTime } from 'luxon';
import { formatDateTime } from '@/helpers/Utils';
import { PeriodValue } from '@/components/builder/form/types/PeriodValue';

export const Definition: BlueprintDefinition = {
    type: 'date',
    name: '[[[Data]]]',
    icon: 'fa-calendar-alt',
    group: 'primary',
    position: 4
};

export enum DateFieldTypes {
    DateTime = 'DateTime',
    Date = 'Date'
}

export class DateEntry extends ValidEntry<DateTime>
{
    public type: string = Definition.type;
    public data: DateTime = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(blueprint: DateContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        const readonly = form.expressions.readonly(blueprint);
        const data = this.data == null || (readonly && blueprint.defaultValue) ? form.expressions.executeExpression(blueprint.defaultValue) : this.data;

        return entry(data);
    }

    public validate(blueprint: DateContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        const readonly = form.expressions.readonly(blueprint);
        const data = this.data == null || (readonly && blueprint.defaultValue) ? form.expressions.executeExpression(blueprint.defaultValue) : this.data;
        const dateTime = blueprint.fieldType == DateFieldTypes.DateTime;

        if (!form.expressions.readonly(blueprint, true) && form.expressions.visible(blueprint, true))
        {
            if (form.expressions.required(blueprint) && data == null)
            {
                this.errors.value = [`[[[Pole "%0" jest wymagane.|||${form.localization.translate(blueprint.label)}]]]`];
            }
            else if (blueprint.period.min !== null && data != null && data < blueprint.period.min)
            {
                const date = formatDateTime(blueprint.period.min, dateTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd');

                this.errors.value = [`[[[Wybrana data jest mniejsza niż dozwolona %0.|||${date}]]]`];
            }
            else if (blueprint.period.max !== null && data != null && blueprint.period.max < data)
            {
                const date = formatDateTime(blueprint.period.max, dateTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd');

                this.errors.value = [`[[[Wybrana data jest większa niż dozwolona %0.|||${date}]]]`];
            }

            if (Object.keys(this.errors).length == 0 && form.expressions.customError(blueprint))
            {
                this.errors.custom = [form.expressions.customErrorMessage(blueprint, form)];
            }
        }

        return this.valid();
    }
}

export const instanceOfDateEntry = (object: any): object is DateEntry =>
{
    return instanceOfEntry(object) && 'type' in object && object.type === Definition.type;
};

export interface DateContract extends Blueprint, VisibleBlueprint, ReadonlyBlueprint, RequiredBlueprint, CustomErrorBlueprint, HasLabel, HasHelp, HasWidth, HasFilter
{
    fieldType: DateFieldTypes;
    defaultValue: string;
    period: PeriodValue;
    showWeekNumbers: boolean;
}

export class DateType implements DateContract, ValidatableBlueprint, EntryFactory<DateEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public fieldType: DateFieldTypes;
    public defaultValue: string;
    public period: PeriodValue;
    public minuteStep: number;
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public required: AlwaysChoice | NeverChoice | WhenChoice;
    public requiredWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;
    public showFilter: boolean;
    public showWeekNumbers: boolean;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = { 'pl-PL': 'Data' };
        this.showLabel = true;
        this.defaultValue = null;
        this.period = { min: null, max: null };
        this.minuteStep = 5;
        this.help = {};
        this.fieldType = DateFieldTypes.DateTime;
        this.minWidth = 1;
        this.width = 0;
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.required = NeverChoice.Never;
        this.requiredWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
        this.showFilter = false;
        this.showWeekNumbers = false;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): DateEntry
    {
        return new DateEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (this.fieldType === DateFieldTypes.DateTime)
        {
            if (this.minuteStep < 1 || this.minuteStep > 60)
            {
                this.errors.minuteStep = ['[[[Krok minut musi być liczbą z zakresu od 1 do 60.]]]'];
            }
        }

        return {
            [this.name]: this.errors
        };
    }
}
