<template>
    <div class="grid-stack-item" v-bind="gridStackAttributes">
        <div class="grid-stack-item-content" :class="{ move: isEditMode }">
            <DataCard class="overflow-hidden" :scroll="true" :overflow="true" :sticky-footer="false" :disable-footer="!widget.config.showPager && (!isFooterActions || (isFooterActions && isNotList))">
                <template #header>
                    <div class="d-flex scroll justify-content-between gap-2 align-items-center">
                        <div>
                            <i v-if="widget.config.headerIcon" class="me-2" :class="widget.config.headerIcon"></i>
                            <strong class="m-0">{{ name }}</strong>
                        </div>
                        <div class="d-flex justify-content-between align-items-center gap-2">
                            <div
                                v-if="widget.config.additionalData?.headerButtons"
                                class="d-flex align-items-center gap-2"
                            >
                                <IdeoButton
                                    v-for="item in widget.config.additionalData.headerButtons"
                                    :key="item.key"
                                    variant="primary"
                                    class="me-0"
                                    @click="handleEvent(item)"
                                >
                                    {{ $t(`[[[${item.value}]]]`) }}
                                </IdeoButton>
                            </div>
                            <IdeoDropdown
                                variant="light"
                                ref="ideo-dropdown"
                                :text="$t('[[[Akcje]]]')"
                                dropleft
                                no-caret
                                boundary="#view"
                                class="options"
                            >
                                <template #button-content>
                                    <i
                                        class="bigger"
                                        :class="($refs['ideo-dropdown'] as any)?.visible ? 'fas fa-close' : 'fas fa-ellipsis-vertical'"
                                    ></i>
                                </template>
                                <template #default>
                                    <IdeoDropdownItemButton @click="changeSettings">
                                        <i class="icon far fa-gear"></i>
                                        <span>{{ $t("[[[Ustawienia]]]") }}</span>
                                    </IdeoDropdownItemButton>
                                    <IdeoDropdownItemButton @click="$emit('deleteWidget')">
                                        <i class="icon far fa-trash-alt"></i> {{ $t("[[[Usuń]]]") }}
                                    </IdeoDropdownItemButton>
                                </template>
                            </IdeoDropdown>
                        </div>
                    </div>
                    <ideo-panel v-if="showAddButton || showFilters || showGroupedActionsButton" :column="false" :stretch="false" :start="desktop && showAddButton" class="mt-2">
                        <template #start>
                            <fetch-data
                                v-if="showAddButton"
                                :endpoint="toKebabCase(widget.config.licence.key)"
                                @meta-changed="metaChanged"
                            >
                                <grid-actions
                                    :licence="toKebabCase(widget.config.licence.key)"
                                    :except="except"
                                    :actions="actions"
                                />
                            </fetch-data>
                        </template>

                        <template #default>
                            <new-filters-wrapper v-if="showFilters" :filter="filter" :url="toKebabCase(widget.config.licence.key)" :emit-id="emitId" :sitemap-id="sitemapId">
                                <dynamic-filters
                                    :request-url="toKebabCase(widget.config.licence.key)"
                                    :filter="filter"
                                    :hidden-filters="['moduleId']"
                                    :filter-emit-id="`filter_${emitId}`"
                                    :sitemap-id="sitemapId"
                                    :action-name="widget.config.actionUrl"
                                    @filter-update="overwriteFilter"
                                    @set-initialization="filtersDone"
                                />
                            </new-filters-wrapper>
                        </template>

                        <template #end>
                            <div class="toolbar-actions">
                                <filter-manager v-if="showFilters" :endpoint="`${toKebabCase(widget.config.licence.key)}/${widget.config.actionUrl}`" :sitemap-id="sitemapId" />
                                <group-actions v-if="showGroupedActionsButton" :licence="toKebabCase(widget.config.licence.key)" :guids="selectedGuids" />
                            </div>
                        </template>
                    </ideo-panel>
                </template>
                <template #default>
                    <div class="scroll scroll__trackrounded w-100 h-100">
                        <template v-if="getWidgetComponent(widget.config)">
                            <component
                                :is="getWidgetComponent(widget.config)"
                                :key="widget.config.sourceConfig"
                                :config="widget.config"
                                :widget-config="widget"
                                :class="{ 'cursor-pointer': isNotList && isFooterActions }"
                                :filter="filter"
                                :emit-id="emitId"
                                @click="handleWidgetClick"
                                @onSelectedGuidsChanged="onSelectedGuidsChanged"
                            ></component>
                        </template>
                        <span v-else>{{ $t('[[[Nie znaleziono pasującego widgetu]]]') }}</span>
                    </div>
                </template>
                <template #footer>
                    <div class="d-flex align-items-center justify-content-between w-100">
                        <div v-if="!isNotList && isFooterActions" class="d-flex align-items-center gap-2">
                            <IdeoButton
                                v-for="item in widget.config.additionalData.footerButtons"
                                :key="item.key"
                                variant="outline-dark"
                                class="me-0"
                                @click="handleEvent(item)"
                            >
                                {{ $t(`[[[${item.value}]]]`) }}
                            </IdeoButton>
                        </div>
                        <portal-target :name="`widget-footer-${widget.publicId}`" multiple></portal-target>
                    </div>
                </template>
            </DataCard>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import {
    APIWidget,
    GridItemAttributes,
    Widget,
    WidgetTypeEnum,
    WidgetActionsEnum,
} from '@/modules/core/home/services/HomeService';
import { getWidgetType } from '@/modules/core/home/inc/helpers';
import { IMetaModel } from '@/modules/core/common/services/GridService';

import ListWidget from '@/modules/core/home/components/widgets/List.vue';
import CountWidget from '@/modules/core/home/components/widgets/Count.vue';
import EmployeesWidget from '@/modules/core/home/components/widgets/Employees.vue';
import ChartWidget from '@/modules/core/home/components/widgets/Chart.vue';
import CalendarWidget from '@/modules/core/home/components/widgets/Calendar.vue';
import TimetableWidget from '@/modules/core/home/components/widgets/Timetable.vue';
import GridActions from '@/components/common/dynamic-grid-new/actions/Grid.vue';
import { kebabCase } from 'lodash';
import { reactive } from 'vue';
import { Form } from '@/helpers/Form';
import ActiveSubstitutions from "@/modules/core/home/components/widgets/ActiveSubstitutions.vue";

@Options({
    name: 'BaseWidget',
    emits: ['deleteWidget'],
    components: {
        ListWidget,
        CountWidget,
        EmployeesWidget,
        ChartWidget,
        CalendarWidget,
        TimetableWidget,
        GridActions,
        ActiveSubstitutions
    },
    methods: { getWidgetType },
})
export default class BaseWidget extends Vue
{
    @Prop({ default: () => {} })
    public widget: Widget;

    @Prop({ default: false })
    public isEditMode: boolean;

    @Prop({ default: null })
    public dashboardId: string;

    public moduleId: string;
    public actions: Record<string, IMetaModel> = {};

    public selectedGuids: string[] = [];

    public filter = reactive(Form.create({})) as any;
    public filterBuilder = reactive<Record<string, unknown>>({});

    public get sitemapId(): string
    {
        return this.widget.config.sitemapEntryDetails.publicId;
    }

    public filtersDone(value: any)
    {
        this.changeFilter();
    }

    public changeFilter()
    {
        const widgetConfig = this.widget.config.sourceConfig;
        const widgetFilter = JSON.parse((widgetConfig as any)?.filter ?? '{}');

        this.filter.setInitialValues(this.filterBuilder);
        this.filter.withData(this.filterBuilder);
        this.filter.withData(widgetFilter);
    }

    public overwriteFilter = (event: { key: string; value: any; period: boolean; amount: boolean }) =>
    {
        if (event.period)
        {
            this.filterBuilder[`${event.key}.FromUtc`] = event.value;
            this.filterBuilder[`${event.key}.ToUtc`] = event.value;
            this.filterBuilder[`${event.key}.DaysBeforeNow`] = event.value;
            this.filterBuilder[`${event.key}.DaysAfterNow`] = event.value;
        }
        else if (event.amount)
        {
            this.filterBuilder[`${event.key}.FromUtc`] = event.value;
            this.filterBuilder[`${event.key}.ToUtc`] = event.value;
        }
        else this.filterBuilder[event.key] = event.value;
    };

    public get emitId(): string
    {
        return `widget_${this.widget.publicId}`;
    }

    public get showAddButton(): boolean
    {
        if (!this.widget.config.showAddButton)
            return false;

        if (this.widget.config.sourceType.key === 'Sitemap')
            return true;

        if ((this.widget.config.actionButtons?.length ?? 0) > 0)
            return true;

        return false;
    }

    public get showFilters(): boolean
    {
        return this.widget.config.showFilters;
    }

    public get showGroupedActionsButton(): boolean
    {
        return this.widget.config.showGroupedActionsButton;
    }

    public get except(): string[]
    {
        if (this.widget.config.sourceType.key === WidgetTypeEnum.Sitemap)
        {
            const exceptedTypes = [
                'document-notifications-create',
                'canManageParticipants',
                'canUploadOneDrive',
                'bpmn-set-targets',
                'canSetPrivate',
                'canEditSimple'
            ];

            return exceptedTypes;
        }

        return Object.values(this.actions)
            .filter((p) => !this.widget.config.actionButtons?.includes(p.endpointId))
            .map((p) => p.key);
    }

    public get portalTargetName(): string
    {
        return 'widget-header-' + this.widget.publicId;
    }

    public get gridStackAttributes(): GridItemAttributes
    {
        return {
            id: `x${this.widget.publicId}`,
            'gs-id': `x${this.widget.publicId}`,
            'gs-x': this.widget.x,
            'gs-y': this.widget.y,
            'gs-w': this.widget.w,
            'gs-h': this.widget.h,
            'gs-no-resize': true,
        };
    }

    public get name(): string
    {
        return this.$i18n.currentTranslation(this.widget.name);
    }

    public get isFooterActions(): boolean
    {
        return this.widget.config.additionalData?.footerButtons?.length > 0;
    }

    public get isNotList(): boolean
    {
        const type = getWidgetType(this.widget.config);

        return type !== WidgetTypeEnum.List && type !== WidgetTypeEnum.ActiveSubstitutions;
    }

    public getWidgetComponent(widget: APIWidget): string
    {
        const type = getWidgetType(widget);

        switch (type)
        {
            case WidgetTypeEnum.List:
                return 'ListWidget';
            case WidgetTypeEnum.Count:
                return 'CountWidget';
            case WidgetTypeEnum.ActiveSubstitutions:
                return 'ListWidget';
            case WidgetTypeEnum.NewEmployees:
                return 'EmployeesWidget';
            case WidgetTypeEnum.Chart:
                return 'ChartWidget';
            case WidgetTypeEnum.DoubleCounter:
                return '';
            case WidgetTypeEnum.Calendar:
                return 'CalendarWidget';
            case WidgetTypeEnum.Timetable:
                return 'TimetableWidget';
        }
    }

    public changeSettings(): void
    {
        this.$events.$emit('widget-settings-modal', this.widget, this.dashboardId);
    }

    public metaChanged(data: Record<string, IMetaModel>): void
    {
        this.actions = data ?? {};
    }

    public handleEvent(item: IMetaModel): void
    {
        const key = item.key;

        switch (key)
        {
            case WidgetActionsEnum.ShowAll: {
                const link = item.url;

                link && this.redirect(link);
                break;
            }
            case WidgetActionsEnum.Add: {
                const link = item.url;

                link && this.redirect(link);
                break;
            }
            default:
                break;
        }
    }

    public handleWidgetClick(): void
    {
        if (this.isNotList && this.isFooterActions)
        {
            const action = this.widget.config.additionalData?.footerButtons?.[0];

            action && this.handleEvent(action);
        }
    }

    public toKebabCase(str: string): string
    {
        return kebabCase(str);
    }

    public onSelectedGuidsChanged(value: any): void
    {
        this.selectedGuids = value;
    }
}
</script>

<style lang="scss" scoped>
.grid-stack-item-content {
    display: flex;
    flex-direction: column;
    position: relative;

    .bg-widget {
        background: var(--ideo-bg-primary);
    }

    &.move {
        cursor: move;
    }

    :deep(.card-header .flex-fill) {
        overflow: hidden;
    }
}
</style>
