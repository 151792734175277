// Blueprints
import { Blueprint } from "../../base/blueprints/Blueprint";
import { AggregateBlueprint } from "../../base/blueprints/AggregateBlueprint";
import { RootBlueprint } from "../../base/blueprints/RootBlueprint";
import { PageBlueprint } from "../blueprints/PageBlueprint";
import { RowBlueprint, instanceOfRowBlueprint } from "../blueprints/RowBlueprint";

// Traits
import { HasWidth } from "../../base/traits/HasWidth";

// Managers
import { ClipboardManager } from "../../base/managers/ClipboardManager";
import { DimensionsManager } from "../../base/managers/DimensionsManager";
import { EventManager, Events } from "../../base/managers/EventManager";
import { SchemaManager } from "../../base/managers/SchemaManager";
import { FormLayoutManager } from "./FormLayoutManager";

export class FormClipboardManager extends ClipboardManager
{
    private dimensions: DimensionsManager;
    private placeholder: Blueprint = null;

    public constructor(eventManager: EventManager, schemaManager: SchemaManager, layoutManager: FormLayoutManager, dimensionsManager: DimensionsManager)
    {
        super(eventManager, schemaManager, layoutManager);

        this.dimensions = dimensionsManager;

        this.events.subscribe(Events.INSERT, (parent: AggregateBlueprint, component: Blueprint) =>
        {
            this.selectPlaceholder(parent as RowBlueprint, component);
        });
    }

    public paste(parent: AggregateBlueprint, before: Blueprint = null): void
    {
        super.paste(parent, before);

        if (instanceOfRowBlueprint(parent) && this.dimensions.space(parent) < 0)
        {
            this.dimensions.autoarrange(parent);
        }
    }

    public canPaste(parent: AggregateBlueprint): boolean
    {
        if (this.mode != 'none' && instanceOfRowBlueprint(parent) && this.source != null)
        {
            if (this.mode == 'copy' && (this.source as HasWidth).minWidth <= this.dimensions.available(parent) && this.schema.parent(parent, this.source.type) === null)
            {
                return true;
            }

            if (this.mode == 'cut' && this.dimensions.available(parent, this.source) >= 0 && this.schema.parent(parent, this.source.type) === null)
            {
                return true;
            }
        }

        return false;
    }

    public selectForm(document: RootBlueprint): void
    {
        this.selectComponent(document);
    }

    public isFormSelected(document: RootBlueprint = null): boolean
    {
        return this.isSelected(document, 'form');
    }

    public selectPage(area: PageBlueprint): void
    {
        this.selectComponent(area);
    }

    public isPageSelected(page: PageBlueprint = null): boolean
    {
        return this.isSelected(page, 'page');
    }

    public selectRow(slot: RowBlueprint): void
    {
        this.selectComponent(slot);
    }

    public isRowSelected(slot: RowBlueprint = null): boolean
    {
        return this.isSelected(slot, 'row');
    }

    public selectPlaceholder(slot: RowBlueprint, component: Blueprint): void
    {
        this.unselect();

        if (this.dimensions.available(slot) > 0)
        {
            if (this.dimensions.space(slot) == 0)
            {
                this.dimensions.rearrange(slot);
            }

            this.selectRow(slot);
            this.placeholder = component;
        }
    }

    public isPlaceholderSelected(slot: RowBlueprint, component: Blueprint): boolean
    {
        return this.isRowSelected(slot) && ((this.placeholder == null && component == null) || (this.placeholder?.id == component?.id));
    }

    public selectComponent(component: Blueprint): void
    {
        super.selectComponent(component);
        this.placeholder = null;
    }

    public getParentTarget(): AggregateBlueprint
    {
        const parent = this.schema.parent(this.target);

        return parent as AggregateBlueprint;
    }
}
