<script setup lang="ts">
import { ref, computed, inject, watch, provide } from 'vue';
import { computedAsync } from '@vueuse/core';
import { useLocalization } from '@/plugins/localization';
import Pager from '@/helpers/Pager';
import lodashLast from 'lodash/last';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { RowContract } from '../row';
import { SectionColors } from '../section';
import { LogitoDocumentDetailsType, DocumentDetailsMode } from '.';
import {
    Definition as RelatedDocumentDefinition,
    RelatedDocumentEntry,
    RelatedDocumentType,
} from '../logito-related-documents';
import { Option } from '@/helpers/Interfaces';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import FormsService from '@/modules/studio/forms/services/FormsService';
import AutocompleteService from '@/modules/core/common/services/AutocompleteService';
import BpmnService from '@/modules/low-code/process/services/BpmnService';
import ColumnsCustomizer from '@/components/common/dynamic-grid/ColumnsCustomizer.vue';

defineOptions({
    name: 'logito-document-details-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const { $t } = useLocalization();

const moduleOptions = ref<any[]>([]);
const filters = ref<any>(null);

const blueprint = computed(() => props.blueprint);

if (props.blueprint.components.length == 0)
{
    props.form.layout.addRow(props.blueprint);
}

const canEdit = inject('canEdit', false);
const design = computed(() => props.form.designMode());
const title = computed(() => props.form.localization.translate(blueprint.value.title) || $t('[[[Szczegóły dokumentu]]]'));
const description = computed(() => props.form.localization.translate(blueprint.value.description));
const readonly = computed(() => props.form.expressions.readonly(props.blueprint));
const rows = computed<RowContract[]>(() => props.blueprint.components as RowContract[]);
const last = computed<RowContract>(() => lodashLast(rows.value));

const isFieldMode = computed(() => blueprint.value.mode == DocumentDetailsMode[DocumentDetailsMode.Field]);
const isCurrentDocMode = computed(() => blueprint.value.mode == DocumentDetailsMode[DocumentDetailsMode.Current]);
const isQueryMode = computed(
    () =>
        blueprint.value.mode == DocumentDetailsMode[DocumentDetailsMode.Single] ||
        blueprint.value.mode == DocumentDetailsMode[DocumentDetailsMode.Multi]
);

const isFields = computed(() => blueprint.value.fields && Object.keys(blueprint.value.fields).length > 0);

const detailsModeOptions = computed(() => [
    {
        value: DocumentDetailsMode[DocumentDetailsMode.Field],
        text: $t('[[[Dokument z pola]]]'),
    },
    {
        value: DocumentDetailsMode[DocumentDetailsMode.Single],
        text: $t('[[[Pojedynczy dokument]]]'),
    },
    {
        value: DocumentDetailsMode[DocumentDetailsMode.Current],
        text: $t('[[[Bieżący dokument]]]'),
    },
]);

const relatedDocumentComponents = computed(() =>
    props.form.schema
        .components(RelatedDocumentDefinition.type)
        .filter((p) => (p as RelatedDocumentType).modules.length == 1)
        .map((p) => ({
            value: p.name,
            text: `${props.form.localization.translate((p as HasLabel).label)} (${p.name})`,
        }))
);

const moduleSelectOptions = computed(() => moduleOptions.value.map((p) => ({ value: p.key, text: p.value })));

const moduleId = computed(() =>
{
    if (blueprint.value.moduleId) return blueprint.value.moduleId;

    if (blueprint.value.fieldName)
        return (
            props.form.schema.components().find((p) => p.name == blueprint.value.fieldName) as RelatedDocumentType
        ).modules.first();

    return null;
});

const fieldOptions = computedAsync(async () =>
{
    if (!design.value || moduleId.value == null) return [];

    const fields = await AutocompleteService.fetchOptionsByCustomEndpoint(
        `admin/forms/module-fields/${moduleId.value}`,
        null,
        new Pager(1, 999, '', 'ASC')
    );

    if (!fields) return [];

    return fields.items.map((item) => item.result).map((p) => ({ ...p, key: p.key, value: `${p.value} (${p.key})` }));
});

const documentDetails = computedAsync(async () =>
{
    if (design.value) return null;

    const selectedDocuments =
        blueprint.value.mode == DocumentDetailsMode[DocumentDetailsMode.Field]
            ? (props.entry.find(blueprint.value.fieldName) as RelatedDocumentEntry).data.map((p) => p.key)
            : [];

    const fieldFilters: Record<string, string> = {};

    for (const item in blueprint.value.filterMappings)
    {
        const blueprint = props.form.schema.find(item) as EntryFactory<any>;
        const parent = props.form.schema.parent(blueprint);

        if (!blueprint) continue;

        const element = parent?.type === 'table' ? props.entry[parent.name].data[props.index][item] : props.entry[item];

        if (element && 'getValues' in element)
        {
            fieldFilters[item] = element.getValues(blueprint, props.form);
        }
    }

    return await BpmnService.getDocumentsDetails(
        props.entry.module,
        props.entry.formId,
        blueprint.value.name,
        selectedDocuments,
        fieldFilters
    );
});

const schema = computed(() => documentDetails.value?.schema ?? {});
const data = computed(() => documentDetails.value?.data?.[0]);

provide('schema', schema);
provide('data', data);

const selectedModule = computed(() =>
{
    if (!moduleId.value) return null;

    return moduleOptions.value.find((p) => p.key == moduleId.value);
});

const colorOptions: Option<SectionColors>[] = [
    {value: SectionColors.None, text: $t('[[[Brak]]]')},
    {value: SectionColors.Primary, text: $t('[[[Podstawowy]]]')},
    {value: SectionColors.Secondary, text: $t('[[[Drugorzędny]]]')},
    {value: SectionColors.Success, text: $t('[[[Sukces]]]')},
    {value: SectionColors.Info, text: $t('[[[Informacja]]]')},
    {value: SectionColors.Warning, text: $t('[[[Ostrzeżenie]]]')},
    {value: SectionColors.Danger, text: $t('[[[Zagrożenie]]]')},
    {value: SectionColors.Light, text: $t('[[[Jasny]]]')},
    {value: SectionColors.Dark, text: $t('[[[Ciemny]]]')},
];

const addRow = (before: RowContract): void =>
{
    props.form.layout.addRow(props.blueprint, before);
};

const addComponent = (definition: BlueprintDefinition, before: RowContract): void =>
{
    const row = props.form.layout.addRow(props.blueprint, before);

    props.form.layout.addComponent(row, definition);
};

const dropComponent = (component: Blueprint, before: RowContract): void =>
{
    const row = props.form.layout.addRow(props.blueprint, before);

    props.form.clipboard.cut(component);
    props.form.clipboard.paste(row);
};

const lastComponentsLength = computed(() => last.value.components.length);
const blueprintWidth = computed(() => props.blueprint.width);

const fetchModuleOptions = async () =>
{
    const response = (await FormsService.getModuleOptions(new Pager(1, 999))).items.map((p) => p.result);

    moduleOptions.value = response;
};

const updateMode = (): void =>
{
    blueprint.value.components = [];
    props.form.layout.addRow(props.blueprint);
    blueprint.value.moduleId = null;
    blueprint.value.fieldName = null;
    blueprint.value.fields = {};
};

watch(
    () => moduleId.value,
    async () =>
    {
        if (!moduleId.value)
        {
            blueprint.value.fields = {};

            return;
        }

        blueprint.value.fields = await FormsService.getModuleDetailsFields(moduleId.value as string);
        filters.value = {};
    }
);

watch(
    () => blueprint.value.mode,
    async () =>
    {
        if (!isCurrentDocMode.value) return;

        blueprint.value.fields = await FormsService.getModuleDetailsFields();
        filters.value = {};
    }
);

watch(
    () => filters.value,
    () => (blueprint.value.filtersJson = filters.value ? JSON.stringify(filters.value) : null),
    { deep: true }
);

watch(lastComponentsLength, (length: number): void =>
{
    if (length && length > 0)
    {
        props.form.layout.addRow(props.blueprint);
    }
});

watch(blueprintWidth, (_: number): void =>
{
    props.blueprint.components.forEach((row: any) => props.form.dimensions.autoarrange(row, false));
});

const init = () =>
{
    if (design.value)
    {
        fetchModuleOptions();
        filters.value = blueprint.value.filtersJson ? JSON.parse(blueprint.value.filtersJson) : null;
    }
};

init();
</script>

<template>
    <form-component-wrapper
        class="logito-document-details-component"
        :form="form"
        :parent="parent"
        :blueprint="blueprint"
        :disabled="false"
    >
        <template #default>
            <fieldset :disabled="readonly" :class="{'design': design, [`alert alert-${blueprint.color}`]: blueprint.color != 'none'}">
                <legend v-if="(blueprint.title && blueprint.showTitle) || design" :class="{'text-secondary': !blueprint.showTitle}">
                    {{ title }}
                </legend>
                <p class="description" v-if="description">{{ description }}</p>
                <div v-if="isFields" class="rows">
                    <template v-for="(component, index) in rows" :key="component.id">
                        <design-ruller :title="$t('[[[Wstaw kontrolkę]]]')" :form="form" :before="component" @insert="addRow" @add="addComponent" @drop="dropComponent" v-if="component.components.length > 0 && canEdit" />
                        <component :is="form.schema.designer(component.type)" :form="form" :parent="blueprint" :blueprint="rows[index]" :entry="entry"></component>
                    </template>
                </div>
                <p v-else class="no-choice">{{ $t('[[[Uzupełnij konfigurację]]]') }}</p>
            </fieldset>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.title[locale]" :label="$t('[[[Tytuł sekcji]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showTitle" :label="$t('[[[Pokaż tytuł sekcji]]]')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.description[locale]" :label="$t('[[[Opis]]]')" />
            </ideo-form-localize>
            <field-select v-model="blueprint.color" :options="colorOptions" :label="$t('[[[Akcent]]]')" />
            <field-select
                v-model="blueprint.mode"
                :options="detailsModeOptions"
                :label="$t('[[[Tryb wyświetlania]]]')"
                :disabled="!canEdit"
                :required="true"
                @update:model-value="updateMode"
            />
            <field-select
                v-if="isFieldMode"
                v-model="blueprint.fieldName"
                :options="relatedDocumentComponents"
                :label="$t('[[[Pole z dokumentem]]]')"
                :disabled="!canEdit"
                :required="true"
                :invalid-feedback="() => form.schema.errorMessage(blueprint, 'fieldName')"
            />
            <template v-if="isQueryMode">
                <field-select
                    v-model="blueprint.moduleId"
                    :options="moduleSelectOptions"
                    :label="$t('[[[Moduł]]]')"
                    :invalid-feedback="() => form.schema.errorMessage(blueprint, 'moduleId')"
                    required
                    :disabled="!canEdit"
                />
                <field-filters
                    v-if="blueprint.moduleId"
                    v-model="filters"
                    :selected-modules="[selectedModule]"
                    :label="$t('[[[Filtry]]]')"
                />
                <field-map
                    v-if="blueprint.moduleId"
                    v-model="blueprint.filterMappings"
                    :label="$t('[[[Filtry wartościami pól formularza]]]')"
                    :form="form"
                    :blueprint="blueprint"
                    :options="fieldOptions"
                    :selected-modules="[selectedModule]"
                    is-filters
                />
            </template>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-readonly :form="form" :blueprint="blueprint" :label="$t('[[[Tylko do odczytu]]]')" />
            <field-error :form="form" :blueprint="blueprint" :label="$t('[[[Niestandardowy błąd]]]')" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
.logito-document-details-component {
    padding: 0px;

    fieldset {
        display: block;
        padding: 1rem 0.75rem 0 0.75rem;
        margin: 0 0.75rem 1rem 0.75rem;

        &:not(.alert) {
            border: 1px solid var(--bs-border-color);
        }

        > legend {
            display: block;
            font-size: 1rem;
            font-weight: 400;
            margin: 0 0 1rem 0;
        }

        &.design {
            padding: 0;
            margin: 0;

            > legend {
                padding: 0 0.75rem;
                margin: 0.75rem 0 0.25rem 0;
            }

            > p {
                padding: 0.75rem;
                margin: 0;
            }
        }
    }
}
.logito-document-details-component:deep(> .wrapper) {
    padding-top: 0px !important;
}
</style>
