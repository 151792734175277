import EmptyLayout from '@/views/layouts/EmptyLayout.vue';
import Dashboard from '@/modules/core/home/views/Dashboard.vue';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    // --------------------------------------------------------------------------
    // Strona główna
    // --------------------------------------------------------------------------
    {
        path: 'dashboard',
        name: 'dashboard',
        meta: { auth: true },
        component: Dashboard,
    },

    // --------------------------------------------------------------------------
    // Moje konto
    // --------------------------------------------------------------------------
    {
        path: 'account',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Profil użytkownika
            {
                path: 'profile',
                name: 'core-account-profile',
                meta: { auth: true },
                component: () => import('@/modules/core/account/views/Profile.vue'),
            },

            // Zmiana hasła
            {
                path: 'password',
                name: 'core-account-password',
                meta: { auth: true, stop: true },
                component: () => import('@/modules/core/account/views/Password.vue'),
            },

            // Powiadomienia
            {
                path: 'notifications',
                name: 'core-account-notifications',
                meta: { auth: true },
                component: () => import('@/modules/core/account/views/LogitoNotifications.vue'),
            },

            // Bezpieczeństwo
            {
                path: 'security',
                name: 'core-account-security',
                meta: { auth: true },
                component: () => import('@/modules/core/account/views/Security.vue')
            },

            // Zastępstwa
            {
                path: 'substitutions/add',
                name: 'account-substitutions-add',
                meta: { auth: true },
                component: () => import('@/modules/core/substitutions/views/SubstitutionForm.vue'),
            },
            {
                path: 'substitutions/:publicId',
                name: 'account-substitutions-edit',
                props: true,
                meta: { auth: true, breadcrumbs: '[[[Zastępstwa]]]' },
                component: () => import('@/modules/core/substitutions/views/SubstitutionForm.vue'),
            },
            {
                path: 'substitutions/list/:filterName(\\w+)?/:filterValue(\\d+)?',
                name: 'account-substitutions',
                meta: { auth: true, breadcrumbs: '[[[Zastępstwa]]]' },
                props: true,
                component: () => import('@/modules/core/substitutions/views/MySubstitutionList.vue'),
            },
        ],
    },

    // --------------------------------------------------------------------------
    // Administracja
    // --------------------------------------------------------------------------
    {
        path: 'admin',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // --------------------------------------------------------------------------
            // Struktura organizacyjna
            // --------------------------------------------------------------------------

            // Konta pracowników
            {
                path: 'users/add',
                name: 'core-users-add',
                meta: { auth: true },
                component: () => import('@/modules/core/users/views/LogitoUserForm.vue'),
            },
            {
                path: 'users/:publicId',
                name: 'core-users-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/users/views/LogitoUserForm.vue'),
            },
            {
                path: 'users/:filterName(\\w+)?/:filterValue(\\d+)?',
                name: 'core-users',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/users/views/LogitoUsersList.vue'),
            },

            // Spółki
            {
                path: 'companies',
                name: 'core-companies',
                meta: { auth: true, breadcrumbs: '[[[Spółki]]]' },
                component: () => import('@/modules/core/companies/views/CompanyList.vue'),
            },
            {
                path: 'companies/:publicId',
                name: 'core-companies-details',
                meta: { auth: true, breadcrumbs: '[[[Szczegóły spółki]]]' },
                props: true,
                component: () => import('@/modules/core/companies/views/CompanyDetails.vue'),
            },

            // Stanowiska pracy
            {
                path: 'job-positions',
                name: 'core-jobPositions',
                meta: { auth: true, breadcrumbs: '[[[Stanowiska pracy]]]' },
                component: () => import('@/modules/core/job-positions/views/JobPositionsList.vue'),
            },

            // --------------------------------------------------------------------------
            // Role i uprawnienia
            // --------------------------------------------------------------------------
            {
                path: 'roles',
                name: 'core-roles',
                meta: { auth: true },
                component: () => import('@/modules/core/roles/views/RolesList.vue'),
            },
            {
                path: 'roles/add',
                name: 'core-roles-add',
                meta: { auth: true },
                component: () => import('@/modules/core/roles/views/RoleForm.vue'),
            },
            {
                path: 'roles/:id(\\d+)',
                name: 'core-roles-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/roles/views/RoleForm.vue'),
            },

            // --------------------------------------------------------------------------
            // Organizacja
            // --------------------------------------------------------------------------
            {
                path: 'units',
                name: 'core-units',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/organization/views/UnitForm.vue')
            },
            {
                path: 'units/add',
                name: 'core-units-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/organization/views/UnitForm.vue')
            },
            {
                path: 'units/:id(\\d+)',
                name: 'core-units-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreOrganizationalUnit' },
                props: true,
                component: () => import('@/modules/core/organization/views/UnitForm.vue')
            },

            // --------------------------------------------------------------------------
            // Zespoły
            // --------------------------------------------------------------------------
            {
                path: 'teams',
                name: 'core-teams',
                meta: { auth: true },
                component: () => import('@/modules/core/teams/views/TeamsList.vue'),
            },
            {
                path: 'teams/add',
                name: 'core-teams-add',
                meta: { auth: true },
                component: () => import('@/modules/core/teams/views/TeamForm.vue'),
            },
            {
                path: 'teams/:id(\\d+)',
                name: 'core-teams-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/teams/views/TeamForm.vue'),
            },


            // --------------------------------------------------------------------------
            // Zastępstwa
            // --------------------------------------------------------------------------
            {
                path: 'substitutions/add',
                name: 'core-substitutions-add',
                meta: { auth: true },
                component: () => import('@/modules/core/substitutions/views/SubstitutionForm.vue'),
            },
            {
                path: 'substitutions/:publicId',
                name: 'core-substitutions-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/substitutions/views/SubstitutionForm.vue'),
            },
            {
                path: 'substitutions/:filterName(\\w+)?/:filterValue(\\d+)?',
                name: 'core-substitutions',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/substitutions/views/LogitoSubstitutionList.vue'),
            },

            // --------------------------------------------------------------------------
            // Biuro
            // --------------------------------------------------------------------------

            // Rejestry
            {
                path: 'register-documents',
                name: 'core-registerDocuments',
                meta: { auth: true },
                component: () => import('@/modules/core/register-documents/views/List.vue')
            },
            {
                path: 'register-documents/add',
                name: 'core-registerDocuments-add',
                meta: { auth: true },
                component: () => import('@/modules/core/register-documents/views/Form.vue'),
            },
            {
                path: 'register-documents/:publicId',
                name: 'core-registerDocuments-edit',
                component: () => import('@/modules/core/register-documents/views/Form.vue'),
            },

            // --------------------------------------------------------------------------
            // Licencja
            // --------------------------------------------------------------------------

            {
                path: 'licence',
                name: 'core-licence',
                meta: { auth: true },
                component: () => import('@/modules/core/licence/views/Details.vue')
            },
        ]
    },
    // --------------------------------------------------------------------------
    // Ustawienia
    // --------------------------------------------------------------------------
    {
        path: 'settings',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Konta użytkowników
            // {
            //     path: 'accounts',
            //     name: 'core-accounts',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/settings/views/Accounts.vue')
            // },

            // Bezpieczeństwo
            {
                path: 'security',
                name: 'core-security',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Security.vue')
            },

            // Recaptcha
            {
                path: 'recaptcha',
                name: 'core-recaptcha',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/ReCaptcha.vue')
            },

            // Słowniki
            {
                path: 'dictionaries',
                name: 'core-dictionaries',
                meta: { auth: true, breadcrumbs: '[[[Słowniki]]]' },
                component: () => import('@/modules/core/dictionaries/views/DictionariesList.vue'),
            },

            // Terminy słowników
            {
                path: 'dictionary-terms',
                name: 'core-dictionaryTerms',
                meta: { auth: true, breadcrumbs: '[[[Ustawienia ogólne]]]' },
                component: () => import('@/modules/core/dictionary-terms/views/DictionaryTermsList.vue'),
            },

            // Tabele wektorowe
            {
                path: 'vector-collections',
                name: 'vector-collections',
                meta: { auth: true, breadcrumbs: '[[[Tabele wektorowe]]]' },
                component: () => import('@/modules/core/vector-collections/views/VectorCollectionsList.vue'),
            },

            // Wpisy kolekcji
            {
                path: 'collection-entries',
                name: 'collection-entries',
                meta: { auth: true, breadcrumbs: '[[[Dane wektorowe]]]' },
                component: () => import('@/modules/core/collection-entries/views/CollectionEntriesList.vue'),
            },

            // Szablony dokumentów
            {
                path: 'print-templates',
                name: 'core-print-templates',
                meta: { auth: true },
                component: () => import('@/modules/low-code/print-templates/views/ParentList.vue'),
            },
            {
                path: 'print-templates-form',
                name: 'core-print-templates-add',
                meta: { auth: true },
                component: () => import('@/modules/low-code/print-templates/views/Form.vue'),
            },
            {
                path: 'print-template/:publicId',
                name: 'core-print-templates-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/print-templates/views/Form.vue'),
            },

            // Źródła danych
            {
                path: 'integrators',
                name: 'integrators',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/register-data-sources/views/List.vue'),
            },
            {
                path: 'integrators-add',
                name: 'integrators-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/register-data-sources/views/Form.vue'),
            },
            {
                path: 'integrators-edit/:publicId',
                name: 'integrators-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/register-data-sources/views/Form.vue'),
            },

            // Powiadomienia
            {
                path: 'notifications/templates',
                name: 'core-notifications-templates',
                meta: { auth: true },
                component: () => import('@/modules/core/notifications/views/NotificationTemplatesList.vue'),
            },
            {
                path: 'notifications/templates/add',
                name: 'core-notifications-templates-add',
                meta: { auth: true },
                component: () => import('@/modules/core/notifications/views/NotificationTemplateForm.vue'),
            },
            {
                path: 'notifications/templates/edit/:id',
                name: 'core-notifications-templates-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/notifications/views/NotificationTemplateForm.vue'),
            },

            // Powiadomienia zbiorcze
            {
                path: 'bulk-notifications',
                name: 'core-bulk-notifications',
                meta: { auth: true },
                component: () => import('@/modules/core/bulk-notifications/views/BulkNotificationsList.vue'),
            },
            {
                path: 'bulk-notifications/add',
                name: 'core-bulk-notifications-add',
                meta: { auth: true },
                component: () => import('@/modules/core/bulk-notifications/views/BulkNotificationForm.vue'),
            },
            {
                path: 'bulk-notifications/edit/:publicId',
                name: 'core-bulk-notifications-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/bulk-notifications/views/BulkNotificationForm.vue'),
            },

            // Źródła LDAP
            {
                path: 'ldap',
                name: 'core-ldap',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "core" */ '@/modules/core/ldap/views/LdapSourcesList.vue'),
            },
            {
                path: 'add',
                props: true,
                name: 'core-ldap-add',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "core" */ '@/modules/core/ldap/views/LdapForm.vue'),
            },
            {
                path: 'edit/:id',
                props: true,
                name: 'core-ldap-edit',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "core" */ '@/modules/core/ldap/views/LdapForm.vue'),
            },

            // Poczta
            {
                path: 'mailing',
                name: 'core-mailing',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Mailing.vue')
            },

            // Powiadomienia
            {
                path: 'modules-form/:moduleId?',
                name: 'lowCode-modules-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/settings/Form.vue'),
            },

            // Kody kontrolingowe
            {
                path: 'control-codes',
                name: 'core-controlCodes-list',
                meta: { auth: true, breadcrumbs: '[[[Kody kontrollingowe]]]' },
                component: () => import('@/modules/core/control-codes/views/ControlCodesList.vue'),
            },
            {
                path: 'control-codes/add',
                name: 'core-controlCodes-add',
                meta: { auth: true, breadcrumbs: '[[[Dodaj kod kontrolingowy]]]' },
                component: () => import('@/modules/core/control-codes/views/ControlCodesForm.vue'),
            },
            {
                path: 'control-codes/:publicId',
                name: 'core-controlCodes-edit',
                meta: { auth: true, breadcrumbs: '[[[Edycja kodu kontrolingowego]]]' },
                component: () => import('@/modules/core/control-codes/views/ControlCodesForm.vue'),
            },

            // Zmienne środowiskowe
            {
                path: 'env-variables',
                name: 'core-env-variables',
                meta: { auth: true, breadcrumbs: '[[[Zmienne środowiskowe]]]' },
                component: () => import('@/modules/core/env-variables/views/EnvVariablesList.vue'),
            },

            // Moduły
            {
                path: 'modules',
                name: 'lowCode-modules-list',
                meta: { auth: true },
                component: () => import('@/modules/low-code/views/administration/settings/List.vue'),
            },
            {
                path: 'modules-form',
                name: 'lowCode-modules-add',
                meta: { auth: true },
                component: () => import('@/modules/low-code/views/administration/settings/Form.vue'),
            },
            {
                path: 'modules-form/:moduleId?',
                name: 'lowCode-modules-edit',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/low-code/views/administration/settings/Form.vue'),
            },

            // Sitemap
            {
                path: 'sitemap',
                name: 'lowCode-sitemap',
                meta: { auth: true },
                component: () => import('@/modules/core/sitemap/views/SitemapForm.vue')
            },
            {
                path: 'sitemap/add',
                name: 'lowCode-sitemap-add',
                meta: { auth: true },
                component: () => import('@/modules/core/sitemap/views/SitemapForm.vue')
            },
            {
                path: 'sitemap/edit/:publicId',
                name: 'lowCode-sitemap-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/sitemap/views/SitemapForm.vue')
            },

            // Pola wspólne
            {
                path: 'shared-fields',
                name: 'lowCode-shared-fields',
                meta: { auth: true },
                component: () => import('@/modules/low-code/views/administration/shared-fields/List.vue')
            },

            // Paleta kolorów
            {
                path: 'colors',
                name: 'core-colors',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Visualization.vue')
            },
            // Tłumaczenia
            {
                path: 'translations',
                name: 'core-translations',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Translations.vue')
            },
            // Ocr
            {
                path: 'ocr',
                name: 'core-ocr',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Ocr.vue')
            },
            // Logs - cele/targets
            {
                path: 'logs/targets',
                name: 'core-logs-targets',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsTargetList.vue'),
            },
            {
                path: 'logs/targets/add',
                props: true,
                name: 'core-logs-targets-add',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsTargetForm.vue')
            },
            {
                path: 'logs/targets/edit/:id',
                props: true,
                name: 'core-logs-targets-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsTargetForm.vue')
            },
            // Logs - role
            {
                path: 'logs/rules',
                name: 'core-logs-rules',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsRuleList.vue'),
            },
            {
                path: 'logs/rules/add',
                props: true,
                name: 'core-logs-rules-add',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsRuleForm.vue')
            },
            {
                path: 'logs/rules/edit/:id',
                props: true,
                name: 'core-logs-rules-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/LogsRuleForm.vue')
            },
            // Skrypty integracyjne
            {
                path: 'scripts',
                name: 'lowCode-scripts',
                meta: { auth: true },
                component: () => import('@/modules/low-code/views/administration/scripts/List.vue'),
            },
            {
                path: 'scripts/add',
                props: true,
                name: 'lowCode-scripts-add',
                meta: { auth: true },
                component: () => import('@/modules/low-code/views/administration/scripts/Form.vue'),
            },
            {
                path: 'scripts/:publicId/edit',
                props: true,
                name: 'lowCode-scripts-edit',
                meta: { auth: true },
                component: () => import('@/modules/low-code/views/administration/scripts/Form.vue'),
            },
            // // Sztuczna inteligencja
            // {
            //     path: 'ai',
            //     name: 'core-ai',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/settings/views/Ai.vue')
            // },
            // // Tłumaczenia
            // {
            //     path: 'translations',
            //     name: 'core-translations',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/settings/views/Translations.vue')
            // },
            // // Ldap
            // {
            //     path: 'ldap',
            //     name: 'core-ldap',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/ldap/views/LdapSourcesList.vue'),
            // },
            // {
            //     path: 'add',
            //     props: true,
            //     name: 'core-ldap-add',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/ldap/views/LdapForm.vue')
            // },
            // {
            //     path: 'edit/:id',
            //     props: true,
            //     name: 'core-ldap-edit',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/ldap/views/LdapForm.vue')
            // },
            // // Powiadomienia
            // {
            //     path: 'notifications/templates',
            //     name: 'core-notifications-templates',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/notifications/views/NotificationTemplatesList.vue')
            // },
            // {
            //     path: 'notifications/templates/add',
            //     name: 'core-notifications-templates-add',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/notifications/views/NotificationTemplateForm.vue')
            // },
            // {
            //     path: 'notifications/templates/edit/:id',
            //     name: 'core-notifications-templates-edit',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/notifications/views/NotificationTemplateForm.vue')
            // },
            // // Monitoring
            // {
            //     path: 'monitoring',
            //     name: 'core-monitoring',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/settings/views/Monitoring.vue')
            // },
        ]
    },

    // --------------------------------------------------------------------------
    // Narzędzia
    // --------------------------------------------------------------------------
    {
        path: 'tools',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // --------------------------------------------------------------------------
            // Monitoring
            // --------------------------------------------------------------------------

            // Wyszukiwarka
            // {
            //     path: 'search/indices',
            //     name: 'search-indices',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/search/views/IndicesList.vue')
            // },
            // {
            //     path: 'search/stopwords',
            //     name: 'search-stopwords',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/search/views/StopWordsList.vue')
            // },
            // {
            //     path: 'search/synonyms',
            //     name: 'search-synonyms',
            //     meta: { auth: true },
            //     component: () => import('@/modules/core/search/views/SynonymsList.vue')
            // },
            // Historia logowania
            {
                path: 'monitoring/authlog',
                name: 'core-authlog',
                meta: { auth: true },
                component: () => import('@/modules/core/authlog/views/AuthlogList.vue'),
            },

            // Rejestr zmian
            {
                path: 'monitoring/changelog/:sessionId(\\d+)?',
                name: 'core-changelog',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/changelog/views/ChangesList.vue'),
            },
            {
                path: 'monitoring/changelog/diff/:id(\\d+)?',
                name: 'core-changelog-diff',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/changelog/views/ChangesView.vue'),
            },
            // Harmonogram zadań
            {
                path: 'monitoring/scheduler',
                name: 'core-scheduler',
                meta: { auth: true },
                component: () => import('@/modules/core/scheduler/views/JobsList.vue'),
            },
            {
                path: 'monitoring/scheduler/history/:type',
                name: 'core-scheduler-history',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/scheduler/views/JobHistory.vue'),
            },
            {
                path: 'monitoring/scheduler/details/:type/:id',
                name: 'core-scheduler-details',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/scheduler/views/JobDetails.vue'),
            },
        ]
    },

    // --------------------------------------------------------------------------
    // Globalna wyszukiwarka
    // --------------------------------------------------------------------------
    {
        path: 'documents',
        name: 'documents',
        props: route => ({ search: route.query.search }),
        component: () => import('@/modules/low-code/views/documents/GlobalSearch.vue'),
    },

    // --------------------------------------------------------------------------
    // Import
    // --------------------------------------------------------------------------
    {
        path: 'import-details/:importId',
        name: 'import-details',
        props: true,
        component: () => import('@/modules/core/import/views/ImportDetails.vue')
    },
];

export default routes;
