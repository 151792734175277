<script lang="ts" setup>
import { computed } from 'vue';
import { useMixins } from '@/plugins/mixins';
import { useRouter } from 'vue-router';
import { DateTime } from 'luxon';
import { getColor } from '@/modules/core/home/inc/helpers';
import { TimetableItems, TimetableDocument } from '@/modules/core/common/services/GridService';
import { TimeScale } from '@/modules/low-code/components/helpers/TimetableDataHelper';
import { $i18n } from '@/plugins/localization';

interface Settings {
    start: string;
    end: string;
    timeRange: string;
}


interface MonthData {
    month: string;
    year: number;
    days: number;
}

const props = defineProps({
  "licence": null,
  "items": null,
  "settings": null,
  "readOnly": { type: Boolean,  },
  "timeScale": null
});


const router = useRouter();
const { $filters } = useMixins();

const isMonthNameShown = computed(() => ['HalfYearly', 'Yearly'].includes(props.settings.timeRange) && props.timeScale != TimeScale.Week);

const units = computed(() =>
{
    const start = DateTime.fromISO(props.settings.start);
    const end = DateTime.fromISO(props.settings.end);

    if (props.timeScale === TimeScale.Week)
    {
        return getWeeksArray(start, end);
    }
    else
    {
        const diff = end.diff(start, 'days').toObject().days;

        return Array.from({ length: diff + 1 }, (_, i) => start.plus({ days: i }).toFormat('yyyy-MM-dd'));
    }
});

const getWeeksArray = (startDate: DateTime, endDate: DateTime) =>
{
    const weeks = [];

    let start = DateTime.fromISO(startDate.toISODate());

    while (start <= endDate)
    {
        const weekStart = start.startOf('week').toISODate();

        weeks.push(weekStart);
        start = start.plus({ week: 1 });
    }

    return weeks;
};

const getMonthsData = (): MonthData[] =>
{
    const start = DateTime.fromISO(props.settings.start);
    const end = DateTime.fromISO(props.settings.end);

    const polishMonths = [
        "styczeń", "luty", "marzec", "kwiecień", "maj", "czerwiec",
        "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"
    ];

    const months = [];

    let current = start;

    while (current <= end)
    {
        months.push({
            month: polishMonths[current.month - 1],
            year: current.year,
            days: current.daysInMonth
        });

        current = current.plus({ month: 1 });
    }

    return months;
};

const unitName = (value: string, format: string = 'yyyy-MM-dd') =>
{
    if (!value || !format) return '';

    if (props.timeScale === TimeScale.Week)
    {
        const weekNumber = DateTime.fromISO(value).weekNumber;

        return weekNumber;
    }
    else
    {
        const day = new Date(value).toLocaleDateString($i18n.shortLocale(), { weekday: 'short' });

        return (day.charAt(0).toUpperCase() + day.slice(1)).slice(0, 2);
    }
};

const activeUnit = (unit: string) =>
{
    if (props.timeScale === TimeScale.Week)
    {
        return DateTime.fromISO(unit).weekNumber === DateTime.utc().weekNumber;
    }

    return DateTime.fromISO(unit).toISODate() === DateTime.utc().toISODate();
};

const unitDate = (unit: string, doc: TimetableDocument) =>
{
    if (doc?.shortName)
        return doc?.shortName;

    if (props.timeScale === TimeScale.Week)
    {
        return DateTime.fromISO(unit).weekNumber;
    }

    return $filters.date(unit, 'dd');
};

const getDocument = (_documents: TimetableDocument[], unit: string) =>
{
    const documents = _documents.filter((document) =>
    {
        const dateFromISO = document.dateFromUtc.setZone('local').toISODate();
        const dateToISO = document.dateToUtc.setZone('local').toISODate();

        let fromDate = DateTime.fromISO(dateFromISO);
        let toDate = DateTime.fromISO(dateToISO);

        if (props.timeScale === TimeScale.Week)
        {
            fromDate = fromDate.startOf('week');
            toDate = toDate.endOf('week');
        }

        const currentDate = DateTime.fromISO(unit);

        return currentDate >= fromDate && currentDate <= toDate;
    });

    return documents;
};

const getTooltip = (doc: TimetableDocument) =>
{
    const title = doc.title || '';
    const description = doc.description
        .filter((item) => item.value)
        .map((item) => `${item.key}: ${item.value || '-'}\n`)
        .join('');

    return `${title}${title && description ? `\n\n` : ''}${description}`;
};

const onRowClick = (item: any) =>
{
    if (!item.publicId || props.readOnly) return;

    router.push({
        name: 'module-form-details',
        params: { licence: props.licence, publicId: item.publicId },
    });
};
</script>

<template>
    <div class="scroll mb-2">
        <table class="timetable">
            <thead>
                <tr v-if="isMonthNameShown && getMonthsData().length" class="month-info-row">
                    <th class="sticky-top"></th>
                    <th v-for="(monthData, index) in getMonthsData()" :key="index" class="month-cell" :class="{ 'first': index == 0 }" :colspan="monthData.days">
                        {{ monthData.month }}
                    </th>
                </tr>
                <tr>
                    <th class="sticky-top"></th>
                    <th
                        v-for="(unit, index) in units"
                        :key="index"
                        class="sticky-top"
                        :class="[
                            {
                                active: activeUnit(unit),
                            },
                            unitName(unit),
                        ]"
                    >
                        {{ unitName(unit) }}<br />
                        {{ timeScale === TimeScale.Day ? unitDate(unit) : '' }}
                    </th>
                </tr>
            </thead>
            <tbody v-for="item in items" :key="item.key">
                <tr>
                    <td :colspan="units.length + 1" class="py-2 text-center text-uppercase">
                        <strong>{{ item.value }}</strong>
                    </td>
                </tr>
                <tr v-for="el in item.groups" :key="el.key" class="user-row">
                    <td class="ts-col">
                        <div class="d-flex">
                            <span class="ml-2">{{ el.value }}</span>
                        </div>
                    </td>
                    <td v-for="unit in units" :key="unit" class="ts-time-col" :class="unitName(unit)">
                        <template v-if="getDocument(el.documents, unit).length">
                            <div class="btn-wrapper">
                                <div
                                    v-for="(doc, index) in getDocument(el.documents, unit)"
                                    :key="`${doc.publicId}${index}`"
                                    v-tooltip="getTooltip(doc)"
                                    class="btn-tooltip"
                                >
                                    <RowActions
                                        :id="doc.publicId"
                                        :disabled="readOnly"
                                        :endpoint="`${licence}/${doc.publicId}/actions`"
                                        :url="licence"
                                        :style="{
                                            color: getColor(doc.color),
                                            background: getColor(doc.color, true),
                                        }"
                                        :except="[
                                            'document-notifications-create',
                                            'canManageParticipants',
                                            'canUploadOneDrive',
                                            'bpmn-set-targets',
                                            'canSetPrivate',
                                        ]"
                                        class="flex-fill rounded-0 border-0 btn-document"
                                        :options="{
                                            calendar: true,
                                            dropdown: { position: 'left' },
                                        }"
                                    >
                                        <template #button-content>
                                            {{ unitDate(unit, doc) }}
                                        </template>
                                        <template #default>
                                            <IdeoDropdownItemButton @click="onRowClick(doc)">
                                                <i class="fas fa-square-list"></i>
                                                <span>{{ $t('[[[Szczegóły]]]') }}</span>
                                            </IdeoDropdownItemButton>
                                        </template>
                                    </RowActions>
                                </div>
                            </div>
                        </template>
                        <span v-else>{{ unitDate(unit) }}</span>
                    </td>
                </tr>
            </tbody>
            <tbody v-if="!items.length">
                <tr>
                    <td :colspan="units.length + 1" class="text-center">
                        <strong>{{ $t('[[[Brak wyników]]]') }}</strong>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style lang="scss" scoped>
.timetable {
    display: contents;
    width: 100%;
    word-wrap: break-word;
    border: 0;
    font-size: 13px;

    .ts-col {
        min-width: 185px;
        text-align: left;
    }

    thead {
        td,
        th {
            padding: 10px;
            width: 100%;
            line-height: 1.2;
            text-align: center;
            background: var(--bs-gray-200);
            border: 1px solid var(--bs-gray-300);
            font-weight: bold;
            border-left: 0px;

            &:first-child {
                border-left: 1px solid var(--bs-gray-300);
            }

            &.sticky-top {
                z-index: 9;
            }

            &.active {
                background: var(--bs-primary);
                color: var(--bs-white);
                border-color: var(--bs-primary);
            }
        }

        .month-info-row {
            width: 100%;

            .month-cell:nth-of-type(even) {
                background-color: rgba(243, 243, 245, 0.5);
            }

            .month-cell {
                border-style: solid !important;
                border-color: #dee2e6 !important;
                border-width: 1px 1px 0px 0px !important;

                &.first {
                    border-left-width: 1px !important;
                }

                &:last-child {
                    border-width: 1px 1px 0px 0px !important;
                }
            }

            .month-cell,
            .sticky-top {
                background-color: white;
                border: unset;
                text-transform: uppercase;
            }
        }
    }

    tbody {
        tr {
            height: 35px;

            &:hover {
                background: var(--bs-light);
            }
        }
    }

    td {
        padding: 6px 12px;
        border: 1px solid var(--bs-gray-300);
        border-top: 0px;
        border-left: 0px;

        &:first-child {
            border-left: 1px solid var(--bs-gray-300);
        }

        @media screen and (max-width: 1440px) {
            padding: 5px;
        }

        &.active {
            background: var(--body-bg);
        }
    }

    .ts-time-col {
        position: relative;
        text-align: center;
        font-size: 10px;

        .btn-wrapper {
            margin: -6px -12px;
            display: flex;
            flex-direction: column;
            min-height: 34px;

            @media screen and (max-width: 1440px) {
                margin: -5px -5px;
            }

            .btn-tooltip {
                width: 100%;
                flex: 1;
                display: flex;
                flex-direction: column;
                font-size: 0.8125rem;
            }

            .btn-document {
                width: 100%;
                flex-shrink: 0;
                border: 0;
                font-weight: bold;
                font-size: 0.6875rem;
                width: 100%;
                min-height: 25px;
                flex-grow: 1;
            }
        }
    }

    .S,
    .N {
        background: var(--bs-gray-200);
        color: var(--bs-gray-400);
    }

    .sticky-top {
        top: unset;
    }
}
</style>
