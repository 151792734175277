<template>
    <div class="color-options">
        <div
            v-for="(btnStyle, index) in buttonStylesArray"
            :key="index"
            class="color-box btn"
            :class="{
                [`btn-${btnStyle.key.toLowerCase()}`]: true,
                'selected': selectedStyle === btnStyle.key
            }"
            @click="setStyle(btnStyle)"
        >
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { cloneDeep } from 'lodash';
import FieldText from '@/components/dynamic-details/properties/FieldText.vue';
import { FieldScript } from '@/components/forms/properties';

@Options({
    name: 'button-styles',
    components: {
        FieldText,
        FieldScript
    },
})
export default class ButtonStyles extends Vue
{
    @Prop() public modelValue: any;
    @Prop() public buttonStyles: any[];

    public get buttonStylesArray(): any
    {
        return this.buttonStyles ?? [];
    }

    public get selectedStyle(): any
    {
        return this.modelValue?.key ?? '';
    }

    public setStyle(style: any): void
    {
        let value = cloneDeep(this.modelValue);

        value = style;
        this.updateModelValue(value);
    }

    @Emit('update:modelValue')
    public updateModelValue(value: any): any
    {
        return value;
    }
}
</script>
<style scoped lang="scss">
.color-options {
  display: flex;
  gap: 5px;
}

.color-box {
  width: 26px;
  height: 26px;
  min-width: 26px !important;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  transition: transform 0.3s ease;
}

.color-box:hover {
  transform: scale(1.1);
}

.selected {
    border: 2px solid red;
    box-shadow: 0 0 10px red;
}
</style>
