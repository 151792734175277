<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { useMixins } from '@/plugins/mixins';
import { useClipboard } from '@vueuse/core';
import { KeyValuePair } from '@/helpers/Interfaces';
import { formatAccountingValue } from '@/helpers/Utils';

const { $t } = useLocalization();
const { $filters } = useMixins();
const { copy, copied } = useClipboard();

const props = defineProps({
  "property": { default: 0 },
  "features": { default: (): KeyValuePair[] => [] },
  "isOnGrid": { type: Boolean, default: false },
  "isInTable": { type: Boolean, default: false },
  "canCopy": { type: Boolean, default: true }
});

const precision = computed(() => Number.parseInt(props.features.find((p) => p.key == 'precision')?.value ?? '0'));
const prefix = computed(() => props.features.find((p) => p.key == 'prefix')?.value ?? '');
const suffix = computed(() => props.features.find((p) => p.key == 'suffix')?.value ?? '');
const value = computed(() => props.property != null ? $filters.number(props.property, precision.value) : null);
const valueWithPrefixAndSuffix = computed(() => `${prefix.value} ${value.value ?? '-'} ${suffix.value}`);

const tooltip = computed(() =>
{
    if (value.value == null) return '';

    return copied.value ? $t('[[[Pole skopiowane]]]') : $t('[[[Kopiuj pole]]]');
});

const copyValue = () =>
{
    if (value.value == null) return;

    copy(value.value);
};
</script>

<template>
    <div
        class="text-nowrap d-block cursor-default" :class="{ 'text-end': isOnGrid || isInTable }"
    >
        <span @click.stop="copyValue" :class="{ 'cursor-pointer': props.canCopy }" v-tooltip.right="tooltip">
            <template v-if="prefix || suffix">
                {{ valueWithPrefixAndSuffix }}
            </template>
            <template v-else>
                <tempalte v-if="precision">
                    {{ formatAccountingValue(value, precision) }}
                </tempalte>
                <template v-else>
                    {{ value }}
                </template>
            </template>
        </span>
    </div>
</template>
