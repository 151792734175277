import EmptyLayout from '@/views/layouts/EmptyLayout.vue';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: 'devexpress',
        redirect: { name: 'devexpress' },
        component: EmptyLayout,
        children: [
            {
                path: '',
                name: 'devexpress',
                meta: { auth: true, breadcrumbs: '[[[Devexpress]]]' },
                component: (): Promise<any> => import('@/modules/logito/dev-express/components/DevExpressDemo.vue'),
            }
        ]

    }
];

export default routes;