<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { ScriptTypeEnum } from '@/modules/low-code/services/ScriptsService';

import ScriptsDocumentation from '@/modules/low-code/views/administration/scripts/components/common/ScriptsDocumentation.vue';
import ModuleDocumentation from '@/modules/low-code/views/administration/scripts/components/common/ModuleDocumentation.vue';
import ProcessScriptHelper from '@/modules/low-code/process/components/ScriptHelper.vue';
import ProcessScriptConditionHelper from '@/modules/low-code/process/components/ScriptConditionHelper.vue';
import FieldConditionScriptsDoc from '@/components/forms/helpers/FieldConditionScriptsDoc.vue';
import FieldScriptDoc from '@/components/forms/blueprints/logito-table/properties/FieldScriptDoc.vue';
import FieldValidatorExpressionScriptsDoc from '@/components/forms/helpers/FieldValidatorExpressionScriptsDoc.vue';
import FieldTemplateScriptsDoc from '@/modules/low-code/print-templates/helpers/PrintTemplateScriptsDoc.vue';
import DynamicModalScriptsDoc from '@/components/forms/blueprints/logito-dynamic-modal/docs/ScriptsDoc.vue';

@Options({
    components: {
        ScriptsDocumentation,
        ModuleDocumentation,
        ProcessScriptHelper,
        ProcessScriptConditionHelper,
        FieldConditionScriptsDoc,
        FieldScriptDoc,
        FieldValidatorExpressionScriptsDoc,
        FieldTemplateScriptsDoc,
        DynamicModalScriptsDoc
    },
})
export default class Documentation extends Vue
{
    @Prop({ default: null }) public form: any;

    private componentMap: any = {
        [ScriptTypeEnum.ScriptTask]: "ProcessScriptHelper",
        [ScriptTypeEnum.Escalation]: "ProcessScriptConditionHelper",
        [ScriptTypeEnum.DynamicTableField]: "FieldConditionScriptsDoc",
        [ScriptTypeEnum.DynamicTableDefaultRowValue]: "FieldScriptDoc",
        [ScriptTypeEnum.ValidationScript]: "FieldValidatorExpressionScriptsDoc",
        [ScriptTypeEnum.PrintTemplate]: "FieldTemplateScriptsDoc",
        [ScriptTypeEnum.DynamicModal]: "DynamicModalScriptsDoc",
    };

    public get showHelper()
    {
        return this.componentMap.hasOwnProperty((this.form?.scriptType?.key ?? ''));
    }

    public getComponent(type: any)
    {
        return this.componentMap[type.key] || null;
    }
}
</script>

<template>
    <IdeoTabs pills nav-class="nav-justified" content-class="mt-3 docs-container">
        <IdeoTab :title="$t('[[[Pola dokumentu]]]')" active>
            <ModuleDocumentation v-if="form.module" :module="form.module" />
            <div v-else>
                {{ $t('[[[Należy wybrać moduł]]]') }}
            </div>
        </IdeoTab>
        <IdeoTab :title="$t('[[[Dokumentacja]]]')">
            <ScriptsDocumentation v-if="form.scriptEngine" :script-engine="form.scriptEngine" />
            <div v-else>
                {{ $t('[[[Należy wybrać silnik skryptowy]]]') }}
            </div>
        </IdeoTab>
        <IdeoTab v-if="showHelper" :title="$t('[[[Pomoc]]]')">
            <component :is="getComponent(form.scriptType)" v-if="form.scriptEngine" :script-engine="form.scriptEngine.key" />
            <div v-else>
                {{ $t('[[[Należy wybrać silnik skryptowy]]]') }}
            </div>
        </IdeoTab>
    </IdeoTabs>
</template>
<style lang="scss">
.docs-container{
    overflow-y: auto;
    max-height: calc(100vh - 250px);
}
</style>
