<script lang="ts" setup>
import { ref, computed, Ref, watch } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { useRoute } from 'vue-router';
import { useEvents } from '@/plugins/events';
import { LocalizationManager } from '@/components/builder/form/managers/LocalizationManager';
import properties from '../../properties';
import { FieldScript } from '@/components/forms/properties';
import { FormEntry } from '../form';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { DynamicModalEntry, DynamicModalType, instaceOfDynamicModalEntry, DynamicActionType } from '.';
import { ScriptTypeEnum } from '@/modules/low-code/services/ScriptsService';
import DynamicModal from '@/components/forms/blueprints/logito-dynamic-modal/modals/DynamicModal.vue';
import DynamicModalService from '@/modules/low-code/services/DynamicModalService';
import DynamicAction from '@/components/forms/blueprints/logito-dynamic-modal/controls/DynamicAction.vue';
import ButtonStyles from '@/components/forms/blueprints/logito-dynamic-modal/controls/ButtonStyles.vue';

defineOptions({
    name: 'dynamic-modal-blueprint',
    components: {
        ...properties,
        FieldScript,
        DynamicModal,
        DynamicAction
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const { $t } = useLocalization();
const { $events } = useEvents();
const route = useRoute();
const localizationManager = new LocalizationManager();

const entryData = ref(new DynamicModalEntry()) as Ref<DynamicModalEntry>;
const dynamicModalRef = ref<InstanceType<typeof DynamicModal> | null>(null);
const scriptType = computed(() => ScriptTypeEnum.DynamicModal);
const moduleId = computed(() => route.params.moduleId.toString());
const formId = computed(() => route.params.publicId.toString());
const modalModes = ref<any[]>([]);
const dynamicForms = ref<any[]>([]);
const buttonStyles = ref<any[]>([]);
const customBlueprint = ref(null);
const customForm = ref<FormBuilderContract>(null);
const isInitial = ref<boolean>(false);
const isLoadedData = ref<boolean>(false);

entryData.value = props.form.document.initEntry(
    props.blueprint,
    entryData.value,
    instaceOfDynamicModalEntry,
    props.index
);

const blueprint = computed(() => props.blueprint);
const design = computed(() => props.form.designMode());
const label = computed(() => localizationManager.translate(props.blueprint.label));
const icon = computed(() => props.blueprint.showIcon ? props.blueprint.icon : '');
const blueprintDynamic = computed(() => customBlueprint.value != null ? customBlueprint.value : props.blueprint);
const modalForm = computed(() => customForm.value != null ? customForm.value : props.form);

watch(() => (props.form.clipboardManager.target?.id ?? ''), async () =>
{
    const targetId = props.form?.clipboardManager?.target?.id ?? '';

    if (design.value && !isLoadedData.value && targetId == props.blueprint.id && isInitial.value)
    {
        await getDictionary(modalModes, 'modal-modes', { });
        await getDictionary(dynamicForms, 'dynamic-forms', { moduleId: moduleId.value, formId: formId.value });
        await getDictionary(buttonStyles, 'button-styles', { });

        isLoadedData.value = true;
    }
}, {
    immediate: true
});

watch(() => (isInitial.value), async () =>
{
    const targetId = props.form?.clipboardManager?.target?.id ?? '';

    if (design.value && !isLoadedData.value && targetId == props.blueprint.id && isInitial.value)
    {
        await getDictionary(modalModes, 'modal-modes', { });
        await getDictionary(dynamicForms, 'dynamic-forms', { moduleId: moduleId.value });
        await getDictionary(buttonStyles, 'button-styles', { });

        isLoadedData.value = true;
    }
}, {
    immediate: true
});

const init = async () =>
{
    isInitial.value = true;
};

const getDictionary = async (targetRef: any, endpoint: string, dynamicParams: Record<string, any>) =>
{
    const result = await DynamicModalService.GetDictionary(endpoint, dynamicParams);

    targetRef.value = result;
};

const initModal = () =>
{
    dynamicModalRef.value.showModal();
};

const actionEvent = (action: DynamicActionType, builder: FormBuilderContract) =>
{
    if (action.closingModal)
    {
        const components = props.form.blueprint.components;
        const component = findComponentByName(components, action.closingModal);

        customBlueprint.value = component;
        customForm.value = builder;
        initModal();
    }
};

const findComponentByName: any = (components: any, targetName: string) =>
{
    if (!components || !Array.isArray(components))
    {
        return null;
    }

    for (const component of components)
    {
        if (component.name === targetName)
        {
            return component;
        }

        const found = findComponentByName(component.components, targetName);

        if (found)
        {
            return found;
        }
    }

    return null;
};

const closingEvent = (formData: any) =>
{
    $events.$emit('dynamic-modal-hide');
    $events.$emit('form-reload-data', formData);
};

const resetModal = () =>
{
    customBlueprint.value = null;
    customForm.value = null;
};

init();
</script>

<template>
    <form-component-wrapper class="dynamicModal-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <IdeoButton :icon="icon" @click="initModal" :variant="(blueprint.buttonStyle?.key ?? 'success').toLowerCase()">
                    {{ (blueprint.showLabel ? label : '') }}
                </IdeoButton>
            </div>
            <DynamicModal
                ref="dynamicModalRef" @action-event="actionEvent" :blueprint="blueprintDynamic" :entry="entry"
                :base-builder="modalForm" :modal-entry="entryData" @closingEvent="closingEvent"
                :form="form" @resetModal="resetModal"
            />
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-text v-model="blueprint.icon" :label="$t('[[[Ikona]]]')" />
            <field-checkbox v-model="blueprint.showIcon" :label="$t('[[[Pokaż ikonę]]]')" />
            <ideo-form-group :label="$t('[[[Styl przycisku]]]')" class="mt-2">
                <ButtonStyles v-model="blueprint.buttonStyle" :button-styles="buttonStyles" />
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Tryb]]]')" :invalid-feedback="form.schema.errorMessage(props.blueprint, 'modalMode')" required>
                <ideo-form-radio-group v-model="blueprint.modalMode" :text-field="'value'" :value-field="'key'" :options="modalModes"></ideo-form-radio-group>
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Skrypt]]]')" :invalid-feedback="form.schema.errorMessage(props.blueprint, 'script')" :required="blueprint.modalMode == 'Button'">
                <FieldScript
                    v-model="blueprint.script"
                    :show-label="false"
                    :label="$t('[[[Wybierz skrypt]]]')"
                    :endpoint="`admin/forms/scripts`"
                    :script-type="scriptType"
                    :module-id="moduleId"
                />
            </ideo-form-group>
            <template v-if="blueprint.modalMode == 'Modal'">
                <ideo-form-localize v-slot="{ locale }">
                    <field-text v-model="blueprint.formTitle[locale]" :label="$t('[[[Tytuł formularza]]]')" />
                </ideo-form-localize>

                <ideo-form-group :label="$t('[[[Formularz dynamiczny]]]')" :invalid-feedback="form.schema.errorMessage(props.blueprint, 'dynamicForm')" required>
                    <IdeoSelect
                        v-model="blueprint.dynamicForm"
                        :options="dynamicForms"
                    />
                </ideo-form-group>
                <DynamicAction
                    v-model="blueprint.dynamicActions"
                    :blueprint="blueprint"
                    :form="form"
                    :label="$t('[[[Akcje]]]')"
                    :button-styles="buttonStyles"
                />
            </template>

            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
        </template>
    </form-component-wrapper>
</template>
