<script lang="ts" setup>
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["update:filters"]);
import { ref, computed, watch } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Form } from '@/helpers/Form';
import FiltersDefinitionService, {
    Filter,
    FilterDefinitionModel,
} from '@/modules/core/common/services/FiltersDefinitionService';
import { isEmpty, entries } from 'lodash';
import FilterAutocomplete from '@/components/common/global-autocomplete/FilterAutocomplete.vue';
import FiltersDropdownInput from '@/components/common/new-filters/components/FiltersDropdownInput.vue';
import FiltersBasicInput from '@/components/common/new-filters/components/FilterBasicInput.vue';
import FilterDate from '@/components/common/new-filters/components/FiltersDate.vue';
import FiltersCheckbox from '@/components/common/new-filters/components/FiltersCheckbox.vue';
import FiltersThreeStateCheckbox from '@/components/common/new-filters/components/FiltersThreeStateCheckbox.vue';
import DateWrapper from '@/components/common/new-filters/components/DateWrapper.vue';
import FiltersAmount from '@/components/common/new-filters/components/FiltersAmount.vue';
import UserAutocomplete from '@/components/common/global-autocomplete/UserAutocomplete.vue';

defineOptions({
    components: {
        FilterAutocomplete,
        FiltersDropdownInput,
        FiltersBasicInput,
        FilterDate,
        FiltersCheckbox,
        FiltersThreeStateCheckbox,
        DateWrapper,
        FiltersAmount,
        UserAutocomplete,
    },
});

const model = __MACROS_useVModel("filters");

const props = defineProps({
  "filters": null,
  "endpoint": null,
  "visibility": { type: Boolean,  },
  "requestUrl": null,
  "actionName": null,
  "parentType": null,
  "parentId": null
});

const { $t } = useLocalization();

const loading = ref(true);
const search = ref('');
const filtersDefinition = ref<FilterDefinitionModel>(null);

const filtersArray = computed(() =>
{
    if (!filtersDefinition.value) return [];

    const f = Object.entries(filtersDefinition.value);

    return f
        .filter((filter) => filter[1].visibility)
        .sort((a, b) =>
        {
            if (b[1].order < a[1].order) return 1;

            return -1;
        });
});

const sortedFilters = computed(() =>
{
    if (filtersDefinition.value)
    {
        const sorted = entries(filtersDefinition.value)
            .filter(
                (filter) =>
                    filter[1].headerName?.toLowerCase()?.includes(search.value.toLowerCase()) &&
                    !filter[1].type.features.find((feature) => feature.key.toLowerCase() == 'displaynone')
            )
            .sort((a, b) =>
            {
                if (b[1].order < a[1].order) return 1;

                return -1;
            });

        return sorted;
    }

    return [];
});

const filterVisibilityChanged = async (value: boolean, item: Filter, filterName: string) =>
{
    item.visibility = value;

    if (!value)
    {
        if (item.type.baseType === 'DatePeriod')
        {
            model.filters.value[`${filterName}.FromUtc`] = null;
            model.filters.value[`${filterName}.ToUtc`] = null;
            model.filters.value[`${filterName}.DaysBeforeNow`] = null;
            model.filters.value[`${filterName}.DaysAfterNow`] = null;
        }
        else if (item.type.baseType === 'AmountPeriod')
        {
            model.filters.value[`${filterName}.FromUtc`] = null;
            model.filters.value[`${filterName}.ToUtc`] = null;
        }
        else
        {
            model.filters.value[filterName] = null;
        }
    }
};

const isValueEmpty = (value: any): boolean =>
{
    if (Array.isArray(value) || typeof value === 'object') return isEmpty(value);
    else return !value;
};

const controlType = (control: [string, Filter]): Record<string, any> =>
{
    const options = control[1];
    const attributes: Record<string, any> = { placeholder: control[1].headerName };
    const multiselect = options.type.features.find((feature) => feature.key.toLowerCase() === 'multiselect');
    const isDisplayNone = options.type.features.find((feauture) => feauture.key.toLowerCase() === 'displaynone');

    if (isDisplayNone)
    {
        attributes.is = null;

        return attributes;
    }

    if (multiselect)
    {
        attributes.multiselect = true;
    }

    if (options.type.baseType.includes('Api'))
    {
        attributes.is = 'FilterAutocomplete';
        attributes.fetchSettings = {
            module: props.endpoint,
            propType: control[0],
            actionName: 'filter',
        };
        attributes.customEndpoint = null;

        return attributes;
    }

    switch (options.type.baseType)
    {
        case 'String':
        case 'RichText':
        case 'IControlAggregate':
            attributes.is = 'FiltersDropdownInput';
            attributes.type = 'text';
            break;
        case 'Int64':
            attributes.is = 'FiltersDropdownInput';
            attributes.type = 'number';
            break;
        case 'Int32':
            attributes.is = 'FiltersDropdownInput';
            attributes.type = 'number';
            break;
        case 'StatusKeyValuePair':
        case 'GenericKeyValuePair':
        case 'DynamicDictionaryFront':
            attributes.is = 'FilterAutocomplete';
            attributes.fetchSettings = {
                module: props.requestUrl,
                propType: control[0],
                actionName: props.actionName,
            };
            attributes.customEndpoint = props.parentType
                ? `simple-dictionary/${props.parentType}/filter/${props.parentId}/${props.requestUrl}/${control[0]}`
                : null;

            if (options.headerName?.toLowerCase()?.includes('dział'))
            {
                attributes.additionalOptions = [
                    {
                        field: 'unitsBelow',
                        type: 'ideo-form-checkbox',
                        label: $t('[[[Wyszukaj w działach podrzędnych]]]'),
                    },
                ];
                attributes.globalFilter = model.filters.value;
            }

            break;
        case 'UserDisplayModel':
            attributes.is = 'UserAutocomplete';
            attributes.fetchSettings = {
                module: props.requestUrl,
                propType: control[0],
                actionName: props.actionName,
            };
            attributes.customEndpoint = props.parentType
                ? `simple-dictionary/${props.parentType}/filter/${props.parentId}/${props.requestUrl}/${control[0]}`
                : null;
            break;
        case 'SimplePartner':
            attributes.is = 'FilterAutocomplete';
            attributes.fetchSettings = {
                module: props.requestUrl,
                propType: control[0],
                actionName: props.actionName,
            };
            attributes.customEndpoint = props.parentType
                ? `simple-dictionary/${props.parentType}/filter/${props.parentId}/${props.requestUrl}/${control[0]}`
                : null;
            break;
        case 'Boolean':
            attributes.is = 'FiltersThreeStateCheckbox';
            break;
        case 'DateTime':
            attributes.is = 'FilterDate';
            break;
    }

    return attributes;
};

const setFilterValue = (value: any, name: string): void =>
{
    model.filters.value[name] = value;
};

const buildFilters = () =>
{
    model.filters.value ??= {};

    if (typeof(model.filters.value) == 'string')
    {
        model.filters.value = JSON.parse(model.filters.value);
    }

    for (const [key, value] of Object.entries(filtersDefinition.value))
    {
        filtersDefinition.value[key].visibility = props.visibility ?? true;

        const multiselect = value.type.features.find((feature) => feature.key.toLowerCase() === 'multiselect');
        const isPeriod = value.type.baseType === 'DatePeriod';
        const isAmount = value.type.baseType === 'AmountPeriod';

        if (isPeriod)
        {
            model.filters.value[`${key}.FromUtc`] ??= null;
            model.filters.value[`${key}.ToUtc`] ??= null;
            model.filters.value[`${key}.DaysBeforeNow`] ??= null;
            model.filters.value[`${key}.DaysAfterNow`] ??= null;

            if (
                model.filters.value[`${key}.FromUtc`] ||
                model.filters.value[`${key}.ToUtc`] ||
                model.filters.value[`${key}.DaysBeforeNow`] ||
                model.filters.value[`${key}.DaysAfterNow`]
            )
            {
                filtersDefinition.value[key].visibility = true;
            }
        }
        else if (isAmount)
        {
            model.filters.value[`${key}.FromUtc`] ??= null;
            model.filters.value[`${key}.ToUtc`] ??= null;

            if (model.filters.value[`${key}.FromUtc`] || model.filters.value[`${key}.ToUtc`])
            {
                filtersDefinition.value[key].visibility = true;
            }
        }
        else
        {
            model.filters.value[key] ??= multiselect ? [] : null;

            if (model.filters.value[key])
            {
                filtersDefinition.value[key].visibility = true;
            }
        }
    }
};

const fetchFiltersDefinition = async () =>
{
    const response = await FiltersDefinitionService.fetchFiltersDefinitionNew(props.endpoint);

    if (!response) return;

    filtersDefinition.value = response;
};

watch(
    () => props.endpoint,
    async () =>
    {
        model.filters.value = Form.create({});
        init();
    }
);

const init = async () =>
{
    loading.value = true;
    await fetchFiltersDefinition();
    buildFilters();
    loading.value = false;
};

init();
</script>

<template>
    <div v-if="!loading" class="d-flex flex-wrap gap-1">
        <template v-for="(control, index) in filtersArray">
            <div
                v-if="control[1].type.baseType === 'DatePeriod'"
                class="single-new-filter"
                :key="`${index}-${control[0]}`"
            >
                <date-wrapper
                    :label="control[1].headerName"
                    :filter-date-from="model.filters.value[`${control[0]}.FromUtc`]"
                    :filter-date-to="model.filters.value[`${control[0]}.ToUtc`]"
                    :filter-days-before-now="model.filters.value[`${control[0]}.DaysBeforeNow`]"
                    :filter-days-after-now="model.filters.value[`${control[0]}.DaysAfterNow`]"
                    :data-id="control[0]"
                    :data-empty="isValueEmpty(model.filters.value[control[0]])"
                    @update:dateFromUtc="setFilterValue($event, `${control[0]}.FromUtc`)"
                    @update:dateToUtc="setFilterValue($event, `${control[0]}.ToUtc`)"
                    @update:daysBeforeNow="setFilterValue($event, `${control[0]}.DaysBeforeNow`)"
                    @update:daysAfterNow="setFilterValue($event, `${control[0]}.DaysAfterNow`)"
                />
            </div>
            <div
                v-if="control[1].type.baseType === 'AmountPeriod'"
                class="single-new-filter"
                :key="`${index}-${control[0]}`"
            >
                <filters-amount
                    :label="control[1].headerName"
                    :from="model.filters.value[`${control[0]}.FromUtc`]"
                    :to="model.filters.value[`${control[0]}.ToUtc`]"
                    @update:from="setFilterValue($event, `${control[0]}.FromUtc`)"
                    @update:to="setFilterValue($event, `${control[0]}.ToUtc`)"
                />
            </div>
            <div v-else-if="controlType(control).is" :key="index">
                <component
                    v-bind="{ ...controlType(control) }"
                    :is="controlType(control).is"
                    :model-value="model.filters.value[control[0]]"
                    :label="control[1].headerName"
                    :data-id="control[0]"
                    :data-empty="isValueEmpty(model.filters.value[control[0]])"
                    @update:modelValue="setFilterValue($event, control[0])"
                />
            </div>
        </template>

        <span v-if="!filtersArray.length">{{ $t('[[[Nie wybrano żadnych filtrów]]]') }}</span>

        <portal to="logito-filters-settings">
            <div class="template-search p-2">
                <ideo-form-group :label="$t('[[[Wyszukaj]]]')" nospace>
                    <ideo-form-input v-model="search" type="text" autocomplete="false"></ideo-form-input>
                </ideo-form-group>
            </div>
            <div class="divider">
                <hr role="separator" aria-orientation="horizontal" class="dropdown-divider" />
            </div>
            <div class="filters-customizer scroll scroll--overflow-x-hidden">
                <div class="drop-zone">
                    <div
                        v-for="(filter, index) in sortedFilters"
                        :key="index"
                        :data-order="filter[1].order"
                        class="dropdown-item py-2"
                    >
                        <div class="d-flex align-items-center">
                            <ideo-form-checkbox
                                :modelValue="filter[1].visibility"
                                @change="filterVisibilityChanged($event, filter[1], filter[0])"
                            >
                                {{ filter[1].headerName }}
                            </ideo-form-checkbox>
                        </div>
                    </div>
                </div>
            </div>
        </portal>
    </div>
</template>

<style scoped>
.template-search {
    min-width: 300px;
    padding: 10px 20px 5px;
}
</style>
