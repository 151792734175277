import { axios } from '@/plugins/axios';
import { Statement, Resource, Pagination, KeyValuePair } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';
import { JsonHelperModel } from '@/modules/core/dictionary-terms/inc/Interfaces';
import Pager from '@/helpers/Pager';
import { FormEntry } from '@/components/forms/blueprints/form';
import { merge } from 'lodash';

export default class DictionaryTermsService
{
    public static async getJson(type: string): Promise<JsonHelperModel[]>
    {
        return (await axios.get<JsonHelperModel[]>(`admin/dictionaryTerms/${type}/json`)).data;
    }

    public static async fetch(id: string): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`simple-fetch/dictionaryTerms/${id}`)).data;
    }

    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`simple-command/dictionaryTerms`, model)).data;
    }

    public static async update(id: string, model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`simple-command/dictionaryTerms/${id}`, model)).data;
    }

    public static async remove(id: string): Promise<Statement>
    {
        return (await axios.delete(`simple-command/dictionaryTerms/${id}`)).data as Statement;
    }

    public static async options(pager: any): Promise<Pagination<Resource<KeyValuePair>>>
    {
        return (await axios.get(`simple-dictionary/dictionaryTerms/form/dictionaryType`, {
            params: pager,
        })).data as any;
    }

    public static async optionsForDynamicForm(licence: string, endpointId: string, fieldName: string): Promise<Pagination<Resource<KeyValuePair>>>
    {
        return (await axios.get(`dictionary-terms/${licence}/${endpointId}/${fieldName}/types`)).data;
    }

    public static async getJsonForDynamicForm(licence: string, endpointId: string, fieldName: string): Promise<JsonHelperModel[]>
    {
        return (await axios.get(`dictionary-terms/${licence}/${endpointId}/${fieldName}/json`)).data;
    }

    public static async createForDynamicForm(licence: string, endpointId: string, fieldName: string, model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post(`dictionary-terms/${licence}/${endpointId}/${fieldName}/create`, model)).data;
    }

    public static async fetchOptions(endpoint: string, pager: Pager, search: string, entry: FormEntry): Promise<Pagination<Resource<Record<string, string>>>>
    {
        return (await axios.post(endpoint, entry, {
            params: merge({ search }, pager.data())
        })).data;
    }
}

export interface ListItemModel
{
    id: number;
    dateDisabledUtc: DateTime;
    dateCreatedUtc: DateTime;
    name: string;
    dictionaryType: string;
    position: number;
    value: string;
    meta: any;
}

export interface FilterModel
{
    search?: string;
}

export interface FormModel
{
    id?: number;
    activeToUtc: DateTime;
    name: Record<string, string>;
    dictionaryType: any;
    description: string;
    position: number;
    value: string;
    publicId?: string;
}

export interface AvailableActions{
    canEdit: boolean,
    canDelete: boolean
}

export interface DynamicFormData
{
    licence: string;
    endpointId: string;
    fieldName: string;
}
