<script lang="ts">
import { Prop } from "@/helpers/Decorators";
import { Options, Vue } from "vue-class-component";
import { AttributesModel, Column } from "@/modules/core/common/services/GridService";
import { getAttributes } from "@/components/common/dynamic-grid/helpers/TypeOfData";

// Presenters
import DateTimePresenter from '@/components/dynamic-details/blueprints/field/presenters/DateTime.vue';
import UserWithAvatarPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserWithAvatar.vue';
import UserWithAvatarArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserWithAvatarArray.vue';
import TextPresenter from '@/components/dynamic-details/blueprints/field/presenters/Text.vue';
import StatusPresenter from '@/components/dynamic-details/blueprints/field/presenters/StatusWithVariant.vue';
import MoneyPresenter from '@/components/dynamic-details/blueprints/field/presenters/Money.vue';
import BooleanPresenter from '@/components/dynamic-details/blueprints/field/presenters/Boolean.vue';
import GenericKeyValuePairPresenter from '@/components/dynamic-details/blueprints/field/presenters/GenericKeyValuePair.vue';
import GenericKeyValuePairArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/GenericKeyValuePairArray.vue';
import DecimalPresenter from '@/components/dynamic-details/blueprints/field/presenters/Decimal.vue';
import AddressPresenter from '@/components/dynamic-details/blueprints/field/presenters/Address.vue';
import MainFilePresenter from '@/components/dynamic-details/blueprints/field/presenters/File.vue';
import ListPresenter from '@/components/dynamic-details/blueprints/field/presenters/List.vue';
import SimplePartnerPresenter from '@/components/dynamic-details/blueprints/field/presenters/SimplePartner.vue';
import SimplePartnerArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/SimplePartnerArray.vue';
import DurationPresenter from '@/components/dynamic-details/blueprints/field/presenters/Duration.vue';
import ResourcePresenter from '@/components/dynamic-details/blueprints/field/presenters/Resource.vue';
import TablePresenter from '@/components/dynamic-details/blueprints/field/presenters/Table.vue';
import TableVatPresenter from '@/components/dynamic-details/blueprints/field/presenters/TableVat.vue';
import UserGroupArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserGroupArray.vue';
import ExchangeRatePresenter from '@/components/dynamic-details/blueprints/field/presenters/ExchangeRate.vue';
import DynamicDocumentPresenter from '@/components/dynamic-details/blueprints/field/presenters/DynamicDocument.vue';
import IsPrivatePresenter from '@/components/dynamic-details/blueprints/field/presenters/IsPrivate.vue';
import RichTextPresenter from '@/components/dynamic-details/blueprints/field/presenters/RichText.vue';

@Options({
    name: "TableChangeLogFormatter",
    components: {
        FileReader,
        DateTimePresenter,
        UserWithAvatarPresenter,
        UserWithAvatarArrayPresenter,
        TextPresenter,
        StatusPresenter,
        MoneyPresenter,
        BooleanPresenter,
        GenericKeyValuePairPresenter,
        DecimalPresenter,
        AddressPresenter,
        MainFilePresenter,
        ListPresenter,
        SimplePartnerPresenter,
        SimplePartnerArrayPresenter,
        DurationPresenter,
        ResourcePresenter,
        TablePresenter,
        TableVatPresenter,
        GenericKeyValuePairArrayPresenter,
        UserGroupArrayPresenter,
        ExchangeRatePresenter,
        DynamicDocumentPresenter,
        IsPrivatePresenter,
        RichTextPresenter,
    },
})
export default class TableChangeLogFormatter extends Vue
{
    @Prop()
    public original: Record<string, string>[];

    @Prop()
    public current: Record<string, string>[];

    @Prop({ default: null })
    public definition: Column;

    @Prop({ default: () => ({})})
    public tableSchema: { body: { [key: string]: Column } };

    public isTable = false;

    public get isFooter(): boolean
    {
        return this.definition.columns.some((item) => item.summary);
    }

    public translatedText(text: Record<string, string>): string
    {
        return this.$i18n.currentTranslation(text);
    }

    public TypeOfData(value: any, header: Column): Record<string, any>
    {
        const headerOptions = header;
        const property = value;
        const features = headerOptions.type.features;
        const attributes: AttributesModel = { property, features, ...getAttributes(headerOptions.type.baseType, { isInTable: true }) };

        return attributes;
    }

    public getSumOfColumn(property: Record<string, any>[], colName: string): number
    {
        const sum = property.reduce((acc, item) =>
        {
            const value = item[colName];

            if (colName.toLowerCase().includes("numeric")) return acc + (value || 0);

            return acc;
        }, 0);

        return sum;
    }
}
</script>

<template>
    <div class="scroll scroll--overflow-y-hidden" :class="{ 'pb-2': isTable }">
        <IdeoButton variant="light" @click="isTable = !isTable" :title="!isTable ? $t('[[[Pokaż]]]') : $t('[[[Ukryj]]]')">
            <i v-show="!isTable" class="fa-solid fa-eye"></i>
            <i v-show="isTable" class="fa-solid fa-eye-slash"></i>
        </IdeoButton>
        <div v-show="isTable" class="mt-2">
            <table v-if="original" class="table table-bordered table--danger">
                <thead v-if="definition.columns">
                    <tr>
                        <th v-for="(col, index) in definition.columns" :key="index">{{ translatedText(col.columnName) }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, indexRow) in original" :key="indexRow">
                        <td v-for="(col, indexCol) in definition.columns" :key="indexCol" class="p-2">
                            <component :is="TypeOfData(row[col.fieldName], tableSchema.body[col.fieldName]).is" v-bind="{ ...TypeOfData(row[col.fieldName], tableSchema.body[col.fieldName]) }" />
                        </td>
                    </tr>
                </tbody>
                <tfoot v-if="isFooter">
                    <tr class="border-0">
                        <td v-for="(col, index) in definition.columns" :key="index" :class="!col.summary ? 'border-0' : 'table--border'">
                            <span v-if="col.summary" class="fw-bold">
                                <component :is="TypeOfData(getSumOfColumn(original, col.fieldName), tableSchema.body[col.fieldName]).is" v-bind="{ ...TypeOfData(getSumOfColumn(original, col.fieldName), tableSchema.body[col.fieldName]) }" />
                            </span>
                        </td>
                    </tr>
                </tfoot>
            </table>

            <table v-if="current" class="table table-bordered table--success mb-0">
                <thead v-if="definition.columns">
                    <tr>
                        <th v-for="(col, index) in definition.columns" :key="index">{{ translatedText(col.columnName) }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, indexRow) in current" :key="indexRow">
                        <td v-for="(col, indexCol) in definition.columns" :key="indexCol" class="p-2">
                            <component :is="TypeOfData(row[col.fieldName], tableSchema.body[col.fieldName]).is" v-bind="{ ...TypeOfData(row[col.fieldName], tableSchema.body[col.fieldName]) }" />
                        </td>
                    </tr>
                </tbody>
                <tfoot v-if="isFooter">
                    <tr class="border-0">
                        <td v-for="(col, index) in definition.columns" :key="index" :class="!col.summary ? 'border-0' : 'table--border'">
                            <span v-if="col.summary" class="fw-bold">
                                <component :is="TypeOfData(getSumOfColumn(current, col.fieldName), tableSchema.body[col.fieldName]).is" v-bind="{ ...TypeOfData(getSumOfColumn(current, col.fieldName), tableSchema.body[col.fieldName]) }" />
                            </span>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.table {
    table-layout: fixed;
    max-width: 900px;

    thead {
        tr {
            height: 36.5px;
            background: var(--border-color-nav);
        }

        th {
            border: 1px solid var(--bs-table-border-color);
            width: 100px;
        }
    }

    &--border {
        background: var(--border-color-nav);
        border: 1px solid var(--bs-table-border-color);
    }

    tbody {
        td {
            border-width: 1px;
            border: 1px solid var(--bs-table-border-color);
        }
    }

    &--danger {
        thead {
            th {
                border-color: #e51c23;
            }
        }

        tbody {
            td {
                border-color: #e51c23;
            }
        }

        tfoot {
            td {
                border-color: #e51c23 !important;
            }
        }
    }

    &--success {
        thead {
            th {
                border-color: #2EB85C;
            }
        }

        tbody {
            td {
                border-color: #2EB85C;
            }
        }

        tfoot {
            td {
                border-color: #2EB85C !important;
            }
        }
    }
}
</style>
