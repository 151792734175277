<template>
    <IdeoModal centered :title="title" ref="widget-settings-modal" @hidden="clearForm">
        <template #default>
            <form id="version-modal-form" @keydown="form.$errors.clear(($event.target as HTMLInputElement).name)" @submit.prevent="onSubmit">
                <!-- Nazwa -->
                <IdeoFormLocalize v-slot="{ locale }">
                    <IdeoFormGroup
                        :label="$t('[[[Nazwa]]]')"
                        :invalid-feedback="form.$errors.first('name')"
                        :state="form.$errors.state('name')"
                        required
                    >
                        <IdeoFormInput v-model="form.name[locale]" :placeholder="$t('[[[Nazwa]]]')" type="text" name="name" disabled />
                    </IdeoFormGroup>
                </IdeoFormLocalize>

                <!-- Opis -->
                <IdeoFormLocalize v-slot="{ locale }">
                    <IdeoFormGroup
                        :label="$t('[[[Opis]]]')"
                        :invalid-feedback="form.$errors.first('description')"
                        :state="form.$errors.state('description')"
                    >
                        <IdeoFormTextarea v-model="form.description[locale]" :placeholder="$t('[[[Opis]]]')" name="description" rows="4" disabled />
                    </IdeoFormGroup>
                </IdeoFormLocalize>

                <!-- Liczba wyników na stronie -->
                <IdeoFormGroup
                    v-if="isPageSize"
                    :label="$t('[[[Liczba wyników na stronie]]]')"
                    :invalid-feedback="form.$errors.first('pageSize')"
                    :state="form.$errors.state('pageSize')"
                    required
                >
                    <IdeoFormInput v-model="(form.sourceConfig as Record<string, any>).pageSize" type="number" name="pageSize" />
                </IdeoFormGroup>

                <div class="row">
                    <div class="col-sm-6">
                        <!-- Szerokość -->
                        <IdeoFormGroup
                            :label="$t('[[[Szerokość]]]')"
                            :invalid-feedback="form.$errors.first('width')"
                            :state="form.$errors.state('width')"
                            required
                        >
                            <IdeoSelect
                                v-model="form.width"
                                :placeholder="$t('[[[Szerokość]]]')"
                                :endpoint="`simple-dictionary/widgets-user-config/form/${publicId}/width`"
                                @update:modelValue="form.$errors.clear('width')"
                            />
                        </IdeoFormGroup>
                    </div>
                    <div class="col-sm-6">
                        <!-- Wysokość -->
                        <IdeoFormGroup
                            :label="$t('[[[Wysokość]]]')"
                            :invalid-feedback="form.$errors.first('height')"
                            :state="form.$errors.state('height')"
                            required
                        >
                            <IdeoSelect
                                v-model="form.height"
                                :placeholder="$t('[[[Wysokość]]]')"
                                :endpoint="`simple-dictionary/widgets-user-config/form/${publicId}/height`"
                                :narrow-options="{ maxHeight: screenHeight }"
                                @update:modelValue="form.$errors.clear('height')"
                            />
                        </IdeoFormGroup>
                    </div>
                </div>
            </form>
        </template>
        <template #modal-footer="{ cancel }">
            <IdeoButton type="submit" variant="success" form="version-modal-form" :disabled="!form.active()">{{ $t('[[[Zapisz]]]') }}</IdeoButton>
            <IdeoButton type="button" variant="secondary" @click="cancel"> {{ $t('[[[Anuluj]]]') }}</IdeoButton>
        </template>
    </IdeoModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { Ref } from "@/helpers/Decorators";
import { Form } from "@/helpers/Form";
import HomeService, { APIWidget, Widget } from "@/modules/core/home/services/HomeService";
import { cloneDeep } from "lodash";
import { Option } from '@/helpers/Interfaces';
import { WIDTH_OPTIONS, HEIGHT_OPTIONS } from "@/modules/core/home/utils";
import IdeoModal from "@/components/ideo/modal/IdeoModal.vue";

@Options({
    name: "WidgetSettingsModal",
    emits: ["update-widget"],
})
export default class WidgetSettingsModal extends Vue
{
    @Ref("widget-settings-modal")
    public modal: () => IdeoModal;

    public form = Form.create<APIWidget>({
        actionButtons: [],
        additionalData: null,
        dataEndpoint: null,
        description: {},
        height: null,
        licence: null,
        licenseUrl: null,
        actionUrl: null,
        moduleId: null,
        name: {},
        publicId: null,
        sourceConfig: {
            pageSize: 5,
            listType: null,
            columns: null,
            filter: {},
        },
        sourceType: null,
        staticWidgetType: null,
        sitemapEntryDetails: null,
        width: null,
        headerIcon: null,
        showFilters: false,
        showAddButton: false,
        showGroupedActionsButton: false,
        showPager: false,
        sitemapId: null
    });

    public publicId: string = "";
    public name: string = "";
    public screenHeight: any = "";

    public get isPageSize(): boolean
    {
        return this.form.staticWidgetType?.key === "ActiveSubstitutions"
                && this.form.sourceConfig
                && typeof this.form.sourceConfig === "object"
                && 'pageSize' in this.form.sourceConfig;
    }

    public get title(): string
    {
        return `${this.$i18n.currentTranslation(this.form.name)} - ${this.$t("[[[ustawienia widgetu]]]")}`;
    }

    public mounted(): void
    {
        this.$events.$on("widget-settings-modal", this.handleOpen);
    }

    public unmounted(): void
    {
        this.$events.$off("widget-settings-modal", this.handleOpen);
    }

    public async handleOpen(widget: Widget, dashboardId?: string): Promise<void>
    {
        this.screenHeight = window.innerHeight;

        const copyWidget = cloneDeep(widget);

        this.publicId = copyWidget.publicId;
        this.form.withData(copyWidget.config);
        this.form.width = { key: `${copyWidget.w}`, value: `${copyWidget.w}`.padStart(2, "0") };
        this.form.height = { key: `${copyWidget.h}`, value: `${copyWidget.h}` };
        this.form.sitemapId = dashboardId;

        this.modal().show();
    }

    public handleClose(): void
    {
        this.modal().hide();
        this.clearForm();
    }

    public clearForm(): void
    {
        this.form.clear();
    }

    public async onSubmit(): Promise<void>
    {
        try
        {
            const form = cloneDeep(this.form.formatData());

            if (form.sourceConfig && typeof form.sourceConfig !==  "string")
            {
                if (form.sourceConfig && 'listType' in form.sourceConfig)
                {
                    form.sourceConfig.listType = form.sourceConfig?.listType?.key as any;
                }

                if (form.sourceConfig && 'numberColor' in form.sourceConfig)
                {
                    form.sourceConfig.numberColor = form.sourceConfig?.numberColor?.key as any;
                }

                if (form.sourceConfig && 'iconColor' in form.sourceConfig)
                {
                    form.sourceConfig.iconColor = form.sourceConfig?.iconColor?.key as any;
                }

                // We have to send sourceConfig as string
                form.sourceConfig = JSON.stringify(form.sourceConfig);
            }

            const urlParams = { maxHeight: this.screenHeight };

            await HomeService.updateWidgetUserConfig(this.publicId, form, urlParams);

            this.$alert.success(this.$t("[[[Zmieniono ustawienia widgetu]]]"));
            this.$emit("update-widget", this.form.formatData(), this.publicId);
            this.handleClose();
        }
        catch (error)
        {
            this.handleException(error, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => this.form.$errors.record(ex.data.errors),
            });
        }
    }
}
</script>
