<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Form } from '@/helpers/Form';
import ScriptsService, { FormModel, ScriptRoleEnum, ScriptTypeEnum } from '@/modules/low-code/services/ScriptsService';
import { Prop, Watch } from '@/helpers/Decorators';
import ActionButton from '@/components/action/ActionButton.vue';
import SourceScripts from '@/modules/low-code/views/administration/scripts/components/tabs/SourceScripts.vue';
import Documentation from '@/modules/low-code/views/administration/scripts/components/common/Documentation.vue';
import { KeyValue } from "@/helpers/Interfaces";
import { uniqueId } from 'lodash';

@Options({
    components: {
        ActionButton,
        SourceScripts,
        Documentation
    },
    emits: ['scriptSaved', 'scriptClosed']
})
export default class ModuleForm extends Vue
{
    @Prop({ default: '' })
    public publicId: string;

    @Prop({ default: false })
    public isModalMode: boolean;

    @Prop({ default: false })
    public documentType: any;

    @Prop({ default: null })
    public moduleId: string;

    @Prop({ default: false })
    public scriptType: ScriptTypeEnum;

    private areDatasFromTemplateFetched = "";
    private isLoaded = false;

    @Watch('form.template')
    public async onTemplateChanged(newValue: { key: string, value: string }): Promise<void>
    {
        const isLoaded = this.isLoaded;

        if (newValue == null || !newValue.key) return;

        try
        {
            const result = (await ScriptsService.getDetails(newValue.key)).result;

            if (result)
            {
                if (isLoaded)
                {
                    this.form.overrideBaseScript = false;
                    this.form.overrideAdditionalScript = false;
                    this.form.overrideScriptConfig = false;
                    this.form.overrideSharedScript = false;
                }

                if (!this.form.overrideBaseScript)
                    this.form.baseScript = result.baseScript;

                if (!this.form.overrideAdditionalScript)
                    this.form.additionalScript = result.additionalScript;

                if (!this.form.overrideScriptConfig)
                    this.form.scriptConfig = result.scriptConfig;

                if (!this.form.overrideSharedScript)
                    this.form.sharedScript = result.sharedScript;

                this.areDatasFromTemplateFetched = uniqueId();
            }
        }
        catch (ex)
        {
            this.$log.debug(ex);
        }
    }

    public form = Form.create<FormModel>({
        name: {},
        description: {},
        module: null,
        scriptRole: null,
        scriptType: null,
        isArchive: false,
        scriptEngine: null,
        scriptConfig: null,
        sharedScript: null,
        baseScript: null,
        additionalScript: null,
        linkedScripts: [],
        template: null,
        overrideAdditionalScript: false,
        overrideBaseScript: false,
        overrideScriptConfig: false,
        overrideSharedScript: false,
        isSystemScript: false
    });

    public tabIndex: number = 0;
    public scriptRoles: KeyValue<string, string>[] = [];
    public scriptTypes: KeyValue<string, string>[] = [];
    public scriptEngines: KeyValue<string, string>[] = [];
    public modulesReloadKey: number = 0;

    public get isEditMode(): boolean
    {
        return this.publicId && this.publicId !== '0';
    }

    public get isSharedScript(): boolean
    {
        return this.form.scriptRole?.key == ScriptRoleEnum.SharedScript;
    }

    public get isTemplate(): boolean
    {
        return this.form.scriptRole?.key == ScriptRoleEnum.Template;
    }

    public get hasTemplate(): boolean
    {
        return this.form.template != null;
    }

    public get isDebugMode(): boolean
    {
        return this.isEditMode && process.env.NODE_ENV !== "production";
    }

    public get scriptEnginesByType(): KeyValue<string, string>[]
    {
        if (!this.form.scriptType)
        {
            if (this.isSharedScript || this.isTemplate)
                return this.scriptEngines;
            else
                return [];
        }

        const clearScriptTypes = [
            ScriptTypeEnum.DynamicTableField.toString(),
            ScriptTypeEnum.DynamicTableDefaultRowValue.toString()
        ];

        const cSharpTypes = [
            ScriptTypeEnum.PrintTemplate.toString(),
            ScriptTypeEnum.DynamicModal.toString()
        ];

        if (clearScriptTypes.includes(this.form.scriptType.key))
            return this.scriptEngines.filter(p => p.key == 'ClearScript');

        if (cSharpTypes.includes(this.form.scriptType.key))
            return this.scriptEngines.filter(p => p.key == 'CSharpEngine');

        return this.scriptEngines;
    }

    public async created(): Promise<void>
    {
        await this.form.ready([
            await this.getScriptRoles(),
            await this.getScriptTypes(),
            await this.getScriptEngines(),
            await this.getDetails()
        ]);

        if (this.isModalMode)
            this.form.scriptRole = this.scriptRoles.find(p => p.key == ScriptRoleEnum.ExecutableScript);

        if (this.scriptType)
            this.form.scriptType = this.scriptTypes.find(p => p.key == this.scriptType);

        this.$events.$on('saveForm', (value: boolean) => this.saveForm(value));
        this.$events.$on('debugOn', (value: boolean) => this.debugOn());
        this.$events.$on('debugOff', (value: boolean) => this.debugOff());

        this.$nextTick(() =>
        {
            if (this.moduleId)
                this.modulesReloadKey++;

            this.isLoaded = true;
        });
    }

    public async getScriptRoles(): Promise<void>
    {
        try
        {
            const response = await ScriptsService.getDictionaryData('scriptRole');

            this.scriptRoles = response.items.map(p => ({key: p.result.key, value: p.result.value}));
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }

    public async getScriptTypes(): Promise<void>
    {
        try
        {
            const response = await ScriptsService.getDictionaryData('scriptType');

            this.scriptTypes = response.items.map(p => ({key: p.result.key, value: p.result.value}));
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }

    public async getScriptEngines(): Promise<void>
    {
        try
        {
            const response = await ScriptsService.getDictionaryData('scriptEngine');

            this.scriptEngines = response.items.map(p => ({key: p.result.key, value: p.result.value}));
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
            });
        }
    }

    public async getDetails(): Promise<any>
    {
        if (!this.isEditMode) return;

        try
        {
            const result = (await ScriptsService.getDetails(this.publicId)).result;

            this.form.withData(result);
        }
        catch (ex)
        {
            this.$log.debug(ex);
        }
    }

    public async debugOn()
    {
        try
        {
            this.form.wait();

            await ScriptsService.debugOn(this.publicId);
        }
        catch (ex)
        {
            this.$log.debug(ex);
        }
        finally
        {
            this.form.continue();
        }
    }

    public async debugOff()
    {
        try
        {
            this.form.wait();

            const result = await ScriptsService.debugOff(this.publicId);

            if (this.form.overrideBaseScript || !this.hasTemplate)
                this.form.baseScript = result.baseScript;

            if (this.form.overrideAdditionalScript  || !this.hasTemplate)
                this.form.additionalScript = result.additionalScript;

            if (this.form.overrideScriptConfig || !this.hasTemplate)
                this.form.scriptConfig = result.scriptConfig;

            if (this.form.overrideSharedScript || !this.hasTemplate)
                this.form.sharedScript = result.sharedScript;
        }
        catch (ex)
        {
            this.$log.debug(ex);
        }
        finally
        {
            this.form.continue();
        }
    }

    public async saveForm(newVersionMode: boolean): Promise<void>
    {
        try
        {
            this.form.wait();

            let scriptPublicId = this.publicId;

            const form = this.form.formatData();

            this.scriptsToBase64(form);

            if (!this.isEditMode || newVersionMode)
            {
                const result = await ScriptsService.create(form, scriptPublicId);

                scriptPublicId = result.result.publicId;

                this.$alert.success(this.$t('[[[Skrypt został dodany.]]]'));
            }
            else
            {
                await ScriptsService.update(this.publicId, form);
                this.$alert.success(this.$t('[[[Skrypt został zaktualizowany.]]]'));
            }

            if (!this.isModalMode)
                this.redirect({ name: 'lowCode-scripts' });

            if (this.isModalMode)
                this.$emit('scriptSaved', form, scriptPublicId);
        }
        catch (ex)
        {
            const errors = (ex as any).data.errors;
            const tabIndex = (Object.keys(errors).length === 1 && errors.hasOwnProperty('scriptEngine')) || errors.hasOwnProperty('baseScript') ? 1 : 0;

            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message),
                422: (ex: any) => [
                    this.form.$errors.record(ex.data.errors),
                    this.tabIndex = tabIndex,
                    this.$nextTick(() => this.$scrollTo('.invalid-field')),
                    this.$nextTick(() =>
                        this.$alert.warning(this.$t('[[[Nie wszystkie pola zostały wypełnione prawidłowo.]]]'))
                    ),
                ],
            });
        }
        finally
        {
            this.form.continue();
        }
    }

    private scriptsToBase64(form: FormModel): void
    {
        const scriptProperties: Array<keyof FormModel> = ['scriptConfig', 'sharedScript', 'baseScript', 'additionalScript'];

        scriptProperties.forEach(property => this.encodeScriptProperty(form, property));
    }

    private encodeScriptProperty(form: FormModel, propertyName: keyof FormModel): void
    {
        if (this.form[propertyName] != null && this.form[propertyName] != '')
        {
            const utf8Encoded = new TextEncoder().encode(this.form[propertyName] as string);

            (form[propertyName] as string) = btoa(String.fromCharCode(...utf8Encoded));
        }
    }

    public unmounted(): void
    {
        this.$events.$off("saveForm");
        this.$events.$off("debugOn");
        this.$events.$off("debugOff");

        if (this.isModalMode)
            this.$emit('scriptClosed');
    }

    public updateFormField({ key, value }: { key: string, value: boolean })
    {
        if (key in this.form)
        {
            this.form[key] = value;
        }
        else
        {
            console.warn(`Key "${key}" does not exist in form.`);
        }
    }
}
</script>

<template>
    <DataCard :use-panels="true" :overflow="true">
        <template v-if="!isModalMode" #toolbar>
            <ActionBar :toggle-footer="true">
                <BackButton toolbar :fallback="{ name:'lowCode-scripts' }" />
                <template v-if="isDebugMode">
                    <ActionButton
                        variant="danger"
                        form="register-data-sources"
                        icon="fas fa-check-circle"
                        :text="$t('[[[DEBUG ON]]]')"
                        :disabled="!form.active()"
                        @click="debugOn()"
                    />
                    <ActionButton
                        variant="danger"
                        form="register-data-sources"
                        icon="fas fa-check-circle"
                        :text="$t('[[[DEBUG OFF]]]')"
                        :disabled="!form.active()"
                        @click="debugOff()"
                    />
                </template>
                <ActionButton
                    variant="success"
                    form="register-data-sources"
                    icon="fas fa-check-circle"
                    :text="$t('[[[Zapisz]]]')"
                    :disabled="!form.active() || form.isSystemScript"
                    @click="saveForm(false)"
                />
                <ActionButton
                    v-if="isEditMode"
                    variant="primary"
                    form="register-data-sources"
                    icon="fas fa-check-circle"
                    :text="$t('[[[Zapisz nową wersję]]]')"
                    :disabled="!form.active() || form.isSystemScript"
                    @click="saveForm(true)"
                />
            </ActionBar>
        </template>
        <template #default>
            <div v-if="form.isSystemScript" class="alert alert-warning">
                {{ $t('[[[Skrypt systemowy można modyfikować tylko poprzez migracje]]]') }}
            </div>
            <IdeoTabs v-model="tabIndex" content-class="mt-3 script-container" justified>
                <IdeoTab active>
                    <template #title>
                        <i class="fas fa-fw fa-gears"></i>
                        <span>{{ $t('[[[Ustawienia]]]') }}</span>
                    </template>
                    <template #default>
                        <div class="row">
                            <div class="col-md-6">
                                <form
                                    id="register-data-sources"
                                    @submit.prevent="saveForm(false)"
                                    @keydown="form.$errors.clear(($event.target as HTMLInputElement).name)"
                                >
                                    <fieldset :disabled="form.isSystemScript">
                                        <PlaceholderForm :loading="form.loading()">
                                            <IdeoFormLocalize v-slot="{ locale }">
                                                <IdeoFormGroup
                                                    :label="$t('[[[Nazwa]]]')"
                                                    :invalid-feedback="form.$errors.first('name')"
                                                    :state="form.$errors.state('name')"
                                                    data-test="licenceName"
                                                    required
                                                >
                                                    <IdeoFormInput v-model="form.name[locale]" type="text" name="name"></IdeoFormInput>
                                                </IdeoFormGroup>
                                            </IdeoFormLocalize>
                                            <IdeoFormLocalize v-slot="{ locale }">
                                                <IdeoFormGroup
                                                    :label="$t('[[[Opis]]]')"
                                                    :invalid-feedback="form.$errors.first('description')"
                                                    :state="form.$errors.state('description')"
                                                >
                                                    <IdeoFormTextarea v-model="form.description[locale]" type="text" name="description"></IdeoFormTextarea>
                                                </IdeoFormGroup>
                                            </IdeoFormLocalize>
                                            <IdeoFormGroup
                                                name="scriptRole"
                                                :label="$t('[[[Rola skryptu]]]')"
                                                :invalid-feedback="form.$errors.first('scriptRole')"
                                                :state="form.$errors.state('scriptRole')"
                                                required
                                            >
                                                <IdeoSelect
                                                    v-model="form.scriptRole"
                                                    :placeholder="$t('[[[Rola skryptu]]]')"
                                                    :options="scriptRoles"
                                                    :disabled="isEditMode || isModalMode"
                                                    @update:modelValue="form.$errors.clear('scriptRole');"
                                                />
                                            </IdeoFormGroup>
                                            <template v-if="form.scriptRole">
                                                <IdeoFormGroup
                                                    name="scriptType"
                                                    :label="$t('[[[Typ skryptu]]]')"
                                                    :invalid-feedback="form.$errors.first('scriptType')"
                                                    :state="form.$errors.state('scriptType')"
                                                    :required="form.scriptRole?.key == 'ExecutableScript'"
                                                >
                                                    <IdeoSelect
                                                        v-model="form.scriptType"
                                                        :placeholder="$t('[[[Typ skryptu]]]')"
                                                        :options="scriptTypes"
                                                        :disabled="isEditMode || isModalMode"
                                                        @update:modelValue="form.$errors.clear('scriptType');"
                                                    />
                                                </IdeoFormGroup>
                                                <IdeoFormGroup
                                                    name="module"
                                                    :label="$t('[[[Typ dokumentu]]]')"
                                                    :invalid-feedback="form.$errors.first('module')"
                                                    :state="form.$errors.state('module')"
                                                >
                                                    <IdeoSelect
                                                        v-model="form.module"
                                                        :placeholder="$t('[[[Typ dokumentu]]]')"
                                                        :endpoint="`simple-dictionary/scripts/form/module`"
                                                        :disabled="isEditMode || isModalMode"
                                                        :reload-key="modulesReloadKey"
                                                        :reload-value="moduleId"
                                                        :reload-option-key="'publicId'"
                                                    />
                                                </IdeoFormGroup>
                                                <IdeoFormGroup
                                                    v-if="!isSharedScript && !isTemplate"
                                                    name="template"
                                                    :label="$t('[[[Szablon]]]')"
                                                    :invalid-feedback="form.$errors.first('template')"
                                                    :state="form.$errors.state('template')"
                                                >
                                                    <IdeoSelect
                                                        v-model="form.template"
                                                        :disabled="form.isSystemScript"
                                                        :placeholder="$t('[[[Szablon]]]')"
                                                        :endpoint="`simple-dictionary/scripts/form/template`"
                                                        :narrow-options="{
                                                            scriptType: form.scriptType?.key,
                                                            moduleId: form.module?.key
                                                        }"
                                                        @update:modelValue="form.$errors.clear('template');"
                                                    />
                                                </IdeoFormGroup>
                                                <IdeoFormGroup
                                                    v-if="!isSharedScript"
                                                    name="linkedScripts"
                                                    :label="$t('[[[Skrypty współdzielone]]]')"
                                                    :invalid-feedback="form.$errors.first('linkedScripts')"
                                                    :state="form.$errors.state('linkedScripts')"
                                                >
                                                    <IdeoSelect
                                                        v-model="form.linkedScripts"
                                                        :disabled="form.isSystemScript"
                                                        :placeholder="$t('[[[Skrypty współdzielone]]]')"
                                                        :endpoint="`simple-dictionary/scripts/form/linkedScripts`"
                                                        :multiselect="true"
                                                        :narrow-options="{
                                                            scriptType: form.scriptType?.key,
                                                            moduleId: form.module?.key
                                                        }"
                                                        @update:modelValue="form.$errors.clear('linkedScripts');"
                                                    />
                                                </IdeoFormGroup>
                                                <IdeoFormGroup
                                                    :invalid-feedback="form.$errors.first('isArchive')"
                                                    :state="form.$errors.state('isArchive')"
                                                >
                                                    <IdeoFormCheckbox v-model="form.isArchive">
                                                        {{ $t('[[[Skrypt archiwalny]]]') }}
                                                    </IdeoFormCheckbox>
                                                </IdeoFormGroup>
                                            </template>
                                        </PlaceholderForm>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </template>
                </IdeoTab>
                <IdeoTab>
                    <template #title>
                        <i class="fas fa-fw fa-diagram-subtask"></i>
                        <span>{{ $t('[[[Skrypty]]]') }}</span>
                    </template>
                    <template #default>
                        <SourceScripts :form="form" :script-engines="scriptEnginesByType" :template-data-uuid="areDatasFromTemplateFetched" @saveForm="saveForm(false)" @update-form-field="updateFormField" />
                    </template>
                </IdeoTab>
            </IdeoTabs>
        </template>
        <template #right>
            <Documentation :form="form" />
        </template>
    </DataCard>
</template>
<style lang="scss">
.script-container{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 260px);
}
</style>
