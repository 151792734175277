import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint, validateBlueprints } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { CustomErrorBlueprint } from '@/components/builder/form/blueprints/CustomErrorBlueprint';
import { ReadonlyBlueprint } from '@/components/builder/form/blueprints/ReadonlyBlueprint';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { HasDescription } from '@/components/builder/form/traits/HasDescription';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasTitle } from '@/components/builder/form/traits/HasTitle';
import { SectionColors } from '../section';
import { Column } from '@/modules/core/common/services/GridService';

export const Definition: BlueprintDefinition = {
    type: 'logito-document-details',
    name: 'Szczegóły dokumentu',
    icon: 'far fa-memo-circle-info',
    group: 'logito',
    position: 14,
};

export interface LogitoDocumentDetailsContract extends AggregateBlueprint, VisibleBlueprint, ReadonlyBlueprint, CustomErrorBlueprint, HasTitle, HasDescription, HasWidth, HasHelp {
    color: SectionColors;
    mode: string;
    fieldName: string;
    moduleId: number;
    fields: Record<string, Column>;
    filtersJson: string;
    filterMappings: Record<string, string>;
}

export class LogitoDocumentDetailsType implements LogitoDocumentDetailsContract, ValidatableBlueprint
{
    public id: string;
    public type: string;
    public name: string;
    public title: Record<string, string>;
    public showTitle: boolean;
    public description: Record<string, string>;
    public color: SectionColors;
    public mode: string;
    public fieldName: string;
    public moduleId: number;
    public fields: Record<string, any>;
    public filtersJson: string;
    public filterMappings: Record<string, string>;
    public components: Blueprint[];
    public help: Record<string, string>;
    public minWidth: number;
    public width: number;
    public exceptWidth: number[];
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public readonly: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public readonlyWhen: string;
    public customError: NeverChoice | WhenChoice;
    public customErrorWhen: string;
    public customErrorMessage: Record<string, string>;
    public errors: ValidationErrors;

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.title = {};
        this.showTitle = false;
        this.description = {};
        this.color = SectionColors.None;
        this.mode = DocumentDetailsMode[DocumentDetailsMode.Field];
        this.fieldName = null;
        this.moduleId = null;
        this.fields = {};
        this.filtersJson = null;
        this.filterMappings = {};
        this.components = [];
        this.help = {};
        this.minWidth = 3;
        this.width = 0;
        this.exceptWidth = [5];
        this.customError = NeverChoice.Never;
        this.customErrorWhen = null;
        this.customErrorMessage = {};
        this.readonly = NeverChoice.Never;
        this.readonlyWhen = null;
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.max(this.minWidth, width);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (this.mode === DocumentDetailsMode[DocumentDetailsMode.Field] && this.fieldName == null)
        {
            this.errors.fieldName = ['[[[Pole jest wymagane]]]'];
        }
        else if (
            (this.mode === DocumentDetailsMode[DocumentDetailsMode.Single] ||
                        this.mode === DocumentDetailsMode[DocumentDetailsMode.Multi]) &&
                    this.moduleId == null
        )
        {
            this.errors.moduleId = ['[[[Moduł jest wymagany]]]'];
        }

        const errors = {
            [this.name]: this.errors,
            ...validateBlueprints(this.components)
        };

        return errors;
    }
}

export enum DocumentDetailsMode {
    Field,
    Single,
    Multi,
    Current
}
