<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { SimplePartner } from '@/modules/core/dictionaries/services/DictionaryService';
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';

@Options({
    name: 'SimplePartnerArrayFormatter',
})
export default class SimplePartnerArrayFormatter extends Vue
{
    @Prop({ default: null }) public property: SimplePartner[];
    @Prop() public features: KeyValuePair[];
    @Prop({ default: false }) public isOnGrid: boolean;

    public canSeeDetails: boolean = false;

    public async created(): Promise<void>
    {
        this.canSeeDetails =
            (await this.$permissions.any(['Ideo.Logito.Crm.CrmPartnerPermissions@Access'])) && !this.isOnGrid;
    }
}
</script>

<template>
    <div v-if="property">
        <div v-for="(contractor, index) in property" :key="index" class="property-value-2-lines">
            <RouterLink
                v-if="canSeeDetails"
                :to="{ name: 'crm-bs-partners-details', params: { publicId: contractor.key } }"
            >
                {{ contractor.value }}
            </RouterLink>
            <span v-else>{{ contractor.value }}</span>
        </div>
    </div>
    <div v-else>-</div>
</template>

<style scoped>
.property-value {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>