<template>
    <div class="form-group">
        <label v-if="label" :for="uid">{{ label }}</label>
        <div
            v-for="(action, index) in blueprint.dynamicActions"
            :key="action.key"
            class="d-flex flex-column position-relative"
        >
            <div class="d-flex">
                <div class="flex flex-fill flex-column">
                    <ideo-form-localize v-slot="{ locale }">
                        <field-text
                            v-model="action.name[locale]" :label="$t('[[[Nazwa]]]')" :required="true"
                            :invalid-feedback="() => form.schema.errorMessage(blueprint, `dynamicActions[${index}].name`)"
                        />
                    </ideo-form-localize>
                    <ideo-form-group :label="$t('[[[Styl przycisku]]]')" class="mt-2">
                        <ButtonStyles v-model="action.buttonStyle" :button-styles="buttonStyles" />
                    </ideo-form-group>
                    <FieldScript
                        v-model="action.script"
                        :custom-class="'mb-1'"
                        class="mb-1"
                        :show-label="false"
                        :label="$t('[[[Skrypt]]]')"
                        :endpoint="`admin/forms/scripts`"
                        :script-type="scriptType"
                        :module-id="moduleId"
                        :invalid-feedback="() => form.schema.errorMessage(blueprint, `dynamicActions[${index}].script`)"
                    />
                    <ideo-form-group :label="$t('[[[Otwórz modal po wykonaniu akcji]]]')" class="mt-2">
                        <field-select
                            v-model="action.closingModal"
                            :options="dynamicModals"
                            :invalid-feedback="() => form.schema.errorMessage(blueprint, `dynamicActions[${index}].closingModal`)"
                        />
                    </ideo-form-group>
                </div>
                <div class="input-group-append ms-1" style="margin-top: 27px;">
                    <button type="button" class="btn btn-outline-light" @click="deleteDynamicAction(index)">
                        <i class="far fa-trash text-danger"></i>
                    </button>
                </div>
            </div>

            <hr role="separator" aria-orientation="horizontal" class="dropdown-divider my-2" />
        </div>

        <button class="btn btn-outline-light w-100" type="button" @click="addDynamicAction">
            <i class="far fa-plus text-success"></i>
        </button>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { cloneDeep } from 'lodash';
import properties from '../../../properties';
import { FieldScript } from '@/components/forms/properties';
import FieldText from '@/components/dynamic-details/properties/FieldText.vue';
import { FormBuilderContract } from '@/components/builder/form';
import { ScriptTypeEnum } from '@/modules/low-code/services/ScriptsService';
import { DynamicModalType, DynamicActionType, Definition as DynamicModalDefinition } from '../index';
import ButtonStyles from '@/components/forms/blueprints/logito-dynamic-modal/controls/ButtonStyles.vue';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';

@Options({
    name: 'dynamic-action',
    components: {
        ...properties,
        FieldText,
        FieldScript,
        ButtonStyles
    },
})
export default class DynamicAction extends Vue
{
    @Prop() public label: string;
    @Prop() public modelValue: DynamicActionType[];
    @Prop() public buttonStyles: any[];
    @Prop({ default: null }) public form!: FormBuilderContract;
    @Prop({ default: null }) public blueprint!: DynamicModalType;
    @Prop({ default: null }) public module: any;

    public scriptType = ScriptTypeEnum.DynamicModal;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get moduleId()
    {
        return this.$route.params.moduleId;
    }

    public get dynamicModals()
    {
        return this.form.schema
            .components([DynamicModalDefinition.type], this.blueprint)
            .map((p) => ({ value: p.name, text: this.form.localization.translate((p as HasLabel).label) }));
    }

    public addDynamicAction(): void
    {
        let value = cloneDeep(this.modelValue);

        if (value == null)
            value = [];

        value.push({
            key: 'dynamicAction' + (value.length + 1),
            name: { 'pl-PL': '' },
            script: null,
            closingModal: '',
            buttonStyle: { key: 'Success', value: 'Success' }
        });

        this.updateModelValue(value);
    }

    public deleteDynamicAction(index: number): void
    {
        const value = cloneDeep(this.modelValue);

        value.splice(index, 1);
        this.updateModelValue(value);
    }

    @Emit('update:modelValue')
    public updateModelValue(value: any[]): any[]
    {
        return value;
    }
}
</script>
