<script lang="ts" setup>
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["update:value", "update:idsRows"]);
import { computed } from 'vue';
import { MIN_WIDTH, MAX_WIDTH, TableType } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { NumericContract, Definition as NumericDefinition } from '../numeric';
import { evaluate } from 'mathjs';

const { value, idsRows } = __MACROS_useVModel("value", "idsRows");

const props = defineProps({
  "value": null,
  "idsRows": null,
  "blueprint": null,
  "entry": null,
  "form": null,
  "design": { type: Boolean,  },
  "canAddRow": { type: Boolean,  },
  "reloadKey": null
});

const blueprint = computed(() => props.blueprint);
const columns = computed(() => blueprint.value.columns);
const footer = computed(() => blueprint.value.columns.some((item) => item.summary));
const showColumnOrdinalNumber = computed(() => blueprint.value.showColumnOrdinalNumber);

const formulas = computed(() =>
{
    return blueprint.value.columns
        .map((item, index) => ({ formula: item.formula, field: blueprint.value.components[index].name }))
        .filter((item) => item.formula && item.field && item.field.toLowerCase().includes(NumericDefinition.type));
});

const columnsHeader = (header: Record<string, string>) =>
{
    return props.form.localization.translate(header);
};

const getSumOfColumn = (colName: string, precision: number): string =>
{
    if (!props.design)
    {
        const sum = value.value.reduce((acc: number, item: any, index: number) =>
        {
            const _blueprint = props.form.schema.find(colName) as NumericContract;
            const readonly = props.form.expressions.readonly(_blueprint);
            const data = item[colName]?.data;
            const value =
                data == null || readonly
                    ? props.form.expressions.executeExpression(_blueprint.defaultValue, index)
                    : data;

            const _type = item[colName]?.type;

            if (_type === NumericDefinition.type) return acc + (value || 0);

            return acc;
        }, 0);

        return sum.toFixed(precision || 0);
    }

    return Number(0).toFixed(precision || 0);
};

const getScope = (index: number, field: string) =>
{
    const mappedScope: Record<string, number> = {};

    for (const key in value.value[index])
    {
        if (key.toLowerCase().includes(NumericDefinition.type) && key != field)
        {
            mappedScope[key] = value.value[index][key].data ?? 0;
        }
    }

    return mappedScope;
};

const removeRow = (index: number) =>
{
    value.value.splice(index, 1);
    idsRows.value.splice(index, 1);
};

const getWidth = (index: number) =>
{
    const width = blueprint.value.columns[index].width;

    function clamp(val: number, min: number, max: number)
    {
        return val > max ? max : val < min ? min : val;
    }

    return {
        ...(width ? { width: `${clamp(width, MIN_WIDTH, MAX_WIDTH)}px` } : {}),
    };
};

const resultFormula = (index: number) =>
{
    const formulasLength = formulas.value.length;

    for (let i = 0; i < formulasLength; i++)
    {
        const formula = formulas.value[i];
        const scope = getScope(index, formula.field);

        if (Object.values(scope)?.length > 0)
        {
            const result = evaluate(formula.formula, scope);

            value.value[index][formula.field].data = result;
        }
    }
};

const isColumnVisible = (index: number) =>
{
    const formula = blueprint.value.columns[index].visibilityFormula;

    if (!formula) return true;

    if (props.design) return true;

    return props.form.expressions.executeExpression(formula);
};

</script>

<template>
    <div class="scroll scroll-x pb-1">
        <table v-if="columns.length" class="table table-bordered mb-0">
            <colgroup>
                <col v-if="showColumnOrdinalNumber" style="width: 40px" />
                <col v-for="(col, index) in blueprint.components.filter((p, i) => isColumnVisible(i))" :key="col.id" :style="getWidth(index)" />
                <col v-if="idsRows.length && canAddRow" style="width: 54px" />
            </colgroup>
            <thead>
                <tr>
                    <th v-if="showColumnOrdinalNumber">{{ $t('[[[Lp.]]]') }}</th>
                    <th v-for="(col, index) in blueprint.components" v-show="isColumnVisible(index)" :key="col.id">
                        {{ columnsHeader(blueprint.columns[index].columnHeader) }}
                    </th>
                    <th v-if="idsRows.length && canAddRow"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in idsRows" :key="row">
                    <td v-if="showColumnOrdinalNumber" class="text-end align-middle">{{ index + 1 }}</td>
                    <td
                        v-for="(col, colIndex) in blueprint.components"
                        v-show="isColumnVisible(colIndex)"
                        :key="col.id"
                        class="position-relative"
                        :class="!design ? 'p-2 pb-0' : 'p-0'"
                    >
                        <component
                            :is="form.schema.designer(col.type)"
                            :form="form"
                            :parent="blueprint"
                            :blueprint="col"
                            :entry="entry"
                            :index="index"
                            width-full
                            disabled-drag
                            disabled-toolbar
                            :key="reloadKey"
                            @update="resultFormula(index)"
                        ></component>
                    </td>
                    <td v-if="canAddRow" class="align-middle">
                        <ideo-button
                            variant="danger"
                            icon="fas fa-trash"
                            :disabled="design"
                            @click="removeRow(index)"
                        />
                    </td>
                </tr>
                <tr v-if="idsRows.length === 0">
                    <td :colspan="blueprint.components.length" class="text-center">
                        <b>{{ $t('[[[Brak wyników]]]') }}</b>
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="footer">
                <tr class="border-0">
                    <td v-if="showColumnOrdinalNumber" class="border-0"></td>
                    <td
                        v-for="(col, index) in blueprint.components"
                        v-show="isColumnVisible(index)"
                        :key="col.id"
                        :class="!blueprint.columns[index].summary ? 'border-0' : 'table-border'"
                    >
                        <ideo-input-group
                            v-if="blueprint.columns[index].summary"
                            :prepend="(col as NumericContract).affix.prepend"
                            :append="(col as NumericContract).affix.append"
                        >
                            <input
                                type="number"
                                class="form-control"
                                :value="getSumOfColumn(col.name, (col as NumericContract).precision)"
                                disabled
                            />
                        </ideo-input-group>
                    </td>
                </tr>
            </tfoot>
        </table>
        <p v-else>{{ $t('[[[Brak dodanych kolumn]]]') }}</p>
    </div>
</template>

<style lang="scss" scoped>
.table {
    table-layout: fixed;
    overflow: scroll;

    thead {
        tr {
            height: 36px;

            th {
                background: var(--bs-tertiary-bg);
            }
        }
    }

    &-border {
        background: var(--bs-tertiary-bg);
        border: 1px solid var(--bs-table-border-color);
    }
}

.table-error {
    margin-top: -12px;
}
</style>
