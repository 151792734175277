<script lang="ts">
import { Prop } from '@/helpers/Decorators';
import { KeyValuePair } from '@/helpers/Interfaces';
import { Options, Vue } from 'vue-class-component';
import { AttributesModel, Column } from '@/modules/core/common/services/GridService';
import { getAttributes } from '@/components/common/dynamic-grid/helpers/TypeOfData';

// Presenters
import DateTimePresenter from '../presenters/DateTime.vue';
import UserWithAvatarPresenter from '../presenters/UserWithAvatar.vue';
import UserWithAvatarArrayPresenter from '../presenters/UserWithAvatarArray.vue';
import TextPresenter from '../presenters/Text.vue';
import StatusPresenter from '../presenters/StatusWithVariant.vue';
import MoneyPresenter from '../presenters/Money.vue';
import BooleanPresenter from '../presenters/Boolean.vue';
import GenericKeyValuePairPresenter from '../presenters/GenericKeyValuePair.vue';
import GenericKeyValuePairArrayPresenter from '../presenters/GenericKeyValuePairArray.vue';
import DecimalPresenter from '../presenters/Decimal.vue';
import AddressPresenter from '../presenters/Address.vue';
import MainFilePresenter from '../presenters/File.vue';
import ListPresenter from '../presenters/List.vue';
import SimplePartnerPresenter from '../presenters/SimplePartner.vue';
import SimplePartnerArrayPresenter from '../presenters/SimplePartnerArray.vue';
import DurationPresenter from '../presenters/Duration.vue';
import ResourcePresenter from '../presenters/Resource.vue';
import TableVatPresenter from '../presenters/TableVat.vue';
import UserGroupArrayPresenter from '../presenters/UserGroupArray.vue';
import ExchangeRatePresenter from '../presenters/ExchangeRate.vue';
import DynamicDocumentPresenter from '../presenters/DynamicDocument.vue';
import IsPrivatePresenter from '../presenters/IsPrivate.vue';
import RichTextPresenter from '../presenters/RichText.vue';
import ParticipantList from '@/modules/low-code/components/ParticipantList.vue';

@Options({
    name: 'TableFormatter',
    components: {
        ParticipantList,
        DateTimePresenter,
        UserWithAvatarPresenter,
        UserWithAvatarArrayPresenter,
        TextPresenter,
        StatusPresenter,
        MoneyPresenter,
        BooleanPresenter,
        GenericKeyValuePairPresenter,
        DecimalPresenter,
        AddressPresenter,
        MainFilePresenter,
        ListPresenter,
        SimplePartnerPresenter,
        SimplePartnerArrayPresenter,
        DurationPresenter,
        ResourcePresenter,
        TableVatPresenter,
        GenericKeyValuePairArrayPresenter,
        UserGroupArrayPresenter,
        ExchangeRatePresenter,
        DynamicDocumentPresenter,
        IsPrivatePresenter,
        RichTextPresenter,
    },
})
export default class TableFormatter extends Vue
{
    @Prop() public property: Record<string, any>[];
    @Prop() public features: KeyValuePair[];
    @Prop({ default: () => ({}) }) public tableSchema: { head: Column; body: { [key: string]: Column } };
    @Prop({ default: false }) public isOnGrid: boolean;
    @Prop({ default: false }) public canCopy: boolean;
    @Prop({ default: {} }) public columnsVisibility: Record<string, boolean>;
    @Prop({ default: null }) public customConfig: any;

    public get isFooter(): boolean
    {
        return this.tableSchema.head.columns.some((item) => item.summary);
    }

    public translatedText(text: Record<string, string>): string
    {
        return this.$i18n.currentTranslation(text);
    }

    public TypeOfData(value: any, header: Column): Record<string, any>
    {
        const headerOptions = header;
        const property = value;
        const features = headerOptions.type.features;
        const attributes: AttributesModel = {
            property,
            features,
            ...getAttributes(headerOptions.type.baseType, { isInTable: true, canCopy: this.canCopy, customConfig: this.customConfig }),
        };

        return attributes;
    }

    public getSumOfColumn(colName: string): number
    {
        if (!this.property) return;

        const sum = this.property.reduce((acc, item) =>
        {
            const value = item[colName];

            if (colName.toLowerCase().includes('numeric')) return acc + (value || 0);

            return acc;
        }, 0);

        return sum;
    }

    public colWidth(index: number): any
    {
        const minWidth = 50;
        const maxWidth = 2000;
        let width = minWidth;

        if (this.customConfig != null && this.customConfig?.columns != null && this.customConfig?.columns.length > index)
        {
            const columnWidth = parseInt(this.customConfig?.columns[index]?.width ?? 0);

            if (columnWidth != null && !isNaN(columnWidth))
                width = columnWidth;
        }

        function clamp(val: number, min: number, max: number)
        {
            return val > max ? max : val < min ? min : val;
        }

        return {
            ...(width ? { width: `${clamp(width, minWidth, maxWidth)}px` } : {}),
        };
    }
}
</script>

<template>
    <div class="scroll scroll--overflow-y-hidden">
        <table class="table table-bordered mb-2">
            <thead v-if="tableSchema.head?.columns">
                <tr>
                    <th v-for="(col, index) in tableSchema.head.columns" v-show="columnsVisibility?.[col.fieldName] != false" :key="index" :style="colWidth(index)">{{ translatedText(col.columnName) }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, indexRow) in property" :key="indexRow">
                    <td v-for="(col, indexCol) in tableSchema.head.columns" v-show="columnsVisibility?.[col.fieldName] != false" :key="indexCol" class="p-2">
                        <component
                            :is="TypeOfData(row[col.fieldName], tableSchema.body[col.fieldName]).is"
                            v-bind="{ ...TypeOfData(row[col.fieldName], tableSchema.body[col.fieldName]) }"
                        />
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="isFooter">
                <tr class="border-0">
                    <td
                        v-for="(col, index) in tableSchema.head.columns"
                        v-show="columnsVisibility?.[col.fieldName] != false"
                        :key="index"
                        :class="!col.summary ? 'border-0' : 'table--border'"
                    >
                        <span v-if="col.summary" class="fw-bold">
                            <component
                                :is="TypeOfData(getSumOfColumn(col.fieldName), tableSchema.body[col.fieldName]).is"
                                v-bind="{
                                    ...TypeOfData(getSumOfColumn(col.fieldName), tableSchema.body[col.fieldName]),
                                }"
                            />
                        </span>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<style lang="scss" scoped>
.table {
    table-layout: fixed;
    width: 100%;

    thead {
        tr {
            height: 36.5px;
            background: var(--border-color-nav);

            th {
                width: 130px;
            }
        }
    }

    tbody {
        tr {
            td {
                :deep(span) {
                    white-space: wrap !important;
                }
            }
        }

    }

    &--border {
        background: var(--border-color-nav);
        border: 1px solid var(--bs-table-border-color);
    }
}
</style>
