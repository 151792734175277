<template>
    <div class="form-group">
        <label v-if="label" :for="uid">{{ label }}</label>
        <div
            v-for="(computedField, index) in blueprint.computedFields"
            :key="computedField.key"
            class="d-flex flex-column position-relative"
        >
            <div class="d-flex">
                <div class="flex flex-fill flex-column">
                    <field-text
                        v-model="computedField.name"
                        :placeholder="$t('[[[Nazwa (wymagane)]]]')"
                        class="mb-1"
                        :invalid-feedback="() => form.schema.errorMessage(blueprint, `computedFields[${index}].name`)"
                    />
                    <FieldScript
                        v-model="computedField.expression"
                        :custom-class="'mb-1'"
                        class="mb-1"
                        :show-label="false"
                        :label="$t('[[[Wyrażenie (wymagane)]]]')"
                        :endpoint="`admin/forms/scripts`"
                        :script-type="scriptType"
                        :module-id="moduleId"
                        :invalid-feedback="() => form.schema.errorMessage(blueprint, `computedFields[${index}].expression`)"
                    />
                    <field-select
                        v-model="computedField.type"
                        :options="typeOptions"
                        class="mb-0"
                        :invalid-feedback="() => form.schema.errorMessage(blueprint, `computedFields[${index}].type`)"
                    />
                </div>
                <div class="input-group-append ms-1">
                    <button type="button" class="btn btn-outline-light" @click="deleteComputedField(index)">
                        <i class="far fa-trash text-danger"></i>
                    </button>
                </div>
            </div>

            <hr role="separator" aria-orientation="horizontal" class="dropdown-divider my-2" />
        </div>

        <button class="btn btn-outline-light w-100" type="button" @click="addComputedField">
            <i class="far fa-plus text-success"></i>
        </button>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { TableType, ComputedField } from '../blueprints/logito-table';
import { cloneDeep } from 'lodash';
import { FormBuilderContract } from '@/components/builder/form';
import { Option } from '@/helpers/Interfaces';
import FieldText from './FieldText.vue';
import FieldExpression from './FieldExpression.vue';
import FieldSelect from './FieldSelect.vue';
import { ScriptTypeEnum } from '@/modules/low-code/services/ScriptsService';
import FieldScript from './FieldScript.vue';

enum ComputedFieldType {
    Text = 'Text',
    Number = 'Number',
    Boolean = 'Boolean',
    DateTime = 'DateTime',
}

@Options({
    name: 'field-computed',
    components: {
        FieldText,
        FieldExpression,
        FieldSelect,
        FieldScript
    },
})
export default class FieldComputed extends Vue
{
    @Prop() public label: string;
    @Prop() public modelValue: ComputedField[];
    @Prop({ default: null }) public form!: FormBuilderContract;
    @Prop({ default: null }) public blueprint!: TableType;
    @Prop({ default: null }) public module: any;

    public get uid(): string
    {
        return `form-field-${this.$.uid}`;
    }

    public get typeOptions(): Option<string>[]
    {
        return [
            {
                text: ComputedFieldType.Text,
                value: ComputedFieldType.Text,
            },
            {
                text: ComputedFieldType.Number,
                value: ComputedFieldType.Number,
            },
            {
                text: ComputedFieldType.Boolean,
                value: ComputedFieldType.Boolean,
            },
            {
                text: ComputedFieldType.DateTime,
                value: ComputedFieldType.DateTime,
            },
        ];
    }

    public get scriptType()
    {
        return ScriptTypeEnum.DynamicTableField;
    }

    public get moduleId()
    {
        return this.$route.params.moduleId;
    }

    public addComputedField(): void
    {
        const value = cloneDeep(this.modelValue);

        value.push({
            key: 'computedField' + (value.length + 1),
            name: '',
            expression: '',
            type: 'Number',
            script: null
        });

        this.updateModelValue(value);
    }

    public deleteComputedField(index: number): void
    {
        const value = cloneDeep(this.modelValue);

        value.splice(index, 1);
        this.updateModelValue(value);
    }

    @Emit('update:modelValue')
    public updateModelValue(value: ComputedField[]): ComputedField[]
    {
        return value;
    }
}
</script>
