<script lang="ts" setup>
import { ref, computed, onMounted, watch } from 'vue';
import { IKeyFields } from '@/modules/low-code/print-templates/services/PrintTemplatesService';
import ScriptsService from '@/modules/low-code/services/ScriptsService';
import KeyFields from '@/components/common/KeyFields.vue';

const props = defineProps({
  "module": null
});

const printField = ref<Array<IKeyFields>>([]);

const moduleId = computed(() =>
{
    if (props.module)
        return (props.module as any).publicId;

    return '';
});

const fieldsViewModel = computed(() =>
{
    return printField.value;
});

const loadData = async () =>
{
    if (moduleId.value == null || moduleId.value == '')
        return;

    const response = await ScriptsService.getFieldsSchema(moduleId.value);

    printField.value = response.map((item) => ({
        key: item.key,
        description: item.value,
        properties: item.properties?.map((property: any) => ({ key: property.key, description: property.value })),
    }));
};

watch(moduleId, () => (loadData()), {
    immediate: true,
});

onMounted(() =>
{
    loadData();
});

</script>

<template>
    <b>{{ $t('[[[Użycie pól dokumentu:]]]') }}</b><br />
    {{ $t("[[[doc['Nazwa pola']]]]") }}
    <KeyFields :fields="fieldsViewModel" class="mt-2" />
</template>
