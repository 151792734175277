<script lang="ts" setup>
import { ref, reactive, computed, onMounted, onUnmounted } from 'vue';
import { useEvents } from '@/plugins/events';
import { useAlerts } from '@/plugins/alerts';
import { Form } from '@/helpers/Form';
import { $t } from '@/plugins/localization';
import { FrontActionModal } from '@/components/common/dynamic-grid/helpers/GridEnums';
import { BpmnEmitOptions } from '@/modules/low-code/process/services/BpmnService';
import IdeoModal from '@/components/ideo/modal/IdeoModal.vue';
import SZAFIR_SDK from '@/modules/logito/signatures/js/szafirsdk-module.js';
import SzafirWebModuleService, { SettingsModel, SignState } from '@/modules/logito/signatures/services/SzafirWebModuleService';

const emit = defineEmits(["reload"]);

const { $events } = useEvents();
const { $alert } = useAlerts();

const modal = ref<IdeoModal>(null);
const canSign = ref<boolean>(false);
const taskLists = ref<any>(null);
const signType = ref<string>('');

const settings = ref<SettingsModel>(null);
const taskList = ref<string>(null);
const settingsXml = ref<string>(null);

const noExtensionError = ref<boolean>(false);
const noHostError = ref<boolean>(false);

const taskListMessages = ref<any>([
    { message: $t('[[[Przygotowanie kontrolek]]]'), value: null },
    { message: $t('[[[Ładowanie task listy]]]'), value: null },
    { message: $t('[[[Podpis dokumentu]]]'), value: null },
    { message: $t('[[[Wysyłka dokumentu]]]'), value: null }
]);

const form = reactive(
    Form.create<any>({

    })
);

const action = reactive(
    Form.create<BpmnEmitOptions>({
        id: '',
        licence: '',
        actionName: '',
        buttonId: '',
        displayMode: 'Modal',
        displaySize: 'Medium',
        title: $t('[[[Podpisz dokument]]]'),
        buttonName: $t('[[[Akceptuj]]]'),
        buttonVariant: 'success',
        buttonIcon: '',
        groupAction: false,
    })
);

const signOptions = computed(() =>
{
    if (!taskLists.value)
        return [];

    return Object.keys(taskLists.value).map(p => ({
        value: p,
        text: p
    }));
});

const szafirsdkLinkExtension = computed(() =>
{
    return SZAFIR_SDK.getExtLink();
});

const szafirsdkLinkInstaller = computed(() =>
{
    return SZAFIR_SDK.getAppLink();
});

const szafirsdkLinkInstallerUx = computed(() =>
{
    return SZAFIR_SDK.getAppLinks().linux;
});

const szafirIsHostUpdateRequired = computed(() =>
{
    return SZAFIR_SDK.isHostUpdateRequired();
});

onMounted(() =>
{
    $events.$on(FrontActionModal.SignDocumentSzafirWebModule, async (_action: BpmnEmitOptions) =>
    {
        action.withData(_action);

        if (canInvokeAction(0))
        {
            setDefaultValues();

            try
            {
                await Promise.all([
                    await loadSettings()
                ]);

                loadWebModule();
            }
            catch (ex)
            {
                setSignState(0, SignState.Error);
            }
        }

        modal.value.show();
    });
});

onUnmounted(() =>
{
    setDefaultValues();

    $events.$off(FrontActionModal.SignDocumentSzafirWebModule);
});

const setDefaultValues = () =>
{
    canSign.value = false;

    settings.value = null;
    taskList.value = null;
    settingsXml.value = null;

    noExtensionError.value = false;
    noHostError.value = false;

    taskListMessages.value.forEach((task: any) =>
    {
        task.value = null;
    });
};

const setSignState = (index: number, signState: SignState) =>
{
    taskListMessages.value[index].value = signState;

    if (signState == SignState.InProgress && index > 0)
        taskListMessages.value[index - 1].value = SignState.Success;
};

const canInvokeAction = (index: number) =>
{
    if (taskListMessages.value.some((p: any) => p.value == SignState.Error))
        return true;

    if (taskListMessages.value.every((p: any) => p.value == SignState.Success))
        return true;

    const signState = taskListMessages.value[index].value;

    return signState == null || signState == SignState.Error;
};

const loadSettings = async () =>
{
    try
    {
        settings.value = await SzafirWebModuleService.getSettings();
    }
    catch (ex: any)
    {
        if (ex.code === 409)
            $alert.warning(ex.data.error);
        else
            $alert.error($t('[[[Wystąpił błąd podczas pobierania ustawień.]]]'));

        throw ex;
    }
};

const loadTaskLists = async () =>
{
    try
    {
        const result = await SzafirWebModuleService.getTaskLists(action.id, action.buttonId, action.licence);

        taskLists.value = result;
    }
    catch (ex: any)
    {
        if (ex.code === 409)
            $alert.warning(ex.data.error);
        else
            $alert.error($t('[[[Wystąpił błąd podczas pobierania task listy.]]]'));

        throw ex;
    }
};

const loadSettingsXml = async () =>
{
    try
    {
        settingsXml.value = await SzafirWebModuleService.getSettingsXml();
    }
    catch (ex: any)
    {
        if (ex.code === 409)
            $alert.warning(ex.data.error);
        else
            $alert.error($t('[[[Wystąpił błąd podczas pobierania konfiguracji kontrolki.]]]'));

        throw ex;
    }
};

const setSzafirConfig = () =>
{
    const debugMode = settings.value?.debugMode ?? false;

    SZAFIR_SDK.config({
        sdk_location_url: '/api/signatures/szafir-web-module/static-files/sdk',
        series_authorization: true,
        onErrorNoExtension: function(errorCode, info)
        {
            noExtensionError.value = true;
        },
        onErrorNoHostApp: function(errorCode, info)
        {
            noHostError.value = true;
        },
        debug: debugMode
    });
};

const loadWebModule = async () =>
{
    setSignState(0, SignState.InProgress);

    setSzafirConfig();

    let szafirIsBusy = false;

    if (SZAFIR_SDK.isBusy())
        szafirIsBusy = await new Promise((resolve, reject) => setTimeout(() => resolve(SZAFIR_SDK.isBusy()), 5000));

    if (noExtensionError.value || noHostError.value)
        return;

    if (szafirIsBusy)
    {
        $alert.warning($t('[[[Nie udało się załadować Szafir Host. Zamknij aplikację do podpisów, jeśli jest otwarta, odśweiż stronę i wykonaj akcję ponownie.]]]'));
        setSignState(0, SignState.Error);

        return;
    }

    SZAFIR_SDK.loadSzafir(() =>
    {
        loadSignConfig();
    }, (e) =>
    {
        setSignState(0, SignState.Error);
        $alert.warning(e);
    });
};

const loadSignConfig = async () =>
{
    await loadSettingsXml();

    SZAFIR_SDK.startComponent(settingsXml.value, async (result: any) =>
    {
        setSignState(1, SignState.InProgress);

        try
        {
            await loadTaskLists();
        }
        catch (ex)
        {
            setSignState(1, SignState.Error);

            return;
        }

        if (!signOptions.value || signOptions.value.length == 0)
        {
            setSignState(1, SignState.Error);
            $alert.warning($t('[[[Nie wygenerowano żadnej task listy.]]]'));

            return;
        }

        if (signOptions.value.length == 1)
        {
            signType.value = signOptions.value[0].value;
            sign();
        }
        else
            canSign.value = true;
    }, () =>
    {
        setSignState(0, SignState.Error);
        $alert.warning($t('[[[Sprawdź czy ustawienia kontrolki są poprawne.]]]'));
    });
};

const sign = async () =>
{
    setSignState(2, SignState.InProgress);
    canSign.value = false;
    taskList.value = taskLists.value[signType.value];

    SZAFIR_SDK.doTaskList(taskList.value, async (result: any) =>
    {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(result, "application/xml");

        const resultCode = xmlDoc.querySelector('SigningResult > Result').getAttribute('code');

        if (resultCode == '0')
        {
            setSignState(3, SignState.InProgress);
            await sendSignedDoc(result);
        }
        else
        {
            const errorMessage = xmlDoc.querySelector('SigningResult > Result').textContent;

            $alert.warning(errorMessage);
            setSignState(2, SignState.Error);
        }
    }, () =>
    {
        $alert.warning($t('[[[Sprawdź poprawność task listy]]]'));
        setSignState(2, SignState.Error);
    });
};

const sendSignedDoc = async (signedDoc: string) =>
{
    try
    {
        form.wait();

        await SzafirWebModuleService.signDocument(action.id, action.buttonId, action.licence, signedDoc);

        $alert.success($t('[[[Dokument został podpisany]]]'));
        setSignState(3, SignState.Success);
        modal.value.hide();

        setTimeout(() =>
        {
            emit('reload');
            $events.$emit('refetchData');
            $events.$emit('refetchActions');
            form.reset();
        }, 500);
    }
    catch (ex: any)
    {
        if (ex.code === 409)
            $alert.warning(ex.data.error);
        else
            $alert.error($t('[[[Wystąpił błąd podczas wysyłki podpisanego dokumentu.]]]'));

        setSignState(3, SignState.Error);
    }
    finally
    {
        form.continue();
    }
};
</script>

<template>
    <IdeoModal
        ref="modal"
        :title="action.title"
        centered
        :size="'md'"
        @hidden="form.clear()"
    >
        <template #default>
            <div v-if="canSign">
                <div class="row">
                    <div class="col-12">
                        <IdeoFormGroup
                            :label="$t('[[[Wybierz typ podpisu]]]')"
                            required
                        >
                            <IdeoFormRadioGroup v-model="signType" :options="signOptions"></IdeoFormRadioGroup>
                        </IdeoFormGroup>
                    </div>
                </div>
            </div>
            <div v-else-if="noExtensionError">
                <div class="error-container">
                    {{ $t('[[[Niniejsza strona wykorzystuje komponenty podpisu elektronicznego ]]]') }}
                    <a href="http://elektronicznypodpis.pl/" target="_blank">{{ $t('[[[Szafir SDK ]]]') }}</a>
                    <a href="http://www.kir.pl/" target="_blank">{{ $t('[[[Krajowej Izby Rozliczeniowej S.A. ]]]') }}</a>
                    <br /><br />
                    {{ $t('[[[W wersji dla przeglądarek]]]') }} <b>Google Chrome</b>, <b>Microsoft Edge</b> (Chromium), <b>Opera</b> {{ $t('[[[oraz]]]') }} <b>Firefox</b>
                    {{ $t('[[[wymagana jest instalacja dedykowanego rozszerzenia]]]') }} <b>{{ $t('[[[Podpis elektroniczny Szafir SDK]]]') }}</b> {{ $t('[[[oraz aplikacji udostępniającej funkcje podpisu elektronicznego.]]]') }}
                    <div class="link-container mt-2 mb-2">
                        <a :href="szafirsdkLinkExtension" target="_blank" class="btn btn-primary">{{ $t('[[[Instalacja rozszerzenia z Web Store]]]') }}</a>
                    </div>
                    <div><b>{{ $t('[[[Uwaga:]]]') }}</b> {{ $t('[[[Po zainstalowaniu rozszerzenia należy przeładować bieżącą stronę!]]]') }}</div>
                </div>
            </div>
            <div v-else-if="noHostError">
                <div class="error-container">
                    <div>
                        <span v-if="!szafirIsHostUpdateRequired">
                            {{ $t('[[[Przeglądarka wymaga zainstalowania dedykowanej aplikacji]]]') }} <b>{{ $t('[[[Szafir Host]]]') }}</b> {{ $t('[[[umożliwiającej składanie podpisu elektronicznego.]]]') }}
                        </span>
                        <span v-else id="szafirsdk-hostapp-update" style="color: Red;">{{ $t('[[[Posiadasz nieaktualną wersję aplikacji]]]') }} <b>{{ $t('[[[Szafir Host]]]') }}</b>!!!</span>
                        <br /><br />
                        <span>{{ $t('[[[Pobierz i zainstaluj aplikację]]]') }} <b>{{ $t('[[[Szafir Host]]]') }}</b> {{ $t('[[[w wersji odpowiedniej dla używanego systemu operacyjnego, klikając na jeden z poniższych odnośników]]]') }}:</span>
                    </div>
                    <div class="link-container mt-2 mb-2">
                        <a :href="szafirsdkLinkInstaller" target="_blank" class="btn btn-primary">{{ $t('[[[Szafir Host dla Windows]]]') }}</a>
                        <a :href="szafirsdkLinkInstallerUx" target="_blank" class="btn btn-primary">{{ $t('[[[Szafir Host dla Linux/Mac OS]]]') }}</a>
                    </div>
                    <div><b>{{ $t('[[[Uwaga]]]') }}:</b> {{ $t('[[[Po zainstalowaniu aplikacji Szafir Host przeładuj bieżącą stronę!]]]') }}</div>
                    <div class="info mt-2 bg-secondary p-2">
                        {{ $t('[[[Jeżeli aplikacja została prawidłowo zainstalowana i mimo tego nadal pojawia się niniejszy komunikat, należy sprawdzić czy na komputerze zainstalowane jest środowisko uruchomieniowe Java.]]]') }}
                        <br /><br />
                        {{ $t('[[[Darmowe środowisko OpenJDK można pobrać i zainstalować ze strony]]]') }} <a href="https://adoptium.net/temurin/releases/" target="_blank" style="font-weight: bold;">Adoptium.net</a> {{ $t('[[[udostępnionej przez Eclipse Foundation.]]]') }}
                    </div>
                </div>
            </div>
            <template v-else>
                <div class="container">
                    <table class="table table-bordered table-sm">
                        <tbody>
                            <tr v-for="(item, index) in taskListMessages" :key="index">
                                <td>{{ item.message }}</td>
                                <td class="text-center">
                                    <i v-if="item.value == SignState.Success" class="fas fa-check-circle text-success"></i>
                                    <i v-else-if="item.value == SignState.Error" class="fas fa-times-circle text-danger"></i>
                                    <i v-else-if="item.value == SignState.InProgress" class="fas fa-spinner fa-spin text-muted"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </template>
        <template #modal-footer="{ cancel }">
            <IdeoButton
                v-if="canSign"
                :variant="action.buttonVariant"
                :disabled="!form.active() || !signType"
                @click="sign"
            >
                <i v-if="action.buttonIcon" class="me-2" :class="action.buttonIcon"></i>
                <span>{{ action.buttonName }}</span>
            </IdeoButton>
            <IdeoButton variant="secondary" @click="cancel">
                {{ $t('[[[Anuluj]]]') }}
            </IdeoButton>
        </template>
    </IdeoModal>
</template>
<style scoped lang="scss">
.error-container{
    .link-container{
        display: flex;
        justify-content: center;
    }
}
</style>
