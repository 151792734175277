<script lang="ts" setup>
import { ref, computed, Ref } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { useRoute } from 'vue-router';
import { FormEntry } from '../form';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { ValidatorType, ValidatorEntry, instanceOfValidatorEntry, ValidationMode } from '.';
import properties from '../../properties';
import { FieldScript } from '@/components/forms/properties';
import { ScriptTypeEnum } from '@/modules/low-code/services/ScriptsService';

defineOptions({
    name: 'validator-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const { $t } = useLocalization();
const route = useRoute();

const entryData = ref(new ValidatorEntry()) as Ref<ValidatorEntry>;

entryData.value = props.form.document.initEntry(
    props.blueprint,
    entryData.value,
    instanceOfValidatorEntry,
    props.index
);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value;
    },
    set(value: boolean)
    {
        entryData.value.data = value;
    },
});

const blueprint = computed(() => props.blueprint);
const required = computed(() => props.form.expressions.required(props.blueprint, props.index));
const design = computed(() => props.form.designMode());
const errorMessage = computed(() => props.form.localization.translate(blueprint.value.errorMessage));
const confirmationMessage = computed(() => props.form.localization.translate(blueprint.value.confirmationMessage));
const isInformationalMode = computed(() => blueprint.value.mode == ValidationMode[ValidationMode.Informational]);
const isVisible = computed(() => design.value || entryData.value.errorMessage('value'));
const scriptType = computed(() => ScriptTypeEnum.ValidationScript);

const validationModeOptions = computed(() => [
    {
        value: ValidationMode[ValidationMode.Blocking],
        text: $t('[[[Blokująca]]]'),
    },
    {
        value: ValidationMode[ValidationMode.Informational],
        text: $t('[[[Informacyjna]]]'),
    },
]);

const moduleId = computed(() =>
{
    return route.params.moduleId.toString();
});
</script>

<template>
    <form-component-wrapper class="validator-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div v-if="isVisible" class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <ideo-form-checkbox v-if="isInformationalMode" v-model="value">
                    {{ confirmationMessage }}
                </ideo-form-checkbox>
                <small class="form-text text-danger d-block">
                    {{ errorMessage }}
                </small>
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-select
                v-model="blueprint.mode"
                :options="validationModeOptions"
                :label="$t('[[[Tryb walidacji]]]')"
                :required="true"
            />
            <FieldScript
                v-model="blueprint.validationScript"
                :label="$t('[[[Skrypt walidacyjny]]]')"
                :endpoint="`admin/forms/scripts`"
                :script-type="scriptType"
                :module-id="moduleId"
                :required="true"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-text
                    v-model="blueprint.errorMessage[locale]"
                    :label="$t('[[[Błąd walidacji]]]')"
                    :required="true"
                />
            </ideo-form-localize>
            <ideo-form-localize v-if="isInformationalMode" v-slot="{ locale }">
                <field-text
                    v-model="blueprint.confirmationMessage[locale]"
                    :label="$t('[[[Tekst potwierdzający wymuszenie dodania dokumentu]]]')"
                    :required="true"
                />
            </ideo-form-localize>
        </template>
    </form-component-wrapper>
</template>
