<script lang="ts" setup>
import { ref, Ref, computed } from 'vue';
import { DateTime } from 'luxon';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { instanceOfDateEntry, DateType, DateEntry, DateFieldTypes } from '.';
import { Option } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'date-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new DateEntry()) as Ref<DateEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfDateEntry, props.index);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value === null || (readonly.value && props.blueprint.defaultValue)
            ? props.form.expressions.executeExpression(props.blueprint.defaultValue, props.index)
            : value;
    },
    set(value: DateTime | null)
    {
        entryData.value.data = value;
    }
});

const fieldTypeOptions: Option<DateFieldTypes>[] = [
    { value: DateFieldTypes.DateTime, text: '[[[Data i czas]]]' },
    { value: DateFieldTypes.Date, text: '[[[Data]]]' }
];

const dateType = computed(() => props.blueprint.fieldType.toLowerCase());
const dateTime = computed(() => props.blueprint.fieldType === DateFieldTypes.DateTime);

const readonly = computed(() => props.form.expressions.readonly(props.blueprint, false, props.index));
const required = computed(() => props.form.expressions.required(props.blueprint, props.index));
const weekNumbers = computed(() => props.blueprint.showWeekNumbers);
</script>

<template>
    <form-component-wrapper class="date-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <ideo-datetime :type="dateType" v-model="value" :id="blueprint.id" :readonly="readonly" :week-numbers="weekNumbers" :minute-step="blueprint.minuteStep || 5" />
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-checkbox v-model="blueprint.showWeekNumbers" :label="$t('[[[Pokaż numer tygodnia na kalendarzu]]]')" />
            <field-choice v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Typ pola]]]')" />
            <field-condition v-model="blueprint.defaultValue" :form="form" :label="$t('[[[Wartość domyślna]]]')" type="date" />
            <field-period v-model="blueprint.period" :type="dateType" :label="$t('[[[Zakres czasu]]]')" />
            <field-numeric v-if="dateTime" v-model="blueprint.minuteStep" :min="1" :max="60" :label="$t('[[[Krok minut]]]')" :naturals="true" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'minuteStep')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
</style>
