<script lang="ts" setup>
import { computed, inject, Ref } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { LogitoFieldType } from '.';
import { FormEntry } from '../form';
import properties from '../../properties';
import { getAttributes } from '@/components/common/dynamic-grid/helpers/TypeOfData';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { Column, ColumnDefinitionModel } from '@/modules/core/common/services/GridService';
import { entries } from 'lodash';
import DetailsLabel from '@/components/common/DetailsLabel.vue';

// Presenters (there are in dynamic-details folder!)
import DateTimePresenter from '@/components/dynamic-details/blueprints/field/presenters/DateTime.vue';
import UserWithAvatarPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserWithAvatar.vue';
import UserWithAvatarArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserWithAvatarArray.vue';
import TextPresenter from '@/components/dynamic-details/blueprints/field/presenters/Text.vue';
import StatusPresenter from '@/components/dynamic-details/blueprints/field/presenters/StatusWithVariant.vue';
import MoneyPresenter from '@/components/dynamic-details/blueprints/field/presenters/Money.vue';
import BooleanPresenter from '@/components/dynamic-details/blueprints/field/presenters/Boolean.vue';
import GenericKeyValuePairPresenter from '@/components/dynamic-details/blueprints/field/presenters/GenericKeyValuePair.vue';
import GenericKeyValuePairArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/GenericKeyValuePairArray.vue';
import DecimalPresenter from '@/components/dynamic-details/blueprints/field/presenters/Decimal.vue';
import AddressPresenter from '@/components/dynamic-details/blueprints/field/presenters/Address.vue';
import MainFilePresenter from '@/components/dynamic-details/blueprints/field/presenters/File.vue';
import ListPresenter from '@/components/dynamic-details/blueprints/field/presenters/List.vue';
import SimplePartnerPresenter from '@/components/dynamic-details/blueprints/field/presenters/SimplePartner.vue';
import SimplePartnerArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/SimplePartnerArray.vue';
import DurationPresenter from '@/components/dynamic-details/blueprints/field/presenters/Duration.vue';
import ResourcePresenter from '@/components/dynamic-details/blueprints/field/presenters/Resource.vue';
import TablePresenter from '@/components/dynamic-details/blueprints/field/presenters/Table.vue';
import TableVatPresenter from '@/components/dynamic-details/blueprints/field/presenters/TableVat.vue';
import UserGroupArrayPresenter from '@/components/dynamic-details/blueprints/field/presenters/UserGroupArray.vue';
import ExchangeRatePresenter from '@/components/dynamic-details/blueprints/field/presenters/ExchangeRate.vue';
import DynamicDocumentPresenter from '@/components/dynamic-details/blueprints/field/presenters/DynamicDocument.vue';
import IsPrivatePresenter from '@/components/dynamic-details/blueprints/field/presenters/IsPrivate.vue';
import RichTextPresenter from '@/components/dynamic-details/blueprints/field/presenters/RichText.vue';

defineOptions({
    name: 'logito-field-blueprint',
    components: {
        DetailsLabel,
        DateTimePresenter,
        UserWithAvatarPresenter,
        UserWithAvatarArrayPresenter,
        TextPresenter,
        StatusPresenter,
        MoneyPresenter,
        BooleanPresenter,
        GenericKeyValuePairPresenter,
        DecimalPresenter,
        AddressPresenter,
        MainFilePresenter,
        ListPresenter,
        SimplePartnerPresenter,
        SimplePartnerArrayPresenter,
        DurationPresenter,
        ResourcePresenter,
        TablePresenter,
        TableVatPresenter,
        GenericKeyValuePairArrayPresenter,
        UserGroupArrayPresenter,
        ExchangeRatePresenter,
        DynamicDocumentPresenter,
        IsPrivatePresenter,
        RichTextPresenter,
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const { $t } = useLocalization();

const schema = inject<Ref<ColumnDefinitionModel>>('schema', null);
const data = inject<Ref<any>>('data', null);

const configs = computed<[string, Column][]>(() =>
{
    const f = entries(schema.value);

    return f
        .filter(
            (column) => column[1].visibility && column[0] !== 'id' && column[0] !== 'publicId' && column[0] !== 'userId'
        )
        .sort((a, b) =>
        {
            if (b[1].order < a[1].order) return 1;

            return -1;
        });
});

const design = computed(() => props.form.designMode());
const value = computed(() =>
{
    if (design.value) return null;

    if (!data.value) return null;

    const value = data.value[blueprint.value.name];

    return value;
});

const config = computed(() =>
{
    if (!configs.value || design.value) return null;

    const config = configs.value.find((config) => config[0] === blueprint.value.name);

    return config;
});

const blueprint = computed(() => props.blueprint);
const label = computed(() => props.form.localization.translate(blueprint.value.headerName));
const isFormat = computed(() => blueprint.value.format === WhenChoice.When ? props.form.expressions.executeExpression(blueprint.value.formatWhen) : true);
const tooltip = computed(() =>
    blueprint.value.tooltip && isFormat.value ? props.form.localization.translate(blueprint.value.tooltip) : ''
);
const boldLabel = computed(() => isFormat.value && blueprint.value.boldLabel);
const boldValue = computed(() => isFormat.value && blueprint.value.boldValue);
const canCopy = computed(() => blueprint.value.canCopy);
const colorLabel = computed(() => (isFormat.value ? blueprint.value.colorLabel : ''));
const colorValue = computed(() => (isFormat.value ? blueprint.value.colorValue : ''));
const icon = computed(() => (isFormat.value ? blueprint.value.icon : ''));
const showLabelAboveValue = computed(() => isFormat.value && blueprint.value.showLabelAboveValue);
const isLabelCustomWidth = computed(() => isFormat.value && blueprint.value.isLabelCustomWidth);
const labelWidth = computed(() => isFormat.value && blueprint.value.labelWidth);

const settings = computed(() => ({
    boldLabel: boldLabel.value,
    boldValue: boldValue.value,
    tooltip: !canCopy.value ? tooltip.value : '',
    colorLabel: colorLabel.value,
    colorValue: colorValue.value,
    icon: icon.value,
    showLabelAboveValue: showLabelAboveValue.value,
    isLabelCustomWidth: isLabelCustomWidth.value,
    labelWidth: labelWidth.value,
}));

const isIconCodeProper = computed(() =>
{
    const iconRegex = new RegExp('^fa[a-z-\\s]+$');

    return iconRegex.test(blueprint.value.icon);
});

const typeOfData = (value: any, config: [string, Column]) =>
{
    const [itemKey, headerOptions] = config;
    const property = value;
    const type = headerOptions.type.baseType;
    const features = headerOptions.type.features;

    const attributes = {
        property,
        features,
        ...getAttributes(type, {
            schema: schema.value,
            itemKey,
            property,
            canCopy: canCopy.value,
        }),
    };

    return attributes;
};
</script>

<template>
    <form-component-wrapper class="logito-field-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <details-label :label="label" :settings="settings" class="mb-2">
                <template #default>
                    <component
                        v-if="!design && config"
                        :is="typeOfData(value, config).is"
                        v-bind="{ ...typeOfData(value, config) }"
                    />
                    <span v-else>-</span>
                </template>
            </details-label>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.headerName[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <div class="alert alert-light p-2 pb-0">
                <h6>{{ $t('[[[Formatowanie]]]') }}</h6>
                <hr class="mt-1 mb-2" />
                <field-format :form="form" :blueprint="blueprint" />
                <field-checkbox v-model="blueprint.boldLabel" :label="$t('[[[Pogrubienie etykiety]]]')" />
                <field-checkbox v-model="blueprint.boldValue" :label="$t('[[[Pogrubienie wartości]]]')" />
                <ideo-form-group :label="$t('[[[Kolor etykiety]]]')">
                    <input v-model="blueprint.colorLabel" type="color" class="d-block w-100" />
                </ideo-form-group>
                <ideo-form-group :label="$t('[[[Kolor wartości]]]')">
                    <input v-model="blueprint.colorValue" type="color" class="d-block w-100" />
                </ideo-form-group>
                <field-checkbox
                    v-model="blueprint.showLabelAboveValue"
                    :label="$t('[[[Pokaż etykietę nad wartością]]]')"
                />
                <field-checkbox
                    v-model="blueprint.isLabelCustomWidth"
                    :label="$t('[[[Szerokość niestandardowa etykiety]]]')"
                />
                <field-numeric
                    v-if="blueprint.isLabelCustomWidth"
                    v-model="blueprint.labelWidth"
                    :label="$t('[[[Szerokość dla etykiety]]]')"
                    append="px"
                    :min="0"
                    :max="1000"
                />
                <ideo-form-localize v-slot="{ locale }">
                    <field-text
                        v-model="blueprint.tooltip[locale]"
                        :label="$t('[[[Tooltip]]]')"
                        class="flex-fill"
                    />
                </ideo-form-localize>
                <ideo-form-group :label="$t('[[[Ikona]]]')">
                    <template #default>
                        <div class="d-flex align-items-center">
                            <ideo-form-input
                                v-model="blueprint.icon"
                                type="text"
                                name="icon"
                                class="flex-fill"
                            />
                            <div class="d-flex justify-content-center">
                                <i
                                    v-if="isIconCodeProper"
                                    :class="blueprint.icon"
                                    class="ms-2"
                                    style="font-size: 20px"
                                />
                            </div>
                        </div>
                    </template>
                    <template #description>
                        {{ $t('[[[Wpisz kod ikony Font Awesome, aby zobaczyć podgląd]]]') }}
                    </template>
                </ideo-form-group>
            </div>
        </template>
    </form-component-wrapper>
</template>
