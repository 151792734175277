<script lang="ts" setup>
import { ref, computed } from 'vue';
import { usePermissions } from '@/plugins/permissions';
import { computedAsync } from '@vueuse/core';
import ScriptForm from '@/modules/low-code/views/administration/scripts/Form.vue';
import { useEvents } from '@/plugins/events';
import { ScriptTypeEnum } from '@/modules/low-code/services/ScriptsService';
import Permissions from '@/settings/permissions';

const model = defineModel<any>();

const props = defineProps({
  "showLabel": { type: Boolean, default: false },
  "label": { default: '' },
  "endpoint": null,
  "documentType": { default: null },
  "moduleId": { default: null },
  "scriptType": null,
  "required": { type: Boolean, default: false },
  "customClass": { default: '' },
  "invalidFeedback": { type: Function, default: null }
});

const { $permissions } = usePermissions();
const modal = ref(null);
const errorMessage = ref<string>(null);
const { $events } = useEvents();
const scriptsReloadKey = ref(0);
const scriptPublicId = ref('');

const isDebugMode = computed(() =>
{
    return model.value?.key && process.env.NODE_ENV !== "production";
});

const icon = computed(() =>
{
    return model.value ? 'fa fa-edit' : 'fa fa-plus';
});

const iconVariant = computed(() =>
{
    return model.value ? 'btn-primary' : 'btn-success';
});

const canAdd = computedAsync(async () => await $permissions.all([Permissions.Logito.LowCode.ScriptsConfig]));

const openEditor = async () =>
{
    modal.value.show();
};

const saveExpression = (value: boolean) =>
{
    $events.$emit('saveForm', value);
};

const closeEditor = () =>
{
    modal.value.hide();
};

const init = async () =>
{

};

const debugMode = async (eventName: string) =>
{
    $events.$emit(eventName);
};

const scriptSaved = (form: any, publicId: string) =>
{
    scriptPublicId.value = publicId;
    scriptsReloadKey.value++;

    closeEditor();
};

init();
</script>

<template>
    <div class="form-group">
        <IdeoFormGroup
            :label="showLabel ? label : ''"
            :required="required"
            :class="customClass"
        >
            <div style="display: flex;">
                <IdeoSelect
                    v-model="model"
                    :placeholder="label"
                    :endpoint="endpoint"
                    :style="{width: canAdd ? 'calc(100% - 36px)' : '100%'}"
                    :reload-key="scriptsReloadKey"
                    :reload-value="scriptPublicId"
                    :reload-option-key="'key'"
                    :narrow-options="{
                        moduleId: moduleId ?? '',
                        scriptType: scriptType
                    }"
                />
                <span class="btn" :class="iconVariant" @click="openEditor" v-if="canAdd">
                    <i :class="icon"></i>
                </span>
            </div>
            <small class="form-text text-danger" v-if="invalidFeedback && invalidFeedback(model)">
                {{ $t(invalidFeedback(model)) }}
            </small>
        </IdeoFormGroup>
        <small class="form-text text-danger" v-if="errorMessage">
            {{ $t(errorMessage) }}
        </small>
        <ideo-modal ref="modal" :title="'Skrypt'" size="xxl" centered scrollable>
            <template #default>
                <ScriptForm
                    :is-modal-mode="true"
                    :public-id="model?.key ?? null"
                    :document-type="documentType"
                    :module-id="moduleId ?? null"
                    :script-type="scriptType"
                    @scriptSaved="scriptSaved"
                    @scriptClosed="closeEditor"
                >
                </scriptform>
            </template>
            <template #modal-footer>
                <template v-if="isDebugMode">
                    <button class="btn btn-danger" @click.stop.prevent="debugMode('debugOn')">
                        {{ $t('[[[DEBUG ON]]]') }}
                    </button>
                    <button class="btn btn-danger" @click.stop.prevent="debugMode('debugOff')">
                        {{ $t('[[[DEBUG OFF]]]') }}
                    </button>
                </template>
                <button class="btn btn-success" @click.stop.prevent="saveExpression(false)">
                    {{ $t('[[[Zapisz]]]') }}
                </button>
                <button v-if="model" class="btn btn-primary" @click.stop.prevent="saveExpression(true)">
                    {{ $t('[[[Zapisz nową wersję]]]') }}
                </button>
                <ideo-button variant="secondary" @click.stop.prevent="closeEditor">
                    {{ $t('[[[Anuluj]]]') }}
                </ideo-button>
            </template>
        </ideo-modal>
    </div>
</template>
