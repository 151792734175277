<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useMixins } from '@/plugins/mixins';
import { useResizeObserver } from '@vueuse/core';
import { KeyValuePair } from '@/helpers/Interfaces';
import { getVariantColor } from '@/components/common/dynamic-grid/helpers/statusColorHelper';

const props = defineProps({
  "property": { default: '' },
  "features": { default: (): KeyValuePair[] => [] },
  "isOnGrid": { type: Boolean, default: false }
});

const { $filters } = useMixins();

const el = ref<HTMLSpanElement>(null);
const isTextClamped = ref(false);

const isBadge = computed(() => props.features.some((feature) => feature.key === 'Badge'));
const getVariant = computed(() =>
{
    const type = props.features.find((elem) => elem.key === 'Badge');
    let func = '';

    if (type) func = type.value;

    return getVariantColor(props.property, func);
});

const value = computed(() => $filters.dashIfEmpty(props.property));

const checkIfClamped = () =>
{
    if (el.value)
    {
        const element = el.value;

        isTextClamped.value = element.scrollHeight > element.clientHeight;
    }
};

useResizeObserver(el, checkIfClamped);
</script>

<template>
    <div class="d-flex">
        <template v-if="!isBadge">
            <template v-if="isOnGrid">
                <ideo-tooltip v-if="isTextClamped" :tooltip="value" multiline position="bottom">
                    <span ref="el" class="property-value-2-lines">{{ value }}</span>
                </ideo-tooltip>
                <span v-else ref="el" class="property-value-2-lines">{{ value }}</span>
            </template>
            <span v-else>{{ value }}</span>
        </template>
        <ideo-badge v-else :variant="getVariant" class="status-badge" :title="property">
            {{ property }}
        </ideo-badge>
    </div>
</template>

<style scoped>
.status-badge {
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
