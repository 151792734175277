<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import { Codemirror } from 'vue-codemirror';
import { basicSetup } from 'codemirror';
import { javascript as jsLang } from '@codemirror/lang-javascript';
import { json as jsonLang } from '@codemirror/lang-json';
import { csharp as csharpLang } from '@replit/codemirror-lang-csharp';
import ScriptsService, { ScriptEngineEnum, ScriptRoleEnum } from '@/modules/low-code/services/ScriptsService';

@Options({
    components: {
        Codemirror
    },
    emits: ['saveForm', 'update-form-field']
})
export default class SourceScripts extends Vue
{
    @Prop({ default: null }) public form!: any;
    @Prop({ default: [] }) public scriptEngines!: any[];
    @Prop() public templateDataUuid!: string;

    public scriptEnginesReloadKey: number = 0;

    private isResizing = false;
    private resizingEl: HTMLElement | null = null;
    private startY = 0;
    private startHeight = 0;
    private linkedScripts: string[] = [];

    public get isSharedScript(): boolean
    {
        return this.form.scriptRole?.key == ScriptRoleEnum.SharedScript;
    }

    public get extensionsJs(): any[]
    {
        return [jsLang()];
    }

    public get extensionsJson(): any[]
    {
        return [jsonLang()];
    }

    public get extensionsCsharp(): any[]
    {
        return [basicSetup, csharpLang()];
    }

    public get extensions(): any[]
    {
        if (this.form?.scriptEngine?.key === ScriptEngineEnum.CSharpEngine)
        {
            return this.extensionsCsharp;
        }

        return this.extensionsJs;
    }

    public beforeUnmount(): void
    {
        this.removeGlobalListeners();
    }

    // Wartości inicjalne dla pól skryptów
    private initialBaseScript: string = "";
    private initialSourceScripts: string = "";
    private initialSharedScript: string = "";
    private initialAdditionalScript: string = "";
    private initialScriptConfig: string = "";

    // Wartości tymczasowe dla pól skryptów
    private temporaryBaseScript: string = "";
    private temporarySourceScripts: string = "";
    private temporarySharedScript: string = "";
    private temporaryAdditionalScript: string = "";
    private temporaryScriptConfig: string = "";

    @Watch('form.scriptType')
    @Watch('form.scriptRole')
    public async onScriptTypeChanged(): Promise<void>
    {
        if (this.scriptEnginesReloadKey > 0)
        {
            if (
                !this.scriptEngines ||
                this.scriptEngines.length === 0 ||
                !this.scriptEngines.some(
                    p => p.key === (this.form?.scriptEngine?.key ?? '')
                )
            )
            {
                this.form.scriptEngine = null;
            }
        }

        this.scriptEnginesReloadKey++;
    }

    @Watch('form.linkedScripts')
    public async onlinkedScriptsChanged(newValue: { key: string, value: string }[]): Promise<void>
    {
        if (newValue && !newValue.length) return;

        this.linkedScripts = [];

        newValue.forEach(async (linkedScript) =>
        {
            try
            {
                const result = (await ScriptsService.getDetails(linkedScript.key)).result;

                if (result)
                {
                    this.linkedScripts.push(result.sharedScript);
                }
            }
            catch (ex)
            {
                this.$log.debug(ex);
            }
        });
    }

    @Watch('templateDataUuid')
    public onTemplateDataUuidChanged(): void
    {
        this.initialBaseScript = this.form.baseScript;
        this.initialSourceScripts = this.form.sourceScripts;
        this.initialSharedScript = this.form.sharedScript;
        this.initialAdditionalScript = this.form.additionalScript;
        this.initialScriptConfig = this.form.scriptConfig;

        this.temporaryBaseScript = this.form.baseScript;
        this.temporarySourceScripts = this.form.sourceScripts;
        this.temporarySharedScript = this.form.sharedScript;
        this.temporaryAdditionalScript = this.form.additionalScript;
        this.temporaryScriptConfig = this.form.scriptConfig;
    }

    public onMouseDown(e: MouseEvent): void
    {
        const targetEl = e.currentTarget as HTMLElement;
        const rect = targetEl.getBoundingClientRect();
        const offsetFromBottom = rect.bottom - e.clientY;

        if (offsetFromBottom <= 5 && offsetFromBottom >= 0)
        {
            this.isResizing = true;
            this.resizingEl = targetEl;
            this.startY = e.clientY;
            this.startHeight = targetEl.offsetHeight;

            window.addEventListener('mousemove', this.onMouseMove);
            window.addEventListener('mouseup', this.onMouseUp);
        }
    }

    private onMouseMove = (e: MouseEvent): void =>
    {
        if (!this.isResizing || !this.resizingEl) return;

        const dy = e.clientY - this.startY;
        let newHeight = this.startHeight + dy;

        const MIN_HEIGHT = 100;

        if (newHeight < MIN_HEIGHT)
        {
            newHeight = MIN_HEIGHT;
        }

        const gutterElements = this.resizingEl.querySelectorAll('.cm-lineNumbers .cm-gutterElement');

        if (gutterElements.length > 0)
        {
            const maxHeight = (gutterElements.length * 18.1875) - 7;

            if (newHeight > maxHeight)
            {
                newHeight = maxHeight;
            }
        }

        this.resizingEl.style.height = `${newHeight}px`;
    };

    private onMouseUp = (): void =>
    {
        this.isResizing = false;
        this.resizingEl = null;
        this.removeGlobalListeners();
    };

    private removeGlobalListeners(): void
    {
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.onMouseUp);
    }

    public get isSetTemplate(): boolean
    {
        return this.form.template;
    }

    // Właściwości zwiazane z bindowaniem stanu skryptu
    public get overrideBaseScript()
    {
        return this.form.baseScript;
    }

    public set overrideBaseScript(value: string)
    {
        if (this.overrideBaseScriptCheckbox)
        {
            this.temporaryBaseScript = value;
        }

        this.$emit('update-form-field', { key: 'baseScript', value });
    }

    public get overrideSourceScripts()
    {
        return this.form.sourceScripts;
    }

    public set overrideSourceScripts(value: string)
    {
        if (this.overrideSourceScriptsCheckbox)
        {
            this.temporaryBaseScript = value;
        }

        this.$emit('update-form-field', { key: 'sourceScripts', value });
    }

    public get overrideSharedScript()
    {
        return this.form.sharedScript;
    }

    public set overrideSharedScript(value: string)
    {
        if (this.overrideSharedScriptCheckbox)
        {
            this.temporarySharedScript = value;
        }

        this.$emit('update-form-field', { key: 'sharedScript', value });
    }

    public get overrideAdditionalScript()
    {
        return this.form.additionalScript;
    }

    public set overrideAdditionalScript(value: string)
    {
        if (this.overrideAdditionalScriptCheckbox)
        {
            this.temporaryAdditionalScript = value;
        }

        this.$emit('update-form-field', { key: 'additionalScript', value });
    }

    public get overrideScriptConfig()
    {
        return this.form.scriptConfig;
    }

    public set overrideScriptConfig(value: string)
    {
        if (this.overrideScriptConfigCheckbox)
        {
            this.temporaryScriptConfig = value;
        }

        this.$emit('update-form-field', { key: 'scriptConfig', value });
    }

    // Własciwości zwiazane z bindowaniem checkboxa
    public get overrideBaseScriptCheckbox()
    {
        return this.form.overrideBaseScript;
    }

    public set overrideBaseScriptCheckbox(value: boolean)
    {
        this.$emit('update-form-field', { key: 'overrideBaseScript', value });

        if (value)
        {
            this.overrideBaseScript = this.temporaryBaseScript;
        }
        else
        {
            this.overrideBaseScript = this.initialBaseScript;
        }
    }

    public get overrideSourceScriptsCheckbox()
    {
        return this.form.overrideSourceScripts;
    }

    public set overrideSourceScriptsCheckbox(value: boolean)
    {
        this.$emit('update-form-field', { key: 'overrideSourceScripts', value });

        if (value)
        {
            this.overrideSourceScripts = this.temporarySourceScripts;
        }
        else
        {
            this.overrideSourceScripts = this.initialSourceScripts;
        }
    }

    public get overrideSharedScriptCheckbox()
    {
        return this.form.overrideSharedScript;
    }

    public set overrideSharedScriptCheckbox(value: boolean)
    {
        this.$emit('update-form-field', { key: 'overrideSharedScript', value });

        if (value)
        {
            this.overrideSharedScript = this.temporarySharedScript;
        }
        else
        {
            this.overrideSharedScript = this.initialSharedScript;
        }
    }

    public get overrideAdditionalScriptCheckbox()
    {
        return this.form.overrideAdditionalScript;
    }

    public set overrideAdditionalScriptCheckbox(value: boolean)
    {
        this.$emit('update-form-field', { key: 'overrideAdditionalScript', value });

        if (value)
        {
            this.overrideAdditionalScript = this.temporaryAdditionalScript;
        }
        else
        {
            this.overrideAdditionalScript = this.initialAdditionalScript;
        }
    }

    public get overrideScriptConfigCheckbox()
    {
        return this.form.overrideScriptConfig;
    }

    public set overrideScriptConfigCheckbox(value: boolean)
    {
        this.$emit('update-form-field', { key: 'overrideScriptConfig', value });

        if (value)
        {
            this.overrideScriptConfig = this.temporaryScriptConfig;
        }
        else
        {
            this.overrideScriptConfig = this.initialScriptConfig;
        }
    }
}
</script>

<template>
    <IdeoFormGroup
        :label="$t('[[[Silnik skryptowy]]]')"
        :invalid-feedback="form.$errors.first('scriptEngine')"
        :state="form.$errors.state('scriptEngine')"
        required
    >
        <IdeoSelect
            v-model="form.scriptEngine"
            :options="scriptEngines"
            :reload-key="scriptEnginesReloadKey"
            :disabled="form.isSystemScript"
            @update:modelValue="form.$errors.clear('scriptEngine');"
        />
        <small v-if="!scriptEngines || scriptEngines.length == 0">{{ $t('[[[Jeśli lista silników jest pusta, sprawdź czy został wybrany typ skryptu]]]') }}</small>
    </IdeoFormGroup>
    <template v-if="form.scriptEngine">
        <IdeoAccordion v-if="!isSharedScript">
            <IdeoAccordionItem>
                <template #header>
                    <div class="accordion-heading d-flex justify-content-between align-items-center">
                        {{ $t('[[[Konfiguracja]]]') }}
                        <IdeoFormCheckbox
                            v-if="isSetTemplate"
                            v-model="overrideScriptConfigCheckbox"
                            :disabled="form.isSystemScript"
                            @click.stop
                        >
                            {{ $t('[[[Nadpisz konfigurację]]]') }}
                        </IdeoFormCheckbox>
                    </div>
                </template>
                <IdeoFormGroup
                    :invalid-feedback="form.$errors.first('scriptConfig')"
                    :state="form.$errors.state('scriptConfig')"
                    @keydown.ctrl.s.prevent.stop="$emit('saveForm')"
                >
                    <div class="border rounded-1 accordion-resizer" @mousedown="onMouseDown">
                        <Codemirror
                            v-model="overrideScriptConfig"
                            :style="{ minHeight: '300px;' }"
                            :autofocus="true"
                            :indent-with-tab="false"
                            :tab-size="4"
                            :extensions="extensionsJson"
                            :disabled="form.isSystemScript || (isSetTemplate && !overrideScriptConfigCheckbox)"
                            @update:modelValue="() => { form.$errors.clear('scriptConfig'); form.$errors.clear('baseScript'); form.$errors.clear('additionalScript'); }"
                        />
                    </div>
                    <small>{{ $t('[[[Konfiguracja dla stałych wartości, których chcemy używać w skrypcie]]]') }}</small><br />
                    <small>{{ $t('[[[Dane z tego pola są przechowywane w bazie w szyfrowanej formie]]]') }}</small>
                </IdeoFormGroup>
            </IdeoAccordionItem>
        </IdeoAccordion>
        <IdeoAccordion>
            <IdeoAccordionItem>
                <template #header>
                    <div class="accordion-heading d-flex justify-content-between align-items-center">
                        {{ $t('[[[Skrypt współdzielony]]]') }}
                        <IdeoFormCheckbox
                            v-if="isSetTemplate"
                            v-model="overrideSharedScriptCheckbox"
                            :disabled="form.isSystemScript"
                            @click.stop
                        >
                            {{ $t('[[[Nadpisz skrypt współdzielony]]]') }}
                        </IdeoFormCheckbox>
                    </div>
                </template>
                <IdeoFormGroup
                    :invalid-feedback="form.$errors.first('sharedScript')"
                    :state="form.$errors.state('sharedScript')"
                    @keydown.ctrl.s.prevent.stop="$emit('saveForm')"
                >
                    <div class="border rounded-1 accordion-resizer" @mousedown="onMouseDown">
                        <template v-if="linkedScripts.length > 0">
                            <Codemirror
                                v-for="(linkedScript, index) in linkedScripts"
                                :key="index"
                                :model-value="linkedScript"
                                :value="linkedScript"
                                :style="{ height: 'max-content' }"
                                disabled
                            />
                            <hr>
                        </template>

                        <Codemirror
                            v-model="overrideSharedScript"
                            :style="{ minHeight: '300px;' }"
                            :autofocus="true"
                            :indent-with-tab="false"
                            :tab-size="4"
                            :extensions="extensions"
                            :disabled="form.isSystemScript || (isSetTemplate && !overrideSharedScriptCheckbox)"
                            @update:modelValue="() => { form.$errors.clear('sharedScript'); form.$errors.clear('baseScript'); form.$errors.clear('additionalScript'); }"
                        />
                    </div>
                    <small style="display: block;">{{ $t('[[[Implementacja metod/zmiennych/stałych, których chcemy używać w skrypcie]]]') }}</small>
                </IdeoFormGroup>
            </IdeoAccordionItem>
        </IdeoAccordion>
        <IdeoAccordion v-if="!isSharedScript">
            <IdeoAccordionItem :initial-open="true">
                <template #header>
                    <div class="accordion-heading d-flex justify-content-between align-items-center">
                        {{ $t('[[[Skrypt]]]') }}
                        <IdeoFormCheckbox
                            v-if="isSetTemplate"
                            v-model="overrideBaseScriptCheckbox"
                            :disabled="form.isSystemScript"
                            @click.stop
                        >
                            {{ $t('[[[Nadpisz skrypt]]]') }}
                        </IdeoFormCheckbox>
                    </div>
                </template>
                <IdeoFormGroup
                    :invalid-feedback="form.$errors.first('baseScript')"
                    :state="form.$errors.state('baseScript')"
                    @keydown.ctrl.s.prevent.stop="$emit('saveForm')"
                >
                    <div
                        class="border rounded-1 accordion-resizer" @mousedown="onMouseDown"
                    >
                        <Codemirror
                            v-model="overrideBaseScript"
                            :style="{ minHeight: '300px;' }"
                            :autofocus="true"
                            :indent-with-tab="false"
                            :tab-size="4"
                            :extensions="extensions"
                            :disabled="form.isSystemScript || (isSetTemplate && !overrideBaseScriptCheckbox)"
                            @update:modelValue="form.$errors.clear('baseScript');"
                        />
                    </div>
                </IdeoFormGroup>
            </IdeoAccordionItem>
        </IdeoAccordion>
        <IdeoAccordion v-if="form.scriptType && form.scriptType.key == 'DataSource'">
            <IdeoAccordionItem>
                <template #header>
                    <div class="accordion-heading d-flex justify-content-between align-items-center">
                        {{ $t('[[[Skrypt po przetworzeniu dokumentu]]]') }}
                        <IdeoFormCheckbox v-if="isSetTemplate" v-model="overrideAdditionalScriptCheckbox" @click.stop>{{ $t('[[[Nadpisz skrypt po przetworzeniu dokumentu]]]') }}</IdeoFormCheckbox>
                    </div>
                </template>
                <IdeoFormGroup
                    :label="$t('[[[Skrypt po przetworzeniu dokumentu]]]')"
                    :invalid-feedback="form.$errors.first('additionalScript')"
                    :state="form.$errors.state('additionalScript')"
                    @keydown.ctrl.s.prevent.stop="$emit('saveForm')"
                >
                    <div class="border rounded-1 accordion-resizer" @mousedown="onMouseDown">
                        <Codemirror
                            v-model="overrideAdditionalScript"
                            :style="{ minHeight: '300px;' }"
                            :autofocus="true"
                            :indent-with-tab="false"
                            :tab-size="4"
                            :extensions="extensions"
                            :disabled="form.isSystemScript || !overrideAdditionalScriptCheckbox"
                            @update:modelValue="form.$errors.clear('additionalScript');"
                        />
                    </div>
                </IdeoFormGroup>
            </IdeoAccordionItem>
        </IdeoAccordion>
    </template>
</template>

<style scoped>
.accordion-resizer {
    position: relative;
    overflow: hidden auto;
    min-height: 300px;
}

.accordion-resizer::after {
    content: '';
        position: sticky;
        display: flex;
        width: 100%;
        bottom: 0;
        height: 2px;
        cursor: row-resize;
}

.accordion-resizer::-webkit-scrollbar {
    display: none;
}

:deep(span:has(.accordion-heading)) {
    width: 100%;
}

:deep(.accordion-heading .form-check) {
    flex-direction: row-reverse;
    padding-right: 10px;
}

:deep(.accordion-heading label) {
    pointer-events: none;
}
</style>
