import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { VisibleBlueprint } from '@/components/builder/form/blueprints/VisibleBlueprint';
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { AlwaysChoice } from '@/components/builder/form/enums/AlwaysChoice';
import { InternallyChoice } from '@/components/builder/form/enums/InternallyChoice';
import { NeverChoice } from '@/components/builder/form/enums/NeverChoice';
import { WhenChoice } from '@/components/builder/form/enums/WhenChoice';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasHelp } from '@/components/builder/form/traits/HasHelp';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';
import { KeyValuePair } from '@/helpers/Interfaces';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';

export const Definition: BlueprintDefinition = {
    type: 'dynamic-modal',
    name: '[[[Dynamiczny modal]]]',
    icon: 'fa-solid fa-window-restore',
    group: 'logito',
    position: 15,
};

export interface DynamicModalContract
    extends Blueprint,
    VisibleBlueprint,
    HasLabel,
    HasWidth,
    HasHelp
{
    icon: string;
    showIcon: boolean;
    buttonStyle: KeyValuePair;
    modalMode: string;
    script: KeyValuePair;
    formTitle: Record<string, string>;
    dynamicForm: KeyValuePair;
    dynamicActions: DynamicActionType[];
}

export class DynamicModalEntry extends ValidEntry<any>
{
    public type: string = Definition.type;
    public data: any = null;

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(
        blueprint: DynamicModalContract,
        form: FormBuilderContract,
        preprocess: ProcessCallback
    ): Promise<Entry>
    {
        await preprocess(blueprint, this, form.blueprintId, form.entryId);

        const data = this.data;

        return entry(data);
    }

    public validate(blueprint: DynamicModalContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        return this.valid();
    }
}

export const instaceOfDynamicModalEntry = (object: any): object is DynamicModalEntry =>
{
    return object && 'type' in object && object.type === Definition.type;
};

export class DynamicModalType
implements DynamicModalContract, EntryFactory<DynamicModalEntry>, ValidatableBlueprint
{
    public id: string;
    public type: string;
    public name: string;
    public label: Record<string, string>;
    public showLabel: boolean;
    public width: number;
    public minWidth: number;
    public help: Record<string, string>;
    public visible: AlwaysChoice | NeverChoice | InternallyChoice | WhenChoice;
    public visibleWhen: string;
    public errors: ValidationErrors;

    public icon: string;
    public showIcon: boolean;
    public buttonStyle: KeyValuePair;
    public modalMode: string;
    public script: KeyValuePair;
    public formTitle: Record<string, string>;
    public dynamicForm: KeyValuePair;
    public dynamicActions: DynamicActionType[];

    public constructor(id: string, name: string)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.width = 0;
        this.minWidth = 1;
        this.showLabel = true;
        this.label = { 'pl-PL': 'Modal' };
        this.help = {};
        this.visible = AlwaysChoice.Always;
        this.visibleWhen = null;
        this.errors = {};

        this.icon = '';
        this.showIcon = false;
        this.buttonStyle = { key: 'Success', value: 'Success' };
        this.modalMode = 'Modal';
        this.script = null;
        this.formTitle = { 'pl-PL': '' };
        this.dynamicForm = null;
        this.dynamicActions = [];
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(): DynamicModalEntry
    {
        return new DynamicModalEntry();
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (!this.modalMode) this.errors.modalMode = ["[[[Wymagane jest wybranie trybu]]]"];

        if (this.modalMode == 'Button')
            if (!this.script) this.errors.script = ["[[[Wymagane jest wybranie skryptu]]]"];

        return {
            [this.name]: this.errors,
        };
    }
}

export class DynamicActionType
{
    public key: string;
    public name: Record<string, string>;
    public buttonStyle: KeyValuePair;
    public script: KeyValuePair;
    public closingModal: string;
}
