<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from '@/helpers/Decorators';
import { Form } from '@/helpers/Form';
import Pager from '@/helpers/Pager';
import { RouteLocation } from 'vue-router';
import { APIWidget, SourceConfigList, Widget } from '@/modules/core/home/services/HomeService';
import { ColumnDefinitionModel } from '@/modules/core/common/services/GridService';

type ActionName = 'active' | 'my' | 'search' | null;

@Options({
    name: 'ListWidget',
})
export default class ListWidget extends Vue
{
    @Prop({ default: () => ({}) })
    public config: APIWidget;

    @Prop({ default: () => ({}) })
    public widgetConfig: Widget;

    @Prop({ default: () => ('') })
    public emitId: string;

    @Prop({ default: () => (Form.create({})) })
    public filter: any;

    public selectedGuids: string[] = [];

    public license: string = '';
    public actionName: ActionName = null;
    public pager: Pager | null = null;
    public widgetFilter = Form.create({});
    public customColumns: ColumnDefinitionModel | null = null;

    public get endpoint(): string
    {
        return `${this.license}/${this.actionName}`;
    }

    public get pagesLimit(): number
    {
        const result = this.widgetConfig.w <= 4 ? 1 : this.widgetConfig.w - 4 + 1;

        return result > 5 ? 5 : result;
    }

    public get showPager(): boolean
    {
        return this.config.showPager;
    }

    public get pageSize(): number
    {
        const sourceConfig = this.config?.sourceConfig as SourceConfigList;

        return sourceConfig?.pageSize ?? 5;
    }

    public get showFilters(): boolean
    {
        return this.config.showFilters;
    }

    public created(): void
    {
        this.license = this.config.licenseUrl;
        this.actionName = this.config.actionUrl as ActionName;

        const sourceConfig = this.config?.sourceConfig as SourceConfigList;
        const cols = JSON.parse(sourceConfig.columns);
        const sortFieldName = sourceConfig.sortField
            ? cols[sourceConfig.sortField.key]?.sort
            : sourceConfig.sortField?.key;
        const sortDirection = sourceConfig.sortDirection ?
            sourceConfig.sortDirection.key === 'Ascending' ? 'ASC' : 'DESC' :
            'DESC';

        this.pager = new Pager(1, sourceConfig.pageSize ?? 5, sortFieldName ?? 'Id', sortDirection);
        this.customColumns = cols;

        const filter = JSON.parse(sourceConfig.filter ?? '{}');

        this.widgetFilter.setInitialValues(filter);
        this.widgetFilter.withData(filter);
    }

    public routeData(item: any): RouteLocation & { href: string }
    {
        const route = this.$router.resolve(
            `/${this.config.additionalData.detailsUrl.replace('{publicId}', item.result.publicId)}`
        );

        return route;
    }

    public onRowClick(item: any): void
    {
        this.redirect({ name: this.routeData(item).name, params: this.routeData(item).params });
    }

    public onScrollClick(item: any): void
    {
        this.scrollRedirect({ name: this.routeData(item).name, params: this.routeData(item).params });
    }

    public fetchData(): void
    {
        if (this.emitId)
            this.$events.$emit(this.emitId);
        else
            this.$events.$emit('refetchData');
    }

    public checkItems(value: {value: boolean, guids: string[]}): any
    {
        this.selectedGuids = value ? value.guids : [];
    }

    public resetGuids(): void
    {
        this.selectedGuids = [];
    }

    @Watch('selectedGuids')
    public onSelectedGuidsChanged(): void
    {
        this.$emit('onSelectedGuidsChanged', this.selectedGuids);
    }
}
</script>

<template>
    <div>
        <dynamic-grid
            v-if="license"
            :pager="pager"
            :filter="showFilters ? filter : widgetFilter"
            :endpoint="endpoint"
            :row-click="onRowClick"
            :scroll-click="onScrollClick"
            :columns="customColumns"
            :sitemap-id="widgetConfig.config?.sitemapEntryDetails?.publicId ?? ''"
            disable-footer
            manual-init
            :emit-id="emitId"
            :disable-column-resize="true"
            @fetch-data="resetGuids"
            @check="checkItems"
        >
            <template #checkbox="{ item }">
                <ideo-form-checkbox v-model="selectedGuids" :value="item.result.publicId" nospace />
            </template>

            <template #actions="{ item }">
                <row-actions
                    :endpoint="config.additionalData.actionsUrl.replace('{publicId}', item.result.publicId)"
                    :url="license"
                    :id="item.result.publicId"
                    :except="[
                        'document-notifications-create',
                        'canManageParticipants',
                        'canUploadOneDrive',
                        'bpmn-set-targets',
                        'canSetPrivate',
                        'canEditSimple'
                    ]"
                >
                    <ideo-dropdown-item-button v-if="config.additionalData?.detailsUrl" @click="onRowClick(item)">
                        <i class="fas fa-square-list"></i>
                        <span>{{ $t('[[[Szczegóły]]]') }}</span>
                    </ideo-dropdown-item-button>
                </row-actions>
            </template>
        </dynamic-grid>
        <portal :to="`widget-footer-${config.publicId}`">
            <pagination :pager="pager" :pages-limit="pagesLimit" @change="fetchData()" v-if="showPager" />
            <div class="d-flex align-items-center">
                <pagination-info :pager="pager" class="ms-2" />
                <data-size :pager="pager" class="ms-2" direct-top="true" :custom-page-size="pageSize" @change="fetchData()" v-if="showPager" />
            </div>
        </portal>
    </div>
</template>
