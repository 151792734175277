<script lang="ts" setup>
import { ref, Ref, computed } from 'vue';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { instanceOfRichTextEntry, RichTextType, RichTextEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'rich-text-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new RichTextEntry()) as Ref<RichTextEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfRichTextEntry, props.index);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value === null || (readonly.value && props.blueprint.defaultValue)
            ? props.form.expressions.executeExpression(props.blueprint.defaultValue, props.index)
            : value;
    },
    set(value: string)
    {
        entryData.value.data = value;
    }
});

const readonly = computed(() => props.form.expressions.readonly(props.blueprint, false, props.index));
const required = computed(() => props.form.expressions.required(props.blueprint, props.index));
</script>

<template>
    <form-component-wrapper class="text-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <editor v-model="value" :id="`editor-${blueprint.id}${form.designMode() ? '-design': ''}`" :disabled="readonly" />
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-condition v-model="blueprint.defaultValue" :form="form" :label="$t('[[[Wartość domyślna]]]')" type="text" />
            <field-minmax v-model="blueprint.characters" :label="$t('[[[Ilość znaków]]]')" :naturals="true" />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
