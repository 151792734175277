<script lang="ts" setup>
import { ref, computed, Ref } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { FormEntry } from '../form';
import Pager from '@/helpers/Pager';
import {
    LogitoAttachmentSelectionEntry,
    LogitoAttachmentSelectionType,
    instanceOfLogitoAttachmentSelectionEntry,
} from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { KeyValuePair } from '@/helpers/Interfaces';
import properties from '../../properties';
import AutocompleteService from '@/modules/core/common/services/AutocompleteService';

defineOptions({
    name: 'logito-attachment-selection-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const { $t } = useLocalization();

const entryData = ref(new LogitoAttachmentSelectionEntry()) as Ref<LogitoAttachmentSelectionEntry>;
const options = ref([] as Record<string, string>[]);

entryData.value = props.form.document.initEntry(
    props.blueprint,
    entryData.value,
    instanceOfLogitoAttachmentSelectionEntry,
    props.index
);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value ?? [];
    },
    set(value: string[])
    {
        if (value == null) value = [];

        if (!Array.isArray(value)) value = [value];

        entryData.value.data = value;
    },
});

const blueprint = computed(() => props.blueprint);
const required = computed(() => props.form.expressions.required(props.blueprint, props.index));
const design = computed(() => props.form.designMode());
const endpoint = computed(() =>
{
    if (design.value) return null;

    if (props.entry.fieldIndex)
    {
        return `modules/${props.entry.moduleId}/table-templates/select-options/${props.entry.fieldIndex}/${blueprint.value.name}`;
    }

    return `simple-dictionary/${props.entry.module}/form/${props.entry.formId}/${props.entry.actionName}/${blueprint.value.name}`;
});

const fetchOptions = async () =>
{
    const { items } = await AutocompleteService.fetchOptionsByCustomEndpoint(
        endpoint.value,
        {},
        new Pager(1, 32, '', 'ASC'),
        {}
    );

    options.value = items?.map?.((item) => item.result);
};

const init = () =>
{
    value.value = value.value.map((v: string | KeyValuePair) =>
    {
        if (typeof v === 'object' && 'key' in v) return v.key;

        return v;
    });

    !design.value && fetchOptions();
};

init();
</script>

<template>
    <form-component-wrapper
        class="logito-attachment-selection-component"
        :form="form"
        :parent="parent"
        :blueprint="blueprint"
    >
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <ideo-form-checkbox v-for="(option, i) in options" :key="i" v-model="value" :value="option.key">
                    {{ option.value }}
                </ideo-form-checkbox>
                <span v-if="!options.length" class="d-block text-muted">{{ $t('[[[Brak załączników]]]') }}</span>
                <form-error-message :entry="entryData" name="values" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-numeric
                v-model="blueprint.maxFiles"
                :label="$t('[[[Maksymalna ilość plików]]]')"
                :min="1"
                :max="60"
            />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-checkbox
                v-model="blueprint.showDocumentAttachments"
                :label="$t('[[[Pokaż załączniki dokumentu]]]')"
            />
            <field-checkbox
                v-model="blueprint.showRelatedDocumentsAttachments"
                :label="$t('[[[Pokaż załączniki dokumentów powiązanych]]]')"
            />
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
