<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useClipboard, useDebounce } from '@vueuse/core';

interface Field {
    key: string;
    description: string;
    properties?: Array<Field>;
}

const props = defineProps({
  "fields": null,
  "prefix": { default: '' }
});

const { copy, copied } = useClipboard();

const search = ref('');
const debouncedSearch = useDebounce(search, 500);

const prefixedFields = computed(() =>
{
    return props.fields.map((field) =>
    {
        if (field.properties?.length > 0)
        {
            return {
                ...field,
                key: `${props.prefix}${field.key}`,
                properties: field.properties.map((property) =>
                {
                    return { ...property, key: `${props.prefix}${field.key}.${property.key}` };
                }),
            };
        }

        return { ...field, key: `${props.prefix}${field.key}` };
    });
});

const filteredFields = computed(() =>
{
    if (debouncedSearch.value === '') return prefixedFields.value;

    const value = debouncedSearch.value.toLowerCase().trim();

    return prefixedFields.value.filter((field) =>
    {
        const includesSearch = (str: string) => str.toLowerCase().includes(value);

        return (
            includesSearch(field.key) ||
            includesSearch(field.description) ||
            field.properties?.some((property) => includesSearch(property.key)) ||
            field.properties?.some((property) => includesSearch(property.description))
        );
    });
});

const isDisabled = (property: Field) =>
{
    return property.properties?.length > 0;
};
</script>

<template>
    <div>
        <div class="search mb-2">
            <ideo-form-input v-model="search" type="text" :placeholder="$t('[[[Wyszukaj]]]')" name="pageSize" />
            <i class="fas fa-magnifying-glass icon"></i>
        </div>
        <div class="scroll scroll-x">
            <table class="table table-striped mb-1">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ $t('[[[Nazwa pola]]]') }}</th>
                        <th scope="col">{{ $t('[[[Opis]]]') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(property, index) in filteredFields" :key="property.key">
                        <td>{{ index + 1 }}</td>
                        <td>
                            <ideo-tooltip
                                :tooltip="
                                    isDisabled(property)
                                        ? ''
                                        : !copied
                                            ? `${$t('[[[Kopiuj pole]]]')}`
                                            : `${$t('[[[Pole skopiowane]]]')}`
                                "
                                position="right"
                            >
                                <span
                                    class="me-1"
                                    :class="!isDisabled(property) && 'text-primary cursor-pointer'"
                                    @click="!isDisabled(property) && copy(property.key)"
                                >
                                    {{ property.key }}
                                </span>
                            </ideo-tooltip>

                            <!-- Nested properties -->
                            <div
                                v-if="property.properties?.length"
                                class="d-flex gap-1 flex-column align-items-start mt-2"
                            >
                                <ideo-tooltip
                                    v-for="item in property.properties"
                                    :key="item.key"
                                    :tooltip="!copied ? `${$t('[[[Kopiuj pole]]]')}` : `${$t('[[[Pole skopiowane]]]')}`"
                                    position="right"
                                >
                                    <span class="text-primary cursor-pointer me-1" @click="copy(item.key)">
                                        {{ item.key }}
                                    </span>
                                </ideo-tooltip>
                            </div>
                        </td>
                        <td>
                            <span>{{ property.description }}</span>

                            <!-- Nested properties -->
                            <div
                                v-if="property.properties?.length"
                                class="d-flex flex-column gap-1 align-items-start mt-2"
                            >
                                <span v-for="item in property.properties" :key="item.key">
                                    {{ item.description || '-' }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="filteredFields == null || filteredFields.length === 0">
                        <td colspan="3" class="text-center">
                            {{ $t('[[[Brak kluczy]]]') }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.table-striped {
    td {
        padding: 0.625rem 0.5rem;
    }
}

.search {
    position: relative;

    input {
        padding-right: 36px;
    }

    .icon {
        position: absolute;
        top: 50%;
        right: 0.75rem;
        transform: translateY(-50%);
    }
}
</style>
