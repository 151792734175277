import { DateTime } from 'luxon';
import { getColor } from '@/modules/core/home/inc/helpers';
import { $i18n } from '@/plugins/localization';
import { persistState, Settings } from '@/plugins/viewstate';
import GridService, {
    Series,
    TimetableItems,
    TimetableCategories,
    TimetableGroup,
} from '@/modules/core/common/services/GridService';
import Pager from '@/helpers/Pager';

export default class TimetableDataHelper
{
    public data: Series<[{ categories: TimetableCategories[] }]>[] = [];

    public years(year: DateTime)
    {
        return [
            { value: year.minus({ year: 2 }) },
            { value: year.minus({ year: 1 }) },
            { value: year },
            { value: year.plus({ year: 1 }) },
            { value: year.plus({ year: 2 }) },
        ];
    }

    public get months()
    {
        return [...Array(12)].map((_item, index) => ({
            value: (index + 1).toString().padStart(2, '0'),
            text: new Date(0, index).toLocaleString($i18n.shortLocale(), { month: 'short' }),
        }));
    }

    public get statuses()
    {
        return this.data.map((item, index) => ({
            key: index,
            value: item.name,
            color: getColor(item.color),
        }));
    }

    public get items(): TimetableItems[]
    {
        const items: TimetableItems[] = [];

        for (const item of this.data)
        {
            const categories = item.documents[0]?.categories ?? [];

            for (const category of categories)
            {
                const isInArray = items.some((el) => el.key === category.key);

                if (!isInArray)
                {
                    const groups: TimetableGroup[] = [];

                    for (const el of category.groups)
                    {
                        groups.push({
                            key: el.key,
                            value: el.value,
                            documents: el.documents.map((document) => ({ ...document, color: item.color })),
                        });
                    }

                    items.push({
                        key: category.key,
                        value: category.value,
                        groups: groups,
                    });
                }
                else
                {
                    const index = items.findIndex((el) => el.key === category.key);

                    for (const el of category.groups)
                    {
                        const isInArray = items[index].groups.some((group) => group.key === el.key);

                        if (!isInArray)
                        {
                            items[index].groups.push({
                                key: el.key,
                                value: el.value,
                                documents: el.documents.map((document) => ({ ...document, color: item.color })),
                            });
                        }
                        else
                        {
                            const groupIndex = items[index].groups.findIndex((group) => group.key === el.key);

                            items[index].groups[groupIndex].documents.push(
                                ...el.documents.map((document) => ({ ...document, color: item.color }))
                            );
                        }
                    }
                }
            }
        }

        return items;
    }

    public loadData = async (licence: string, sitemapId: string, filter: any, routeKey: string, pager: Pager, settings: Settings) =>
    {
        try
        {
            const response = await GridService.fetchTimetableSeries(
                licence,
                sitemapId,
                DateTime.fromISO(settings.start).setZone('utc').toISO(),
                DateTime.fromISO(`${settings.end}T23:59:59.999`).setZone('utc').toISO(),
                filter.formatData()
            );

            this.data = response.series ?? [];
        }
        catch (ex)
        {
            this.data = [];
        }
        finally
        {
            persistState(routeKey, filter, pager, null, settings);
        }
    };
}

export enum TimeRange {
    Monthly = 'Monthly',
    HalfYearly = 'HalfYearly',
    Yearly = 'Yearly',
    CustomDate = 'CustomDate',
}

export enum TimeScale {
    Day = 'Day',
    Week = 'Week',
}
