<template>
    <div v-if="initHelper">
        <ideo-form-group
            v-for="item in initHelper"
            :key="item.dataJson[1].headerName"
            :label="item.dataJson[1].type.baseType !== 'Boolean' && $t(`[[[%0]]]|||${item.dataJson[1].headerName}`)"
            :invalid-feedback="errors.first(item.dataJson[0])"
            :state="errors.state(item.dataJson[0])"
            :required="item.dataJson[1].required"
        >
            <template v-if="item.dataJson[1].options != null && item.dataJson[1].options.length > 0">
                <ideo-select
                    :model-value="form[item.dataJson[0]]" :options="item.dataJson[1].options" @update:modelValue="(event) => {
                        setProperFormValue(event, item.dataJson[0], item.dataJson[1].type)
                        selectChanged(item)
                    }"
                >
                </ideo-select>
            </template>
            <template v-else-if="item.dataJson[1].type !== 'Boolean'">
                <component :is="getComponent(item.dataJson[1].type)" @update:modelValue="setProperFormValue($event, item.dataJson[0], item.dataJson[1].type)" :modelValue="form[item.dataJson[0]]" @input="internalChanged" />
            </template>
            <template v-else>
                <ideo-form-checkbox :modelValue="form[(item.dataJson[0])]" @update:modelValue="form[(item.dataJson[0])] = $event" @change="checkboxChanged">
                </ideo-form-checkbox>
            </template>
            <small v-if="item.dataJson[1].description && !errors.first(item.dataJson[0])">
                {{ item.dataJson[1].description }}
            </small>
        </ideo-form-group>
    </div>
</template>

<script lang="ts">
import { Debounce, Prop, Watch } from '@/helpers/Decorators';
import { Options, Vue } from 'vue-class-component';
import { DictionaryType, ColorOptions } from '@/helpers/Enums';
import { JsonHelperModel } from '../inc/Interfaces';
import { KeyValuePair } from '@/helpers/Interfaces';
@Options({
    name: 'JsonForm',
    emits: ['jsonChanged']
})
export default class JsonForm extends Vue
{
    @Prop({ default: null })
    public dictionary: DictionaryType;

    @Prop({ default: (): JsonHelperModel[] => [] })
    public helper: JsonHelperModel[];

    @Prop({ default: {} })
    public errors: Record<string, any>;

    @Prop({ default: null })
    public value: string | null;

    @Prop({ default: false })
    public isEdit: boolean;

    public form: any = {};
    public initHelper: any[] = [];
    public colorOptions = ColorOptions();

    public mounted(): void
    {
        if (this.isJSon(this.value))
        {
            const parsed = JSON.parse(this.value);
            const entries = Object.entries(parsed);

            for (const [key, value] of entries)
            {
                this.form[key] = value;
            }
        }
    }

    public created(): void
    {
        this.initHelperModified();
    }

    public isJSon(jsonString: string): boolean
    {
        try
        {
            const parsed = JSON.parse(jsonString);

            if (parsed && typeof parsed === "object") return true;
        }
        catch (e)
        {
            return false;
        }
    }

    @Watch('helper')
    public initHelperModified(): any[]
    {
        const helperKeys = Object.entries(this.helper);

        this.initHelper = helperKeys.map(item => ({ dataJson: item }));

        if (!this.isEdit)
        {
            this.form = {};
            this.initHelper.forEach((p, i) =>
            {
                if (p.dataJson[1].type == 'Boolean')
                    this.form[p.dataJson[0]] = false;
                else
                    this.form[p.dataJson[0]] = null;
            });
            this.$emit('jsonChanged', this.form);
        }
        else
        {
            this.initHelper.forEach((field, i) =>
            {
                if (this.isJSon(this.value))
                {
                    const parsed = JSON.parse(this.value);
                    const entries = Object.entries(parsed);

                    for (const [key, value] of entries)
                    {
                        if (value) this.form[key] = value;
                    }
                }

                if (field.dataJson[1].options && field.dataJson[1].options.length > 0 && this.form[field.dataJson[0]] != null)
                {
                    this.form[field.dataJson[0]] = (field.dataJson[1].options as any[]).find(p => p.key == this.form[field.dataJson[0]]);
                }
            });
        }

        return this.initHelper;
    }

    @Debounce(300)
    public internalChanged(): void
    {
        this.$emit('jsonChanged', this.form);
    }

    public checkboxChanged(): void
    {
        this.$emit('jsonChanged', this.form);
    }

    public selectChanged(item: any): void
    {
        const value = this.form[item.dataJson[0]];

        if (typeof value == 'string')
        {
            const option = (item.dataJson[1].options as any[]).find(p => p.key == value);

            this.form[item.dataJson[0]] = option;
        }
        else if (Object.hasOwn(value, 'key'))
        {
            const key = value.key;
            const option = (item.dataJson[1].options as any[]).find(p => p.key == key);

            this.form[item.dataJson[0]] = option;
        }

        this.$emit('jsonChanged', this.form);
    }

    public setProperFormValue(value: KeyValuePair, name: string, type: string): void
    {
        if (!isNaN(Number(value)) && type != 'String')
            this.form[name] = Number(value);
        else
            this.form[name] = value;
    }

    public formatOptionValue(option: KeyValuePair, name: string, type: string): void
    {
        if (!isNaN(Number(option.key)) && type != 'String')
            this.form[name] = Number(option.key);
        else
            this.form[name] = option.key;
    }

    public getComponent(type: string): string
    {
        switch (type)
        {
            case "string":
                return "ideo-form-input";
            case "boolean":
                return "ideo-form-checkbox";
            default:
                return "ideo-form-input";
        }
    }

    public capitalize(name: string): string
    {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }
}

</script>

<style lang="scss" scoped>
    .option-color-extra{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 8px;
    }
</style>
