// Base
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { AggregateBlueprint, instanceOfAggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { ValidatableBlueprint, setValidationErrors, validateBlueprints } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { RootAbstract } from '@/components/builder/base/blueprints/RootBlueprint';

// Blueprints
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { Definition as TableDefinition } from '../../../forms/blueprints/table';
import { FormBlueprint } from '@/components/builder/form/blueprints/FormBlueprint';

// Traits
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasTitle } from '@/components/builder/form/traits/HasTitle';
import { HasDescription } from '@/components/builder/form/traits/HasDescription';

// Entries
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry, instanceOfValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { RootEntryAbstract } from '@/components/builder/form/entries/RootEntry';

// Types
import { ProcessCallback, defaultCallback } from '@/components/builder/form/types/ProcessCallback';

// Form
import { FormBuilderContract } from '@/components/builder/form';

import { $i18n } from '@/plugins/localization';

// --------------------------------------------------

export const Definition: BlueprintDefinition = {
    type: 'form',
    name: '[[[Formularz]]]',
    group: 'none'
};

export enum ProgressChoice {
    Steps = 'Steps',
    Bar = 'Bar',
    Tabs = 'Tabs',
    None = 'None'
}

export enum TabsType {
    Tabs = 'Tabs',
    Pills = 'Pills',
    Underline = 'Underline'
}

export interface FormContract extends FormBlueprint, AggregateBlueprint, HasTitle, HasDescription
{
    sendButtonText: Record<string, string>;
    showSendButton: boolean;
    showPageNumbers: boolean;
    showPageTitles: boolean;
    progressType: ProgressChoice;
    tabsType: TabsType;
    tabsJustified: boolean;
    currentPage: number;
    category: number;
}

export class FormType extends RootAbstract implements EntryFactory<FormEntry>, FormContract, ValidatableBlueprint
{
    public kind: 'form' = 'form' as const;
    public id: string;
    public type: string;
    public name: string;
    public components: Blueprint[] = [];
    public title: Record<string, string>;
    public showTitle: boolean;
    public description: Record<string, string>;
    public sendButtonText: Record<string, string>;
    public showSendButton: boolean;
    public showPageNumbers: boolean;
    public showPageTitles: boolean;
    public progressType: ProgressChoice;
    public tabsType: TabsType;
    public tabsJustified: boolean;
    public currentPage: number;
    public category: number;
    public errors: ValidationErrors;

    public constructor(id: string, name: string)
    {
        super();

        this.id = id;
        this.name = name;
        this.type = Definition.type;
        this.components = [];
        this.title = { 'pl-PL': 'Formularz' };
        this.showTitle = false;
        this.description = {};
        this.sendButtonText = {};
        this.showSendButton = false;
        this.showPageNumbers = false;
        this.showPageTitles = true;
        this.progressType = ProgressChoice.Steps;
        this.tabsType = TabsType.Tabs;
        this.tabsJustified = false;
        this.currentPage = 1;
        this.category = null;
        this.errors = {};
    }

    public createEntry(data: any): FormEntry
    {
        return new FormEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        const defaultLocale = $i18n.defaultLocale();
        const TITLE_LENGTH = 250;

        if (!this.title[defaultLocale])
            this.errors.title = ['[[[Tytuł formularza jest wymagany]]]'];
        else if (Object.values(this.title).some((value) => value.length > TITLE_LENGTH))
            this.errors.title = [`[[[Tytuł formularza nie może być dłuższy niż %0 znaków|||${TITLE_LENGTH}]]]`];

        const errors = {
            [this.name]: this.errors,
            ...validateBlueprints(this.components)
        };

        return errors;
    }

    public setErrors(errors: Record<string, string[]>): void
    {
        setValidationErrors(this, errors);
    }
}

export const instanceOfFormType = (object: any): object is FormType =>
{
    return instanceOfAggregateBlueprint(object) && 'type' in object && object.type === Definition.type;
};

export class FormEntry extends RootEntryAbstract
{
    [prop: string]: any;

    public constructor(data: any = null)
    {
        super(Definition.type);

        if (data !== null)
        {
            Object.entries(data).forEach(([name, value]) =>
            {
                this[name] = value;
            });
        }
    }

    public async collect(form: FormContract, builder: FormBuilderContract, preprocess: ProcessCallback = defaultCallback): Promise<Entry>
    {
        await preprocess(form, this, builder.blueprintId, builder.entryId);

        const result: any = {
            type: this.type
        };

        const blueprints = builder.schema.components().filter((p) =>
        {
            const parent = builder.schema.parent(p);

            return parent?.type !== TableDefinition.type;
        });

        for (let i = 0; i < blueprints.length; i++)
        {
            const name = blueprints[i].name;

            if (instanceOfValidEntry(this[name]))
            {
                const entry = this[name] as ValidEntry<any>;
                const blueprint = builder.schema.find(name);

                result[name] = await entry.collect(blueprint, builder, preprocess);
            }
        }

        return entry(result);
    }

    public validate(form: FormContract, builder: FormBuilderContract): boolean
    {
        this.errors = {};

        builder.schema.components().forEach(blueprint =>
        {
            if (instanceOfValidEntry(this[blueprint.name]))
            {
                const entry = this[blueprint.name] as ValidEntry<Record<string, ValidEntry<any>>[]>;

                if (blueprint.type !== TableDefinition.type)
                {
                    if (!entry.validate(blueprint, builder))
                    {
                        Object.keys(entry.errors).forEach(key =>
                        {
                            this.errors[`${blueprint.name}.${key}`] = entry.errors[key];
                        });
                    }
                }
                else
                {
                    builder.schema.components().forEach(tableBlueprint =>
                    {
                        entry.data.forEach(row =>
                        {
                            if (instanceOfValidEntry(row[tableBlueprint.name]))
                            {
                                const tableEntry = row[tableBlueprint.name] as ValidEntry<any>;

                                if (!tableEntry.validate(tableBlueprint, builder))
                                {
                                    Object.keys(tableEntry.errors).forEach(key =>
                                    {
                                        this.errors[`${blueprint.name}.${tableBlueprint.name}.${key}`] = tableEntry.errors[key];
                                    });
                                }
                            }
                        });
                    });
                }
            }
        });

        return this.valid();
    }

    public find(name: string): ValidEntry<any>
    {
        if (name in this && instanceOfValidEntry(this[name]))
        {
            return this[name];
        }

        return null;
    }

    public getModuleName(): string | null
    {
        return this['module'];
    }
}
